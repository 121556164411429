import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";

class main extends Component {

    state = {
        user: [],
        entries: [],
        perms: [],
        adminToDel: "",
        adminIdToDel: "",
        adminTypeToDel: "",
        numOFFDays: "0",
        branchesList: ""
    }

    async setupSuppliersHistory() {
        const self = this;
        function getMyDate(date) {
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        window.$('#table2').DataTable({
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                const targetId = $("#boxForSupplierId").val();
                // console.log(targetId);
                await axios.post('/admins_history_table', {itemId:targetId, offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const itemId = resData[vCount].target_id;
                    const itemName = resData[vCount].target_name;
                    const operation = resData[vCount].operation;
                    const balance = resData[vCount].balance;
                    const balanceAfter = resData[vCount].balance_after;
                    const branch = resData[vCount].branch;
                    const author = resData[vCount].author;
                    const date = getMyDate(resData[vCount].date);
                    const note = resData[vCount].note;

                    out.push([id, itemName, operation, balance, balanceAfter, branch, author, date, note]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
    }

    setupDatatable() {
        const self = this;
        function getMyDate(date) {
            if(!date) {
                return "";
            }
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        window.$('#table1').DataTable({
            'columnDefs': [{
            'targets': [2],
            'render': function(data, type, full, meta) {
                        if(type === 'display'){ data = strtrunc(data, 10); } 
                            return data; 
                      }
            }],
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = [];
                const selectedEmployee = $("#selectedEmployee").val();
                const selectedDate = $("#selectedDate").val();
                await axios.post('/staff_login_table', {selectedDate:selectedDate, selectedEmployee:selectedEmployee, offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const staffId = resData[vCount].staff_id;
                    const staffName = resData[vCount].staff_name;
                    const loginDate = getMyDate(resData[vCount].login_date);
                    const status = resData[vCount].status;
                    const logoutDate = getMyDate(resData[vCount].logout_date);
                    const hoursPassed = resData[vCount].hours_passed;
                    const salary = resData[vCount].salary;
                    const workingHours = resData[vCount].working_hours;
                    const branch = resData[vCount].branch;
                    const offDays = resData[vCount].off_days;
                    const earns = resData[vCount].earns;

                    var buttons = "<div class='dropdown'>" +
                                        "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                        "<div class='dropdown-content'>"
                    // if(self.state.user.access.includes("|4|")) {
                    //     buttons += "<a class='myAStyle' id='delete_admin' itemId='"+id+"' itemName='"+name+"' itemType='"+type+"' style='color: red !important;'>Delete</a>";
                    // }
                    // if(self.state.user.access.includes("|5|")) {
                    //     buttons += "<a class='myAStyle' id='edit_admin' itemId='"+id+"' itemName='"+name+"' itemAccess='"+access+"' itemStatus='"+status+"' itemType='"+type+"' \
                    //                 itemSalary='"+salary+"' itemWorkHoursNum='"+workHoursNum+"' itemWorkHoursRange='"+workHoursRange+"' itemOffDaysName='"+offDaysName+"' itemBranch='"+branch+"'>Update</a>";
                    // }
                    // if(self.state.user.access.includes("|2|")) {
                    //     buttons += "<a class='myAStyle' id='view_supplier_logs' itemId='"+id+"' itemName='"+name+"'>History</a>";
                    // }
                    if(self.state.user.access.includes("|46|") && status === "Logged In") {
                        buttons += "<a class='myAStyle' id='logout_staff' itemId='"+id+"' itemName='"+staffName+"' style='color: red !important;'>Logout</a>";
                    }
                    if(self.state.user.access.includes("|60|") && status === "Logged Out") {
                        buttons += "<a class='myAStyle' id='update_staff_dates' itemId='"+id+"' itemName='"+staffName+"' loginDate='"+loginDate+"' hoursPassed='"+hoursPassed+"' \
                                                        salary='"+salary+"' workingHours='"+workingHours+"' logoutDate='"+logoutDate+"'>Update Dates</a>";
                    }
                    buttons += "</div></div>";
                    
                    out.push([id, staffId, staffName, loginDate, status, logoutDate, hoursPassed, salary, workingHours, branch, offDays, earns, buttons]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
                self.setupEmployeeData();
            }
        });
        $(document).off("click", "#update_staff_dates");
        $(document).on("click", "#update_staff_dates", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const loginDate = $(this).attr("loginDate");
            const hoursPassed = $(this).attr("hoursPassed");
            const salary = $(this).attr("salary");
            const workingHours = $(this).attr("workingHours");
            const logoutDate = $(this).attr("logoutDate");
            self.setState({adminIdToDel:itemId, adminToDel:itemName});
            
            $("#inStaffLoginDate").val(loginDate);
            $("#inStaffLogoutDate").val(logoutDate);
            $("#inStaffWorkingHours").html(workingHours);
            $("#inStaffPassedHours").html(hoursPassed);
            
            window.$("#updateDatesModal").modal("show");
        });
        $(document).off("click", "#logout_staff");
        $(document).on("click", "#logout_staff", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            self.setState({adminIdToDel:itemId, adminToDel:itemName});
            window.$("#deleteAdminModal").modal("show");
        });

        // $(document).off("click", "#view_supplier_logs");
        // $(document).on("click", "#view_supplier_logs", function() {
        //     const itemId = $(this).attr("itemId");
        //     const itemName = $(this).attr("itemName");
        //     self.setState({adminToDel:itemName, adminIdToDel:itemId, itemIdLogsHistory:itemId});
        //     $("#boxForSupplierId").val(itemId);
        //     window.$("#suppliersLogsHistory").modal("show");
        //     self.setupSuppliersHistory();
        // });
        // $(document).off("click", "#delete_admin");
        // $(document).on("click", "#delete_admin", function() {
        //     const itemId = $(this).attr("itemId");
        //     const itemName = $(this).attr("itemName");
        //     const itemType = $(this).attr("itemType");
        //     self.setState({adminToDel:itemName, adminIdToDel:itemId, adminTypeToDel:itemType});
        //     window.$("#deleteAdminModal").modal("show");
        // });
        // $(document).off("click", "#edit_admin");
        // $(document).on("click", "#edit_admin", async function() {
        //     const itemSalary = $(this).attr("itemSalary");
        //     const itemWorkHoursNum = $(this).attr("itemWorkHoursNum");
        //     const itemWorkHoursRange = $(this).attr("itemWorkHoursRange");
        //     const itemOffDaysName = $(this).attr("itemOffDaysName");
        //     const itemId = $(this).attr("itemId");
        //     const itemName = $(this).attr("itemName");
        //     const itemAccess = $(this).attr("itemAccess");
        //     const itemStatus = $(this).attr("itemStatus");
        //     const itemType = $(this).attr("itemType");
        //     const itemBranch = $(this).attr("itemBranch");
        //     self.setState({adminToDel:itemName, adminIdToDel:itemId});
        //     $("#emailSetEdit").html(itemName);
        //     $("#adminUpdateStatus").val(itemStatus);
        //     $("#adminType").val(itemType);
        //     const accessData = document.querySelectorAll(".queryAccess");
        //     for(var i = 0; i < accessData.length; ++i) {
        //         if(itemAccess.includes(accessData[i].id)) {
        //             accessData[i].checked = true;
        //         } else {
        //             accessData[i].checked = false;
        //         }
        //     }
        //     $("#emailSetView").show();
        //     $("#emailInputView").hide();
        //     $("#addAdminBtn").hide();
        //     $("#editAdminBtn").show();
        //     $("#statusView").show();
        //     $("#passwordInput").attr("placeholder", "Leave empty to avoid change...");
        //     if($("#adminType").val() === "employee") {
        //         $("#salaryIn").val(itemSalary);
        //         $("#workHoursFromToIn").val(itemWorkHoursRange);
        //         const offDaysName = document.querySelectorAll(".queryAccessDays");
        //         for(var i = 0; i < offDaysName.length; ++i) {
        //             if(itemOffDaysName.includes(offDaysName[i].id)) {
        //                 offDaysName[i].checked = true;
        //             } else {
        //                 offDaysName[i].checked = false;
        //             }
        //         }
        //         $(".employeeOptions").show();
        //         await self.setupBranches();
        //         $("#branchIn").val(itemBranch);
        //         self.processDays();
        //         self.processTimeSum();
        //     } else if($("#adminType").val() === "admin") {
        //         $("#salaryIn").val("");
        //         $("#workHoursFromToIn").val("");
        //         $("#workHoursIn").val("");
        //         $("#offDaysIn").val("");
        //         $("#selectDaysBtn").text("");
        //         const offDaysName = document.querySelectorAll(".queryAccessDays");
        //         for(var i = 0; i < offDaysName.length; ++i) {
        //             offDaysName[i].checked = false;
        //         }
        //         $(".employeeOptions").hide();
        //     }
        //     window.$("#addAdminModal").modal("show");
        // });
    }
    componentDidMount() {
        axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            this.setState({user:ok.data});
            if(!ok.data.access.includes("|45|")) {
                this.props.history.push("/panel");
                return;
            }
            const nodeName = "#staff-login_btn";
            const interv3 = setInterval(function() {
                $(".MuiButtonBase-root").css({backgroundColor: ""});
                $(nodeName).css({backgroundColor: "cadetblue"});
                if($(nodeName).prop("style")) {
                    if($(nodeName).prop("style").backgroundColor === "cadetblue") {
                        clearInterval(interv3);
                    }
                }
            }, 1);
            this.setupDatatable();
            this.setupEmployees();
            this.setupEmployeeData();
        })
    }

    setupEmployees = () => {
        axios.post("/get_employees").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({employeesList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({employeesList:data});
            }
        })
    }

    getMonthDate = (monthNum) => {
        const date = new Date();
        date.setDate(1);
        date.setMonth(date.getMonth() + monthNum);
        const fYear = date.getFullYear();
        const mMonth = String(date.getMonth() + 1).length === 1 ? "0"+String(date.getMonth() + 1) : String(date.getMonth() + 1);
        const monthDate = fYear+"-"+mMonth;
        return monthDate;
    }
    getYearDate = (yearNum) => {
        const date = new Date();
        date.setDate(1);
        date.setFullYear(date.getFullYear() + yearNum);
        const fYear = date.getFullYear();
        return fYear;
    }

    setStateSynchronous = (stateUpdate) => {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }

    setupBranches = async() => {
        window.toastr["info"]("Loading data...");
        await axios.post("/get_branches").then(async(ok)=>{
            if(ok.data.status == "error") {
                this.setState({branchesList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                await this.setStateSynchronous({branchesList:data});
            }
        })
        window.toastr.clear();
    }

    addAdmin = () => {
        const username = $("#usernameInput").val();
        const password = $("#passwordInput").val();
        const type = $("#adminType").val();
        const accessData = document.querySelectorAll(".queryAccess");
        var branch = $("#branchIn").val();
        var salary = $("#salaryIn").val();
        var workHours = $("#workHoursIn").val();
        var workHoursFromTo = $("#workHoursFromToIn").val();
        var offDaysNum = $("#offDaysIn").val();
        var offDaysName = $("#selectDaysBtn").text();

        var accessRes = "";
        for(var i = 0; i < accessData.length; ++i) {
            const isChecked = accessData[i].checked;
            if(isChecked) {
                accessRes += accessData[i].id;
            }
        }
        if(username.length < 3) {
            window.toastr["error"]("Invalid Username");
            return;
        }
        if(password.length < 3) {
            window.toastr["error"]("Invalid Password");
            return;
        }
        if(!accessRes) {
            window.toastr["error"]("Please select Access");
            return;
        }
        if(type === "employee") {
            if(!branch) {
                window.toastr["error"]("Invalid Branch");
                return;
            }
            if(!salary) {
                window.toastr["error"]("Invalid Salary");
                return;
            }
            if(!workHours) {
                window.toastr["error"]("Invalid Work Hours Number");
                return;
            }
            if(!workHoursFromTo) {
                window.toastr["error"]("Invalid Work Hours (From/To)");
                return;
            }

        } else {
            branch = "";
            salary = "";
            workHours = "";
            workHoursFromTo = "";
            offDaysNum = "";
            offDaysName = "";
        }

        axios.post("/add_admin", {username:username, password:password, access:accessRes, type:type, salary:salary, workHours:workHours, workHoursFromTo:workHoursFromTo, offDaysNum:offDaysNum, offDaysName:offDaysName, branch:branch}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addAdminModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    deleteAdmin = () => {
        axios.post("/del_admin", {id:this.state.adminIdToDel}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#deleteAdminModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    processDays = () => {
        const days = document.querySelectorAll(".queryAccessDays");
        var selectedDays = "";
        var count = 0;
        for(var i = 0; i < days.length; ++i) {
            const isChecked = days[i].checked;
            if(isChecked) {
                selectedDays += days[i].id+", ";
                count += 1;
            }
        }
        $("#offDaysIn").val(count);
        selectedDays = selectedDays.slice(0, -2);
        if(selectedDays) {
            $("#selectDaysBtn").text(selectedDays);
        } else {
            $("#selectDaysBtn").text("No OFF");
        }
        // console.log(selectedDays);
    }

    processTimeSum = () => {
        const data = $("#workHoursFromToIn").val();
        // console.log("["+data+"]");
        if(data && (data.match(/.*AM --- .*PM/) || data.match(/.*AM --- .*AM/) || data.match(/.*PM --- .*PM/) || data.match(/.*PM --- .*AM/))) {
            var firstNum = data.split(" --- ")[0].replace("A", "").replace("P", "").replace("M", "").replace(/ /g, "");
            var secondNum = data.split(" --- ")[1].replace("A", "").replace("P", "").replace("M", "").replace(/ /g, "");
            const firstPart = data.split(" --- ")[0];
            const secondPart = data.split(" --- ")[1];
            var numOfHours = "0";

            if(firstPart.includes("PM")) {
                if(!firstPart.includes("12")) {
                    firstNum = String(parseFloat(firstNum) + 12);
                }
            }
            if(secondPart.includes("PM")) {
                if(!secondPart.includes("12")) {
                    secondNum = String(parseFloat(secondNum) + 12);
                }
            }
            if(firstPart.includes("12AM")) {
                firstNum = "00";
            }
            if(secondPart.includes("12AM")) {
                secondNum = "00";
            }
            const timeStart = new Date("01/01/2007 " + firstNum+":00:00").getHours();
            const timeEnd = new Date("01/01/2007 " + secondNum+":00:00").getHours();
            numOfHours = timeEnd - timeStart;
            if (numOfHours < 0) {
                numOfHours = 24 + numOfHours;
            }
            // console.log("{"+numOfHours+"}");
            $("#workHoursIn").val(numOfHours);
            // console.log("--"+$("#workHoursIn").val()+"--");
        } else {
            window.toastr["error"]("Invalid Time Syntax, Accepted only (*AM --- *PM)");
            return false;
        }
        return true;
    }

    editAdmin = () => {
        const type = $("#adminType").val();
        if(type === "employee") {
            this.processDays();
            if(!this.processTimeSum()) {
                return;
            }
        }
        const password = $("#passwordInput").val();
        const accessData = document.querySelectorAll(".queryAccess")
        const status = $("#adminUpdateStatus").val();
        var branch = $("#branchIn").val();
        var salary = $("#salaryIn").val();
        var workHours = $("#workHoursIn").val();
        var workHoursFromTo = $("#workHoursFromToIn").val();
        var offDaysNum = $("#offDaysIn").val();
        var offDaysName = $("#selectDaysBtn").text();
        // console.log("("+workHours+")");

        var accessRes = "";
        for(var i = 0; i < accessData.length; ++i) {
            const isChecked = accessData[i].checked;
            if(isChecked) {
                accessRes += accessData[i].id;
            }
        }
        // if(password.length < 3) {
        //     window.toastr["error"]("Invalid Password");
        //     return;
        // }
        if(!accessRes) {
            window.toastr["error"]("Please select Access");
            return;
        }

        if(type === "employee") {
            if(!branch) {
                window.toastr["error"]("Invalid Branch");
                return;
            }
            if(!salary) {
                window.toastr["error"]("Invalid Salary");
                return;
            }
            if(!workHours) {
                window.toastr["error"]("Invalid Work Hours Number");
                return;
            }
            if(!workHoursFromTo) {
                window.toastr["error"]("Invalid Work Hours (From/To)");
                return;
            }
        } else {
            branch = "";
            salary = "";
            workHours = "";
            workHoursFromTo = "";
            offDaysNum = "";
            offDaysName = "";
        }

        axios.post("/edit_admin", {id:this.state.adminIdToDel, password:password, access:accessRes, status:status, type:type, salary:salary, workHours:workHours, workHoursFromTo:workHoursFromTo, offDaysNum:offDaysNum, offDaysName:offDaysName, branch:branch}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addAdminModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    logoutStaff = () => {
        const id = this.state.adminIdToDel;
        axios.post("/admin_logout_staff", {id:id}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#deleteAdminModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        });
    }

    setupEmployeeData = () => {
        const staff = $("#selectedEmployee").val();
        const date = $("#selectedDate").val();
        if(staff && date) {
            axios.post("/get_final_staff_data", {staff:staff, date:date}).then((ok)=>{
                if(ok.data.status === "success") {
                    $("#salaryInput").html(ok.data.salary);
                    $("#earnsInput").html(ok.data.earns);
                    $("#passedDaysInput").html(ok.data.count);
                    $("#linkedClientInput").html(ok.data.linkedClient);
                    $("#linkedClientBalanceInput").html(ok.data.linkedClientBalance);
                    $("#finalEmployeeData").show();
                } else if(ok.data.status === "error") {
                    // console.log(ok.data.msg);
                    $("#finalEmployeeData").hide();
                }
            });
        } else {
            $("#finalEmployeeData").hide();
        }
    }

    updateStaffDates = () => {
        const entryId = this.state.adminIdToDel;
        var loginDate = $("#inStaffLoginDate").val();
        var logoutDate = $("#inStaffLogoutDate").val();
        if(!loginDate.includes(", ")) {
            window.toastr["error"]("Invalid Login Date");
            return;
        }
        if(!logoutDate.includes(", ")) {
            window.toastr["error"]("Invalid Logout Date");
            return;
        }
        loginDate = loginDate.split(",")[0].split("/")[2]+"-"+loginDate.split(",")[0].split("/")[1]+"-"+loginDate.split(",")[0].split("/")[0]+","+loginDate.split(",")[1];
        logoutDate = logoutDate.split(",")[0].split("/")[2]+"-"+logoutDate.split(",")[0].split("/")[1]+"-"+logoutDate.split(",")[0].split("/")[0]+","+logoutDate.split(",")[1];
        
        axios.post("/update_staff_dates", {id:entryId, loginDate:loginDate, logoutDate:logoutDate}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#updateDatesModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        });
    }

    render() {
        return(
            <>
            
            <div class="modal fade" id="updateDatesModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Staff Entry ID:</th>
                                        <td>{this.state.adminIdToDel}</td>
                                    </tr>
                                    <tr>
                                        <th>Staff Name:</th>
                                        <td>{this.state.adminToDel}</td>
                                    </tr>
                                    <tr>
                                        <th>Staff Working Hours:</th>
                                        <td id="inStaffWorkingHours"></td>
                                    </tr>
                                    <tr>
                                        <th>Staff Passed Hours:</th>
                                        <td id="inStaffPassedHours">></td>
                                    </tr>
                                    <tr>
                                        <th>Staff Login Date:</th>
                                        <td><input class="myInputStyle" id="inStaffLoginDate" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Staff Logout Date:</th>
                                        <td><input class="myInputStyle" id="inStaffLogoutDate" placeholder=""/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='moveItemStockBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.updateStaffDates();
                            }}>Update</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="deleteAdminModal" role="dialog" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            Logging Out Staff
                        </div>
                        <div class="modal-body">
                            Are you sure you want to Force Logout ({this.state.adminToDel}) ?
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.logoutStaff();
                            }}>Yes</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                window.$("#deleteAdminModal").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="FusePageCarded-header container">
                <div style={{flexDirection: "column"}} className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
                    <span className="MuiTypography-root MuiTypography-body1 flex text-24 md:text-32 font-extrabold tracking-tight muiltr-1niqtu4-MuiTypography-root" delay={300} style={{transform: 'none'}}>Working Time System Page</span>
                </div>
            </div>

            <div style={{padding: "0px 10px 0px 10px"}}>

                {this.state.addAdminsBtn}

                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                    // this.setupEmployeeData();
                }}>Refresh</button>
                <br/>

                <table className="table-modal" style={{width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                    <tbody>
                        <tr>
                            <th>Employee:</th>
                            <td>
                                <select id="selectedEmployee" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                    this.setupEmployeeData();
                                }}>
                                    <option value="">All</option>
                                    {this.state.employeesList}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Date:</th>
                            <td>
                                <select id="selectedDate" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                    this.setupEmployeeData();
                                }}>
                                    <option value="">All</option>
                                    <option value={this.getMonthDate(0)}>This Month {this.getMonthDate(0)}</option>
                                    <option value={this.getMonthDate(-1)}>Previous Month {this.getMonthDate(-1)}</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br/>

                <table class="table table-report dt-responsive -mt-2" id="table1" style={{width: "100%"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Staff ID</th>
                            <th>Staff Name</th>
                            <th>Login Date</th>
                            <th>Status</th>
                            <th>Logout Date</th>
                            <th>Hours Passed</th>
                            <th>Salary</th>
                            <th>Working Hours</th>
                            <th>Branch</th>
                            <th>OFF Days</th>
                            <th>Earns</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                
                <table className="table-modal" id="finalEmployeeData" style={{width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                    <tbody>
                        <tr>
                            <th>Salary:</th>
                            <td id="salaryInput"></td>
                        </tr>
                        <tr>
                            <th>Passed Days:</th>
                            <td id="passedDaysInput"></td>
                        </tr>
                        <tr>
                            <th>Linked Client:</th>
                            <td id="linkedClientInput"></td>
                        </tr>
                        <tr>
                            <th>Linked Client Balance:</th>
                            <td id="linkedClientBalanceInput"></td>
                        </tr>
                        <tr>
                            <th>Earns:</th>
                            <td id="earnsInput"></td>
                        </tr>
                    </tbody>
                </table>

            </div>
            </>
        )
    }
}

export default main;
