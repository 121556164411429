import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";

class main extends Component {

    state = {
        user: [],
        entries: [],
        perms: [],
        adminToDel: "",
        adminIdToDel: "",
        allowCost: "",
        itemsCats: [],
        catToMove: ""
    }
    async setupDatatable() {
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        const self = this;
        window.$('#table1').DataTable({
            'columnDefs': [{
            'targets': [2],
            'render': function(data, type, full, meta) {
                        if(type === 'display'){ data = strtrunc(data, 10); } 
                            return data; 
                      }
            }],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                await axios.post('/cats_table', {offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const name = resData[vCount].name;
                    const type = resData[vCount].type;
                    const parent = resData[vCount].parent;

                    var buttons = "<div class='dropdown'>" +
                                        "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                        "<div class='dropdown-content'>"
                    if(self.state.user.access.includes("|14|")) {
                        buttons += "<a class='myAStyle' id='delete_cat' itemId='"+id+"' itemName='"+name+"' itemType='"+type+"' style='color: red !important;'>Delete</a>";
                    }
                    if(self.state.user.access.includes("|15|")) {
                        buttons += "<a class='myAStyle' id='move_cat' itemId='"+id+"' itemName='"+name+"' itemParent='"+parent+"'>Move</a>";
                    }
                    // if(self.state.user.access.includes("|4|")) {
                    //     buttons += "<a class='myAStyle' id='delete_admin' itemId='"+id+"' itemName='"+name+"' style='color: red !important;'>Delete</a>";
                    // }
                    // if(self.state.user.access.includes("|5|")) {
                    //     buttons += "<a class='myAStyle' id='edit_admin' itemId='"+id+"' itemName='"+name+"' itemAccess='"+access+"' itemStatus='"+status+"'>Update</a>";
                    // }
                    buttons += "</div></div>";
                    out.push([id, name, type, parent, buttons]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
        $(document).off("click", "#delete_cat");
        $(document).on("click", "#delete_cat", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemType = $(this).attr("itemType");
            axios.post("/del_cat", {id:itemId, name:itemName, type:itemType}).then((ok)=>{
                window.toastr[ok.data.status](ok.data.msg);
                if(ok.data.status === "success") {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }
            })
        });
        $(document).off("click", "#move_cat");
        $(document).on("click", "#move_cat", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemParent = $(this).attr("itemParent");
            if(itemParent) {
                self.setState({catToMove:itemParent+" > "+itemName});
            } else {
                self.setState({catToMove:itemName});
            }
            self.setState({catToMove:itemName});
            self.setupCats();
            window.$("#moveCatModal").modal("show");
        });
    }

    setupCats = () => {
        axios.post("/get_cats").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({itemsCats:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                data.push(<option value="">{"<Make as Main Category>"}</option>);
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({itemsCats:data});
            }
        })
    }

    componentDidMount() {
        axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            this.setState({user:ok.data});
            if(!ok.data.access.includes("|12|")) {
                this.props.history.push("/panel");
                return;
            }
            const nodeName = "#cats_btn";
            const interv3 = setInterval(function() {
                $(".MuiButtonBase-root").css({backgroundColor: ""});
                $(nodeName).css({backgroundColor: "cadetblue"});
                if($(nodeName).prop("style")) {
                    if($(nodeName).prop("style").backgroundColor === "cadetblue") {
                        clearInterval(interv3);
                    }
                }
            }, 1);
            this.setupDatatable();

            if(ok.data.access.includes("|13|")) {
                this.setState({addAdminsBtn:[
                    <>
                        <button class='myBtnStyle' tabIndex={0} type='button' onClick={() => {
                            this.setupCats();
                            window.$("#addCatModal").modal("show");
                        }}>+ Add</button>
                    </>
                ]})
            }
        })
    }
    addCat = () => {
        const parentCat = $("#inParentCat2").val();
        const catName = $("#inCatName").val();
        axios.post("/add_cat", {parent:parentCat, name:catName}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addCatModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    saveCat = () => {
        var newParent = $("#inParentCat1").val();
        const catToMove = this.state.catToMove;
        if(newParent.includes(" > ")) {
            newParent = newParent.split(" > ")[newParent.split(" > ").length - 1];
        }
        axios.post("/move_cat", {catToMove:catToMove, newParent:newParent}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$('#table1').DataTable().ajax.reload(null, false);
                window.$("#moveCatModal").modal("hide");
            }
        })
    }

    render() {
        return(
            <>

            <div class="modal fade" id="moveCatModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div className="modal-header">
                            {"Moving Category: {"+this.state.catToMove+"}"}
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Move to Category:</th>
                                        <td>
                                            <select id="inParentCat1" className="myInputStyle">
                                                {this.state.itemsCats}
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addItemBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.saveCat();
                            }}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addCatModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Parent Category:</th>
                                        <td>
                                            <select id="inParentCat2" className="myInputStyle">
                                                {this.state.itemsCats}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Category Name:</th>
                                        <td><input class="myInputStyle" id="inCatName"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addItemBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.addCat();
                            }}>Add</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="FusePageCarded-header container">
                <div style={{flexDirection: "column"}} className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
                    <span className="MuiTypography-root MuiTypography-body1 flex text-24 md:text-32 font-extrabold tracking-tight muiltr-1niqtu4-MuiTypography-root" delay={300} style={{transform: 'none'}}>Categories Page</span>
                </div>
            </div>

            <div style={{padding: "0px 10px 0px 10px"}}>

                {this.state.addAdminsBtn}

                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>Refresh</button>
                <br/>
                <br/>

                <table class="table table-report dt-responsive -mt-2" id="table1" style={{width: "100%"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Parent</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }
}

export default main;
