import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import {Buffer} from "buffer";
import QRCode from "react-qr-code";
import Barcode from 'react-barcode';


class login extends Component {
  state = {
    invoiceId: "",
    invoiceDate: "",
    customer: "",
    user: [],
    itemsData: []
  }
    componentWillMount() {
      axios.post("/is_authed").then((ok)=>{
        if(!ok.data) {
          this.props.history.push("/login");
        }
        if(!ok.data.access.includes("|81|")) {
            window.open("/panel", "_self");
            // this.props.history.push("/panel");
            return;
        }
        this.setState({user:ok.data});
      })
    }
    getMyDate = (date) => {
      return new Date(date).toLocaleString("en-GB", {timeZone: this.state.user.tz, hour12: "true"}).toLocaleUpperCase();
    }
    setStateSynchronous = (stateUpdate) => {
      return new Promise(resolve => {
          this.setState(stateUpdate, () => resolve());
      });
    }
    componentDidMount = async() => {
        $("head").append('<link rel="stylesheet" title="myPrintA4Style" href="/assets/style/print_a4.css" />');
        $("#root").removeAttr("class");
        $("#root").removeAttr("id");
        const base64InvoiceId = window.location.href.split("/")[window.location.href.split("/").length - 1];
        const invoiceId = Buffer.from(base64InvoiceId, "hex").toString("utf8");


        const width = invoiceId.split("||")[0];
        const height = invoiceId.split("||")[1];
        const name = invoiceId.split("||")[2];
        const price = invoiceId.split("||")[3];
        const barcode = invoiceId.split("||")[4];
        $("#main_page_barcode").css({width: width+"mm", "min-height": height+"mm", height: height+"mm", border: "0", padding: "4mm"});
        if(name.length > 45) {
            $("#label_name_id").css({"font-size": "xx-small"});
        }
        
        await this.setStateSynchronous({name:name, price:price+"$", barcode:barcode});
        $("#item_barcode_112").css({width: "100%"});
        window.print();
    }

    render() {
        return(
            <>
              <div class="book">
                  <div class="page" id="main_page_barcode" style={{justifyItems: "center", textAlign: "center", alignContent: "center"}}>
                    <label id="label_name_id">{this.state.name}</label>
                    <br/>
                    <label>{this.state.price}</label>
                    <Barcode id="item_barcode_112" style={{justifySelf: "center", width: "100%"}} value={this.state.barcode} height="20" />
                  </div>
              </div>
            </>
        )
    }
}

export default login;
