import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import onScan from "onscan.js";
import {Buffer} from "buffer";

class main extends Component {

    state = {
        user: [],
        entries: [],
        perms: [],
        adminToDel: "",
        adminIdToDel: "",
        allowCost: "",
        itemsCats: [],
        branchesList: [],
        suppliersList: [],
        itemIdLogsHistory: "",
        gotItems: [],
        rate: "",
        snsSold: []
    }

    async setupDatatable() {
        await window.$('#table1').DataTable({
            'columnDefs': [
                { "width": "100%", "targets": 1 },
                { className: 'dt-left', targets: 1 },
            ],
            // order: [[0, "desc"]],
            serverSide: false,
            ordering: false,
            searching: false,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var out = [];
                data.length = 0;
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: 0,
                    recordsFiltered: 0
                });
            }
        });
    }

    setupItemModal = async(modal) => {
        window.toastr["info"]("Loading data...");
        await axios.post("/get_cats").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({itemsCats:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                // data.push(<option value="">{"<Make as Main Category>"}</option>);
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({itemsCats:data});
            }
            // console.log(ok.data);
        })
        await axios.post("/get_branches").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({branchesList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                // data.push(<option value="">{"<Make as Main Category>"}</option>);
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({branchesList:data});
            }
        })
        await axios.post("/get_suppliers").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({suppliersList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                // data.push(<option value="">{"<Make as Main Category>"}</option>);
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({suppliersList:data});
            }
        })
        window.toastr.clear();
        window.$(modal).modal("show");
    }

    setupRate = () => {
        axios.post("/get_rate").then((ok)=>{
            if(ok.data) {
                this.setState({rate:ok.data});
            }
        })
        axios.post("/get_branches").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({branchesList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                // data.push(<option value="">{"<Make as Main Category>"}</option>);
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({branchesList:data});
            }
        })
    }

    componentDidMount() {
        axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            this.setState({user:ok.data});
            // if(ok.data.access.includes("|10|")) {
            //     this.setState({allowCost:true});
            // } else {
            //     this.setState({allowCost:false});
            // }
            if(!ok.data.access.includes("|11|")) {
                this.props.history.push("/panel");
                return;
            }
            const nodeName = "#selling_btn";
            const interv3 = setInterval(function() {
                $(".MuiButtonBase-root").css({backgroundColor: ""});
                $(nodeName).css({backgroundColor: "cadetblue"});
                if($(nodeName).prop("style")) {
                    if($(nodeName).prop("style").backgroundColor === "cadetblue") {
                        clearInterval(interv3);
                    }
                }
            }, 1);
            this.setupDatatable();
            this.setupRate();
            this.fixInputValue("#inCashBoxIdUsd");
            this.fixInputValue("#inCashBoxIdLbp");
            this.setupBarcodeScanner();
        })
    }

    getBarcodeItem = (code) => {
        // code = "94145703";
        const table = window.$('#table1').DataTable();
        const self = this;
        const itemBranch = this.state.user.branch ? this.state.user.branch : $("#inBranches").val();
        window.toastr["info"]("Loading Items with Barcode ["+code+"]");
        axios.post("/get_barcode_items", {code:code, branch:itemBranch}).then(async(ok)=>{
            if(ok.data.status === "error") {
                window.toastr.remove();
                window.toastr[ok.data.status](ok.data.msg);
            } else if(ok.data.status === "success") {
                window.toastr.remove();
                window.toastr[ok.data.status](ok.data.msg);
                const spData = ok.data.data;
                const id = spData[0].id;
                const name = spData[0].name;
                const barcode = spData[0].barcode;
                const retail = spData[0].retail_price;
                const secRetail = spData[0].retail_price_2;
                const wholesale = spData[0].wholesale_price;
                const qty = spData[0].qty;
                const branch = spData[0].branch;
                const haveSns = spData[0].have_sns;

                var doAdd = true;
                const qtsAllNodes = $(".qtyToChangeClass");
                if(qtsAllNodes.length > 0) {
                    for(var i = 0; i < qtsAllNodes.length; ++i) {
                        const cBarcode = $(qtsAllNodes[i]).attr("itemBarcode");
                        const cId = $(qtsAllNodes[i]).attr("itemId");
                        if(String(cId) === String(id)) {
                            
                            if(haveSns === "yes") {
                                const oldSnsCodes = $(qtsAllNodes[i]).attr("snsCode");
                                if(oldSnsCodes && oldSnsCodes.includes(code)) {
                                    window.toastr["error"]("Item ["+code+"] Already Added");
                                    return;
                                }
                                const newSnsCodes = oldSnsCodes+","+code;
                                $(qtsAllNodes[i]).attr("snsCode", newSnsCodes);
                            }
                            
                            const newVal = String(parseFloat($(qtsAllNodes[i]).val()) + 1);
                            $(qtsAllNodes[i]).val(newVal);
                            doAdd = false;
                            self.calculateDiscountRes(true);
                        }
                        // console.log(cBarcode, $(qtsAllNodes[i]).attr("id"));
                    }
                }

                if(doAdd) {
                    // console.log("11");
                    var sellPrice = retail;
                    if($("#sellType").val() === "wholesale") {
                        sellPrice = wholesale;
                    }
                    var readOnlyQty = "";
                    var snsCode = "";
                    if(haveSns === "yes") {
                        readOnlyQty = "readonly='readonly'";
                        snsCode = "snsCode='"+code+"'";
                    }
                    const qtyToChange = "<input class='myInputStyle qtyToChangeClass' haveSns='"+haveSns+"' "+snsCode+" itemRetailBasePrice='"+retail+"' itemWholesaleBasePrice='"+wholesale+"' itemName='"+name+"' itemBarcode='"+barcode+"' id='qtyToChange_"+id+"' itemId='"+id+"' itemBranch='"+branch+"' type='number' value='1' placeholder='' "+readOnlyQty+" />";
                    const priceToChange = "<input class='myInputStyle priceToChangeClass' selfEdited='no' itemName='"+name+"' itemBarcode='"+barcode+"' id='priceToChange_"+id+"' itemId='"+id+"' itemBranch='"+branch+"' retail='"+retail+"' wholesale='"+wholesale+"' type='number' value='"+sellPrice+"' placeholder='' />";
                    var buttons = "<div class='dropdown'>" +
                                    "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                    "<div class='dropdown-content'>"
                    buttons += "<a class='myAStyle' id='removeItemBtn_"+id+"' itemId='"+id+"'>Remove</a>";
                    $(document).off("click", "#removeItemBtn_"+id+"");
                    $(document).on("click", "#removeItemBtn_"+id+"", async function() {
                        const rowsLen = table.rows().data().length;
                        const id = $(this).attr("itemId");
                        for(var i = 0; i < rowsLen; ++i) {
                            if(table.row(i).data()) {
                                const rowId = table.row(i).data()[0];
                                if(String(id) === String(rowId)) {
                                    table.row(i).remove().draw(false);
                                }
                            }
                        }
                        self.calculateDiscountRes(true);
                    });
                    buttons += "</div></div>";
                    table.row.add([id, name, barcode, retail, secRetail, wholesale, qty, qtyToChange, priceToChange, branch, buttons]).draw(true);
                    this.calculateDiscountRes(true);
                    $(document).off("change", ".qtyToChangeClass");
                    $(document).on("change", ".qtyToChangeClass", async function() {
                        self.updateInvoiceEvent();
                    });
                    $(document).off("change", ".priceToChangeClass");
                    $(document).on("change", ".priceToChangeClass", async function() {
                        $(this).attr("selfEdited", "yes");
                        // console.log($(this).attr("selfEdited"));
                        self.updateInvoiceEvent();
                    });
                }
            }
        });

    }

    unSetupBarcodeScanner = () => {
        if(onScan.isAttachedTo(document)) {
            onScan.detachFrom(document);
        }
        $(document).off("scan");
    }

    componentWillUnmount() {
        this.unSetupBarcodeScanner();
    }

    setupBarcodeScanner = () => {
        const self = this;
        this.unSetupBarcodeScanner();
        onScan.attachTo(document);
        $(document).on("scan", function(sScancode, iQuantity) {
            const code = sScancode.detail.scanCode;
            if(!isNaN(code)) {
                self.getBarcodeItem(code);
            }
        })
    }

    setStateSynchronous = (stateUpdate) => {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }

    processSearchItems = () => {
        this.setState({gotItems:[]});
        const se = $("#searchItemsBox").val();
        $("#dropdownDiv").css({display:"none"});
        const branch = $("#inBranches").val();
        const table = window.$('#table1').DataTable();
        const self = this;
        axios.post("/selling_items_table", {se:se, branch:branch}).then(async(ok)=>{
            const spData = ok.data;
            if(spData) {
                var allData = [];
                var addedArrayIds = [];
                for(var i = 0; i < spData.length; ++i) {
                    const id = spData[i].id;
                    const name = spData[i].name;
                    const barcode = spData[i].barcode;
                    const retail = spData[i].retail_price;
                    const secRetail = spData[i].retail_price_2;
                    const wholesale = spData[i].wholesale_price;
                    const qty = spData[i].qty;
                    const branch = spData[i].branch;
                    const haveSns = spData[i].have_sns;
                    addedArrayIds.push(id);
                    allData.push([
                        <>
                            <input type="checkbox" class="queryAccessItem myCheckboxStyle" id={"itemId_"+id} style={{height: "17px"}} onChange={() => {
                                // const table = window.$('#table1').DataTable();
                                // console.log("Triggered Data 1");
                                if(document.getElementById("itemId_"+id).checked) {
                                    // const qtyToChange = "<input class='myInputStyle qtyToChangeClass' itemName='"+name+"' itemBarcode='"+barcode+"' id='qtyToChange_"+id+"' itemId='"+id+"' itemBranch='"+branch+"' type='number' value='1' placeholder='' />";
                                    var sellPrice = retail;
                                    if($("#sellType").val() === "wholesale") {
                                        sellPrice = wholesale;
                                    }
                                    var readOnlyQty = "";
                                    var snsCode = "";
                                    if(haveSns === "yes") {
                                        readOnlyQty = "readonly='readonly'";
                                        snsCode = "snsCode='"+se+"'";
                                    }
                                    
                                    const qtyToChange = "<input class='myInputStyle qtyToChangeClass' haveSns='"+haveSns+"' "+snsCode+" itemRetailBasePrice='"+retail+"' itemWholesaleBasePrice='"+wholesale+"' itemName='"+name+"' itemBarcode='"+barcode+"' id='qtyToChange_"+id+"' itemId='"+id+"' itemBranch='"+branch+"' type='number' value='1' placeholder='' "+readOnlyQty+" />";
                                    const priceToChange = "<input class='myInputStyle priceToChangeClass' selfEdited='no' itemName='"+name+"' itemBarcode='"+barcode+"' id='priceToChange_"+id+"' itemId='"+id+"' itemBranch='"+branch+"' retail='"+retail+"' wholesale='"+wholesale+"' type='number' value='"+sellPrice+"' placeholder='' />";
                                    
                                    var buttons = "<div class='dropdown'>" +
                                                    "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                                    "<div class='dropdown-content'>"
                                    buttons += "<a class='myAStyle' id='removeItemBtn_"+id+"' itemId='"+id+"'>Remove</a>";

                                    $(document).off("click", "#removeItemBtn_"+id+"");
                                    $(document).on("click", "#removeItemBtn_"+id+"", async function() {
                                        const rowsLen = table.rows().data().length;
                                        const id = $(this).attr("itemId");
                                        for(var i = 0; i < rowsLen; ++i) {
                                            if(table.row(i).data()) {
                                                const rowId = table.row(i).data()[0];
                                                if(String(id) === String(rowId)) {
                                                    table.row(i).remove().draw(false);
                                                }
                                            }
                                        }
                                        self.calculateDiscountRes(true);
                                    });

                                    buttons += "</div></div>";
                                    
                                    table.row.add([id, name, barcode, retail, secRetail, wholesale, qty, qtyToChange, priceToChange, branch, buttons]).draw(true);
                                    this.calculateDiscountRes(true);
                                } else if(haveSns === "yes") {
                                    document.getElementById("itemId_"+id).checked = true;
                                    const oldSnsCodes = $("#qtyToChange_"+id).attr("snsCode");
                                    if(oldSnsCodes.includes(se)) {
                                        window.toastr["error"]("Item ["+se+"] Already Added");
                                        return;
                                    }
                                    const newSnsCodes = oldSnsCodes+","+se;
                                    $("#qtyToChange_"+id).attr("snsCode", newSnsCodes);
                                    const newVal = String(parseFloat($("#qtyToChange_"+id).val()) + 1);
                                    $("#qtyToChange_"+id).val(newVal);
                                    self.calculateDiscountRes(true);
                                } else {
                                    const rowsLen = table.rows().data().length;
                                    for(var i = 0; i < rowsLen; ++i) {
                                        if(table.row(i).data()) {
                                            const rowId = table.row(i).data()[0];
                                            if(String(id) === String(rowId)) {
                                                table.row(i).remove().draw(false);
                                            }
                                        }
                                    }
                                    this.calculateDiscountRes(true);
                                }
                             }} />
                            <label class="queryAccessItem2 myLabelForCheckbox" style={{fontWeight: 100}} id={"labelItemId_"+id} for={"itemId_"+id}>({id}) {name} / Qty: ({qty})</label>
                            <br/>
                        </>
                    ]);
                }
                await this.setStateSynchronous({gotItems:allData});
                // this.setState({gotItems:allData});
                const rowsLen = table.rows().data().length;
                if(rowsLen > 0) {

                    // const itemsData = document.querySelectorAll(".queryAccessItem")
                    // for(var i = 0; i < itemsData.length; ++i) {
                    //     const isChecked = itemsData[i].checked;
                    //     if(isChecked) {
                    //         accessRes += itemsData[i].id;
                    //     }
                    // }

                    // console.log(addedArrayIds);
                    for(var i = 0; i < rowsLen; ++i) {
                        // console.log(i, rowsLen);
                        if(table.row(i).data()) {
                            const rowId = table.row(i).data()[0];
                            for(var b = 0; b < addedArrayIds.length; ++b) {
                                const id = String(addedArrayIds[b]);
                                // console.log(id, rowId);
                                if(id === String(rowId)) {
                                    document.getElementById("itemId_"+id).checked = true;
                                }
                            }
                        }
                    }
                }
                $("#dropdownDiv").css({display:"block"});
            }
            $(document).off("change", ".qtyToChangeClass");
            $(document).on("change", ".qtyToChangeClass", async function() {
                self.updateInvoiceEvent();
            });
            $(document).off("change", ".priceToChangeClass");
            $(document).on("change", ".priceToChangeClass", async function() {
                $(this).attr("selfEdited", "yes");
                // console.log($(this).attr("selfEdited"));
                self.updateInvoiceEvent();
            });
        })

    }

    setupCustomItem = () => {
        const table = window.$('#table1').DataTable();
        const id = "c-"+String(Math.random()).split(".")[1].substring(0, 6);
        const qtyToChange = "<input class='myInputStyle customItemEventChange' id='qtyCToChange_"+id+"' itemId='"+id+"' type='number' value='1' placeholder='' />";
        const priceToChange = "<input class='myInputStyle customItemEventChange' id='priceCToChange_"+id+"' itemId='"+id+"' type='number' placeholder='' />";
        const name = "<input class='myInputStyle' id='name_"+id+"' />"
        const barcode = "<input class='myInputStyle' id='barcode_"+id+"' />"
        const retail = "";
        const secRetail = "";
        const wholesale = "";
        const qty = "";
        const buttons = "";
        const outBranch = this.state.user.branch ? this.state.user.branch : $("#inBranches").val();
        $(document).off("change", ".customItemEventChange");
        $(document).on("change", ".customItemEventChange", async function() {
            const qtyId = "qtyCToChange_"+$(this).attr("itemId");
            const priceId = "priceCToChange_"+$(this).attr("itemId");
            const totalPriceVal = String(parseFloat($("#"+qtyId).val()) * parseFloat($("#"+priceId).val()));
            $("#totalInvoice").html(totalPriceVal);
            $("#finalInvoiceId").val(totalPriceVal);
        });
        table.row.add([id, name, barcode, retail, secRetail, wholesale, qty, qtyToChange, priceToChange, outBranch, buttons]).draw(true);
    }

    updateInvoiceEvent = () => {
        const data = $(".qtyToChangeClass");
        var total = 0;
        var final = 0;
        // console.log(data);
        for(var i = 0; i < data.length; ++i) {
            const priceItemId = "priceToChange_"+$(data[i]).attr("itemId");
            const qtyVal = $(data[i]).val();
            var priceVal = $("#"+priceItemId).attr("retail");
            if($("#sellType").val() === "wholesale") {
                priceVal = $("#"+priceItemId).attr("wholesale");
            }
            const currentPrice = $("#"+priceItemId).val();
            if(!qtyVal || parseFloat(qtyVal) < 1) {
                $("#totalInvoice").html("Qty Value ["+qtyVal+"] Unacceptable !");
                $("#totalInvoice").css({color: "red"});
                return;
            }
            if(!currentPrice || parseFloat(currentPrice) < 0) {
                $("#totalInvoice").html("Price Value ["+currentPrice+"] Unacceptable !");
                $("#totalInvoice").css({color: "red"});
                return;
            }
            final += parseFloat(qtyVal) * parseFloat(currentPrice);
            total += parseFloat(qtyVal) * parseFloat(priceVal);
        }
        $("#finalInvoiceId").val(final);
        // $("#inCashBoxId").val(final);
        // if($("#currencyId").val() === "LBP" && this.state.rate) {
        //     $("#inCashBoxId").val(String(parseFloat(final) * parseFloat(this.state.rate)));
        // }
        $("#totalInvoice").html(String(total));
        $("#totalInvoice").css({color: "black"});
    }

    calculateDiscountRes = (final) => {
        var disVal = $("#discountValId").val();
        if(!disVal) {
            disVal = "0";
        }
        // if(parseFloat($("#totalInvoice").html()) <= 0) {
        //     return;
        // }
        // if(!disVal) {
        //     $("#discountValId").val(0);
        //     this.calculateDiscountRes(final);
        //     return;
        // }
        // if(parseFloat(disVal) < 0) {
        //     window.toastr["error"]("Invalid Discount Value");
        //     return;
        // }
        // if(parseFloat(disVal) >= 100) {
        //     window.toastr["error"]("Invalid Discount Value");
        //     return;
        // }
        const data = $(".priceToChangeClass");
        for(var i = 0; i < data.length; ++i) {
            var price = $(data[i]).attr("retail");
            if($("#sellType").val() === "wholesale") {
                price = $(data[i]).attr("wholesale");
            }
            // const price = $(data[i]).val();
            const newPrice = String(parseFloat(price) - (parseFloat(price) / 100 * parseFloat(disVal)));
            if($(data[i]).attr("selfEdited") === "no") {
                $(data[i]).val(newPrice);
            }
        }
        if(final) {
            this.updateInvoiceEvent();
        }
    }

    Sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    sleep = async(secs) => {
        await this.Sleep(secs * 1000);
    }

    pLog = async(color, text) => {
        $("#errorLabel").css({color: color});
        $("#errorLabel").html(text);
        $("#errorLabel").css({display: ""});

        const interval = 75;
        for(var i = 0; i < 10; ++i) {
            await this.Sleep(interval);
            $("#errorLabel").css({color: "black"});
            await this.Sleep(interval);
            $("#errorLabel").css({color: "red"});
        }
        $("#errorLabel").css({color: color});
    }

    payInvoice = async() => {
        $("#errorLabel").css({display: "none"});
        const data = $(".qtyToChangeClass");
        if(data.length < 1) {
            this.pLog("red", "Nothing to pay, Please Add items");
            return;
        }
        const sellType = $("#sellType").val();
        const payType = $("#payType").val();
        const sellCustomer = $("#sellCustomer").val();
        const sellDest = $("#sellDest").val();
        const finalInvoiceVal = $("#finalInvoiceId").val();
        const totalInvoice = $("#totalInvoice").html();
        const note = $("#noteId").val();

        if(payType === "debt") {
            if(sellCustomer === "anonymous") {
                this.pLog("red", "For Paying Type `debt` Must select Customer to `Staff` or `Client`");
                return;
            }
        }
        var cashBoxUsdVal = $("#inCashBoxIdUsd").val();
        var cashBoxLbpVal = $("#inCashBoxIdLbp").val().replace(/,/g, "");
        if(isNaN(cashBoxLbpVal)) {
            this.pLog("red", "Value (cashBoxLbpVal) is not a number");
            return;
        }
        var totalCashBox = "";
        if(!cashBoxLbpVal && !cashBoxUsdVal) {
            this.pLog("red", "Please input Cashboxes money value");
            return;
        }
        totalCashBox = parseFloat(cashBoxUsdVal);
        if(cashBoxLbpVal) {
            totalCashBox += parseFloat(parseFloat(cashBoxLbpVal) / parseFloat(this.state.rate));
        }
        // if(parseFloat(totalCashBox) !== parseFloat(finalInvoiceVal) && payType === "cash") {
        //     // console.log(parseFloat(totalCashBox) - parseFloat(finalInvoiceVal));
        //     if(parseFloat(totalCashBox) - parseFloat(finalInvoiceVal) >= 1) {
        //         this.pLog("red", "In CashBox ("+totalCashBox+") not equal Final Invoice Cash ("+finalInvoiceVal+"), Fix it or set `Paying Type` to `debt`");
        //         return;
        //     }
        // }
        
        // this.pLog("red", "In CashBox ("+totalCashBox+") not equal Final Invoice Cash ("+finalInvoiceVal+"), Fix it or set `Paying Type` to `debt`");
        // return;
        var items = [];
        var snsCodes = "";
        for(var i = 0; i < data.length; ++i) {
            const priceItemId = "#priceToChange_"+$(data[i]).attr("itemId");
            const itemQty = $(data[i]).val();
            const itemName = $(data[i]).attr("itemName");
            const itemPrice = $(priceItemId).val();
            const itemBranch = $(data[i]).attr("itemBranch");
            const itemId = $(data[i]).attr("itemId");
            var itemBasePrice = $(data[i]).attr("itemRetailBasePrice");
            if(sellType === "wholesale") {
                itemBasePrice = $(data[i]).attr("itemWholesaleBasePrice");
            }
            if(parseFloat(itemQty) < 1) {
                this.pLog("red", "Invalid `Selling Qty` Value for Item ["+itemName+"]");
                return;
            }
            if(parseFloat(itemPrice) < 0) {
                this.pLog("red", "Invalid `Selling Price` Value for Item ["+itemName+"]");
                return;
            }
            if($(data[i]).attr("haveSns") === "yes") {
                snsCodes += $(data[i]).attr("snsCode")+",";
            }
            items.push({itemName, itemBranch, itemQty, itemPrice, itemId, itemBasePrice});
        }
        const branch = $("#inBranches").val();
        window.toastr["info"]("Selling Invoice...");
        $("#payInvoiceBtnId").hide();
        axios.post("/pay_invoice", {snsCode:snsCodes, note:note, branch:branch, totalInvoice:totalInvoice, items:items, sellType:sellType, payType:payType, sellCustomer:sellCustomer, sellDest:sellDest, finalInvoiceVal:finalInvoiceVal, cashBoxUsdVal:cashBoxUsdVal, cashBoxLbpVal:cashBoxLbpVal, totalCashBox:totalCashBox}).then((ok)=>{
            window.toastr.remove();
            $("#payInvoiceBtnId").show();
            if(ok.data.status === "error") {
                this.pLog("red", ok.data.msg);
            } else if(ok.data.status === "success") {
                $("#cashBoxUsdVal").html(parseFloat(ok.data.newCashBoxUsd).toLocaleString("en", {useGrouping:true}));
                $("#cashBoxLbpVal").html(parseFloat(ok.data.newCashBoxLbp).toLocaleString("en", {useGrouping:true}));
                window.$('#table1').DataTable().clear().draw();
                this.calculateDiscountRes(true);
                $("#searchItemsBox").val("");
                window.toastr[ok.data.status](ok.data.msg);
                $("#inCashBoxIdUsd").val(0);
                $("#inCashBoxIdLbp").val(0);
                $("#noteId").val("");
                $("#discountValId").val("");
                const invoiceId = ok.data.invoiceId;
                this.setState({invoicePrintId:invoiceId});
                const printData = ok.data.printItems;
                if(printData[0]) {
                    for(var i = 0; i < printData.length; ++i) {
                        const encId = Buffer.from(printData[i], "utf8").toString("base64");
                        window.open("/print-items-codes/"+encId, "_blank");
                    }
                }
                window.$("#invoicePrintData").modal("show");
            }
        });
    }

    setupCustomers = () => {
        const data = $("#sellCustomer").val();
        $("#sellDestTr").hide();
        $("#addClientBtn").css({display: "none"});
        if(data === "staff") {
            axios.post("/get_staff").then((ok)=>{
                $("#sellDestTr").show();
                if(ok.data.status == "error") {
                    this.setState({sellDestData:[
                        <option value="">{ok.data.msg}</option>
                    ]});
                } else {
                    var data = [];
                    for(var i = 0; i < ok.data.msg.length; ++i) {
                        data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                    }
                    this.setState({sellDestData:data});
                }
            })
        }
        if(data === "client") {
            axios.post("/get_suppliers").then((ok)=>{
                $("#sellDestTr").show();
                if(ok.data.status == "error") {
                    this.setState({sellDestData:[
                        <option value="">{ok.data.msg}</option>
                    ]});
                } else {
                    var data = [];
                    for(var i = 0; i < ok.data.msg.length; ++i) {
                        data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                    }
                    this.setState({sellDestData:data});
                }
                $("#addClientBtn").css({display: "inline"});
            })
        }
    }

    addClient = () => {
        const name = $("#nameInput").val();
        const phone = $("#phoneInput").val();
        const country = $("#countryInput").val();
        const balance = $("#balanceInput").val();
        if(!name) {
            window.toastr["error"]("Invalid Supplier Name");
            return;
        }
        axios.post("/add_supplier", {onlyAdmin:"no", name:name, phone:phone, country:country, balance:balance}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                this.setupCustomers();
                window.$("#addClientModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    fixInputValue = (id) => {
        const val = $(id).val();
        if(parseFloat(val) < 0) {
            $(id).val(0);
        }
        if(!val) {
            $(id).val(0);
        }
        if(val.length > 1 && val[0] === "0") {
            $(id).val(val.slice(1));
        }
    }

    payCustomItem = () => {
        $("#errorLabel").css({display: "none"});
        const sellType = $("#sellType").val();
        const payType = $("#payType").val();
        const sellCustomer = $("#sellCustomer").val();
        const sellDest = $("#sellDest").val();
        const finalInvoiceVal = $("#finalInvoiceId").val();
        const totalInvoice = $("#totalInvoice").html();
        const note = $("#noteId").val();

        if(payType === "debt") {
            if(sellCustomer === "anonymous") {
                this.pLog("red", "For Paying Type `debt` Must select Customer to `Staff` or `Client`");
                return;
            }
        }
        var cashBoxUsdVal = $("#inCashBoxIdUsd").val();
        var cashBoxLbpVal = $("#inCashBoxIdLbp").val().replace(/,/g, "");
        if(isNaN(cashBoxLbpVal)) {
            this.pLog("red", "Value (cashBoxLbpVal) is not a number");
            return;
        }
        var totalCashBox = "";
        if(!cashBoxLbpVal && !cashBoxUsdVal) {
            this.pLog("red", "Please input Cashboxes money value");
            return;
        }
        totalCashBox = parseFloat(cashBoxUsdVal);
        if(cashBoxLbpVal) {
            totalCashBox += parseFloat(parseFloat(cashBoxLbpVal) / parseFloat(this.state.rate));
        }
        // if(parseFloat(totalCashBox) !== parseFloat(finalInvoiceVal) && payType === "cash") {
        //     if(parseFloat(totalCashBox) - parseFloat(finalInvoiceVal) >= 1) {
        //         this.pLog("red", "In CashBox ("+totalCashBox+") not equal Final Invoice Cash ("+finalInvoiceVal+"), Fix it or set `Paying Type` to `debt`");
        //         return;
        //     }
        // }

        const data = $(".customItemEventChange");
        var items = [];
        const itemBranch = this.state.user.branch ? this.state.user.branch : $("#inBranches").val();
        for(var i = 0; i < data.length / 2; ++i) {
            const qtyId = "#qtyCToChange_"+$(data[i]).attr("itemId");
            const priceId = "#priceCToChange_"+$(data[i]).attr("itemId");
            const name = "#name_"+$(data[i]).attr("itemId");
            const itemName = $(name).val();
            const itemQty = $(qtyId).val();
            const itemPrice = $(priceId).val();
            const itemId = $(data[i]).attr("itemId");
            const itemBasePrice = itemPrice;
            if(!itemName) {
                this.pLog("red", "Invalid Custom Item Name");
                return;
            }
            if(!itemPrice) {
                this.pLog("red", "Invalid Custom Item Price");
                return;
            }
            if(!itemQty) {
                this.pLog("red", "Invalid Custom Item Qty");
                return;
            }
            if(!items.includes(itemName) && !items.includes(itemId)) {
                items.push({itemName, itemBranch, itemQty, itemPrice, itemId, itemBasePrice});
            }
        }

        // console.log(items);
        // this.pLog("red", "tested");
        // return;

        const branch = $("#inBranches").val();
        window.toastr["info"]("Selling Invoice...");
        $("#payInvoiceBtnId").hide();
        axios.post("/pay_invoice", {note:note, branch:branch, totalInvoice:totalInvoice, items:items, sellType:sellType, payType:payType, sellCustomer:sellCustomer, sellDest:sellDest, finalInvoiceVal:finalInvoiceVal, cashBoxUsdVal:cashBoxUsdVal, cashBoxLbpVal:cashBoxLbpVal, totalCashBox:totalCashBox}).then((ok)=>{
            window.toastr.remove();
            $("#payInvoiceBtnId").show();
            if(ok.data.status === "error") {
                this.pLog("red", ok.data.msg);
            } else if(ok.data.status === "success") {
                $("#cashBoxUsdVal").html(parseFloat(ok.data.newCashBoxUsd).toLocaleString("en", {useGrouping:true}));
                $("#cashBoxLbpVal").html(parseFloat(ok.data.newCashBoxLbp).toLocaleString("en", {useGrouping:true}));
                window.toastr[ok.data.status](ok.data.msg);
            }
        });
    }

    render() {
        return(
            <>

            <div class="modal fade" id="invoicePrintData" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Print Invoice
                        </div>
                        <div class="modal-body">
                            Do you want to print Invoice ({this.state.invoicePrintId}) ?
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' onClick={() => {
                                window.open("/print-a4/"+Buffer.from(this.state.invoicePrintId, "utf8").toString("base64"), "_blank");
                            }}>Print A4</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' onClick={() => {
                                window.open("/print-pos-80/"+Buffer.from(this.state.invoicePrintId, "utf8").toString("base64"), "_blank");
                            }}>Print POS 80</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' onClick={() => {
                                window.$("#invoicePrintData").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addClientModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Name:</th>
                                        <td><input class="myInputStyle" id="nameInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Phone:</th>
                                        <td><input class="myInputStyle" type="number" id="phoneInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Country:</th>
                                        <td><input class="myInputStyle" id="countryInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Balance:</th>
                                        <td><input class="myInputStyle" type="number" id="balanceInput"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addAdminBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.addClient();
                            }}>Add</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="FusePageCarded-header container">
                <div style={{flexDirection: "column"}} className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
                    <span className="MuiTypography-root MuiTypography-body1 flex text-24 md:text-32 font-extrabold tracking-tight muiltr-1niqtu4-MuiTypography-root" delay={300} style={{transform: 'none'}}>Selling Page</span>
                </div>
            </div>

            <div style={{padding: "0px 10px 0px 10px"}}>

                {this.state.addAdminsBtn}

                {/* <table className="table-modal" style={{width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                    <tbody>
                        
                    </tbody>
                </table>
                <br/> */}


                <table className="table-modal" style={{width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                    <tbody>
                        <tr>
                            <th>Sell Stock Item</th>
                            <td>
                                <label class="switch" style={{marginBottom: "0px"}}>
                                    <input type="checkbox" id="sellItemType" onChange={() => {
                                        window.$('#table1').DataTable().clear().draw();
                                        this.calculateDiscountRes(true);
                                        if($("#sellItemType").is(":checked")) {
                                            $(".mainSearchArea").hide();
                                            this.setupCustomItem();
                                        } else {
                                            $(".mainSearchArea").show();
                                        }
                                    }} />
                                    <span class="slider round" style={{backgroundColor: "#2196F3"}}></span>
                                </label>
                            </td>
                            <th>Sell Custom Item</th>
                        </tr>
                    </tbody>
                </table>
                <br/>

                <div class='dropdown mainSearchArea' style={{width: "100%"}} onMouseOver={() => { 
                    if($("#searchItemsBox").val()) {
                        $("#dropdownDiv").css({display: "block"});
                    }
                 }} onMouseLeave={() => { 
                    $("#dropdownDiv").css({display: "none"});
                  }}>
                    <input class='dropbtn myBtnStyle3 myDropdownBtnStyle' id="searchItemsBox" placeholder="Search for Items..." style={{width: "100%"}} onChange={() => {
                        this.processSearchItems();
                    }} onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            if(this.state.gotItems.length > 0) {
                                const inElement = this.state.gotItems[0][0].props.children[1];
                                const elementId = inElement.props.id;
                                $("#"+elementId).trigger("click");
                                $("#searchItemsBox").val("");
                                $("#dropdownDiv").css({display: "none"});
                                this.processSearchItems();
                            }
                            $("#searchItemsBox").focus();
                        }
                    }} />

                    <div class='dropdown-content-multi-search' id="dropdownDiv">
                        {this.state.gotItems}
                    </div>
                </div>
                <br className="mainSearchArea" />
                <br className="mainSearchArea" />

                <table class="table table-report dt-responsive -mt-2" id="table1" style={{width: "100%"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Barcode</th>
                            <th>Retail Price</th>
                            <th>Second Retail Price</th>
                            <th>Wholesale Price</th>
                            <th>Qty</th>
                            <th>Selling Qty</th>
                            <th>Selling Price</th>
                            <th>Branch</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <label id="errorLabel"></label>
                <button class='myBtnStyle' id="payInvoiceBtnId" tabIndex={0} type='button' onClick={() => {
                    if($("#sellItemType").is(":checked")) {
                        this.payCustomItem();
                    } else {
                        this.payInvoice();
                    }
                }}>Pay Invoice</button>
                <br/>

                <table className="table-modal" style={{width: "750px", textAlignLast: "start", backgroundColor: "silver"}}>
                    <tbody>
                        <tr>
                            <th style={{width: "110px"}}>Selling Type:</th>
                            <td>
                                <select id="sellType" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    const data = $(".priceToChangeClass");
                                    for(var i = 0; i < data.length; ++i) {
                                        $(data[i]).val($(data[i]).attr($("#sellType").val()));
                                    }
                                    this.updateInvoiceEvent();
                                }}>
                                    <option value="retail">Retail</option>
                                    <option value="wholesale">Wholesale</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Paying Type:</th>
                            <td>
                                <select id="payType" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    // window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    <option value="cash">Cash</option>
                                    <option value="debt">Debt</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Customer:</th>
                            <td>
                                <select id="sellCustomer" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    this.setupCustomers();
                                }}>
                                    <option value="anonymous">Anonymous</option>
                                    {/*<option value="staff">Staff</option>*/}
                                    <option value="client">Client</option>
                                </select>
                            </td>
                        </tr>
                        <tr id="sellDestTr" style={{display: "none"}}>
                            <th>Buyer:</th>
                            <td style={{display: "flex", alignItems: "center"}}>
                                <select id="sellDest" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    // window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    {this.state.sellDestData}
                                </select>
                                &nbsp;
                                <button id="addClientBtn" class='myBtnStyle' tabIndex={0} type='button' style={{display: "none", float: "right", borderRadius: "0px", height: "25px"}} onClick={() => {
                                    window.$("#addClientModal").modal("show");
                                }}>Add Client</button>
                            </td>
                        </tr>
                        <tr>
                            <th>Total Invoice:</th>
                            <td id="totalInvoice">0</td>
                        </tr>
                        <tr>
                            <th>Discount:</th>
                            <td><input class="myInputStyle" id="discountValId" style={{width: "90%"}} type="number" onChange={() => {
                                this.calculateDiscountRes(true);
                            }} />%</td>
                        </tr>
                        <tr>
                            <th>Final Invoice:</th>
                            <td><input class="myInputStyle" id="finalInvoiceId" type="number" onChange={() => {
                                this.fixInputValue("#finalInvoiceId");
                                const val = $("#finalInvoiceId").val();
                                const totalVal = $("#totalInvoice").html();
                                // if(parseFloat(val) >= parseFloat(totalVal)) {
                                //     $("#inCashBoxId").val(val);
                                //     $("#discountValId").val(0);
                                //     this.calculateDiscountRes(false);
                                //     return;
                                // }
                                const disValue = String(100 - (parseFloat(val) / parseFloat(totalVal) * 100));
                                // $("#inCashBoxId").val(val);
                                $("#discountValId").val(disValue);
                                this.calculateDiscountRes(false);
                            }} /></td>
                        </tr>
                        <br/>
                        {/* <br/> */}
                        <tr>
                            <th>In CashBox:</th>
                            <td style={{display: "flex", alignItems: "center"}}>
                                <div>
                                    <input class="myInputStyle" style={{display: "inline", marginBottom: "5px"}} id="inCashBoxIdUsd" placeholder="Received Cash" type="number" onChange={() => {
                                        this.fixInputValue("#inCashBoxIdUsd");
                                    }} onBlur={() => {
                                        const finalInvoice = $("#finalInvoiceId").val();
                                        const usdVal = $("#inCashBoxIdUsd").val();
                                        if(finalInvoice && parseFloat(finalInvoice) >= 0) {
                                            const leftLbpVal = String(parseFloat(parseFloat(finalInvoice) - parseFloat(usdVal)) * parseFloat(this.state.rate));
                                            $("#inCashBoxIdLbp").val(parseFloat(leftLbpVal).toLocaleString('en', {useGrouping:true}));
                                        }
                                    }} />
                                    <input class="myInputStyle" style={{display: "inline"}} id="inCashBoxIdLbp" placeholder="Received Cash" onChange={() => {
                                        const val = $("#inCashBoxIdLbp").val().replace(/,/g, "");
                                        if(val && !isNaN(val)) {
                                            $("#inCashBoxIdLbp").val(parseFloat(val).toLocaleString('en', {useGrouping:true}));
                                        }
                                        // this.fixInputValue("#inCashBoxIdLbp");
                                    }} />
                                </div>
                                &nbsp;
                                <select id="currencyId" className="myInputStyle" style={{width: "fit-content", display: "none"}} onChange={() => {
                                    if(!this.state.rate) {
                                        window.toastr["error"]("Failed to get Rate, Please write Price manually");
                                        return;
                                    }
                                    const moneyVal = $("#inCashBoxId").val();
                                    const newCurrency = $("#currencyId").val();
                                    if(parseFloat(moneyVal) < 0) {
                                        return;
                                    }
                                    if(!moneyVal) {
                                        return;
                                    }
                                    var newVal = "";
                                    if(newCurrency === "USD") {
                                        newVal = String(parseFloat(moneyVal) / parseFloat(this.state.rate));
                                    } else if(newCurrency === "LBP") {
                                        newVal = String(parseFloat(moneyVal) * parseFloat(this.state.rate));
                                    }
                                    $("#inCashBoxId").val(newVal);
                                }}>
                                    <option value="USD">USD</option>
                                    <option value="LBP">LBP</option>
                                </select>
                                &nbsp;
                                <label id="rateLabelId" style={{marginBottom: "0px", textAlignLast: "center"}}>Rate ({parseFloat(this.state.rate).toLocaleString("en", {useGrouping:true})})</label>
                                &nbsp;
                                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", borderRadius: "0px", height: "25px", width: "65%"}} onClick={() => {
                                    this.setupRate();
                                }}>Refresh Rate</button>
                            </td>
                        </tr>
                        <tr>
                            <th>Note:</th>
                            <td><input class="myInputStyle" id="noteId" style={{width: "100%"}} /></td>
                        </tr>
                        {!this.state.user.branch && <tr>
                            <th>Branch:</th>
                            <td>
                                <select id="inBranches" className="myInputStyle">
                                    {this.state.branchesList}
                                </select>
                            </td>
                        </tr>}
                    </tbody>
                </table>

            </div>
            </>
        )
    }
}

export default main;
