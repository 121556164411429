import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import {Buffer} from "buffer";

class login extends Component {
  state = {
    invoiceId: "",
    invoiceDate: "",
    customer: "",
    user: [],
    itemsData: []
  }
    componentWillMount() {
      axios.post("/is_authed").then((ok)=>{
        if(!ok.data) {
          this.props.history.push("/login");
        }
        if(!ok.data.access.includes("|44|")) {
            window.open("/panel", "_self");
            // this.props.history.push("/panel");
            return;
        }
        this.setState({user:ok.data});
      })
    }
    getMyDate = (date) => {
      return new Date(date).toLocaleString("en-GB", {timeZone: this.state.user.tz, hour12: "true"}).toLocaleUpperCase();
    }
    setStateSynchronous = (stateUpdate) => {
      return new Promise(resolve => {
          this.setState(stateUpdate, () => resolve());
      });
    }
    componentDidMount() {
      $("head").append('<link rel="stylesheet" title="myPrintPos80Style" href="/assets/style/print_pos_80.css" />');
      $("#root").removeAttr("class");
      $("#root").removeAttr("id");
      const base64InvoiceId = window.location.href.split("/")[window.location.href.split("/").length - 1];
      const invoiceId = Buffer.from(base64InvoiceId, "base64").toString("utf8");
      this.setState({invoiceId:invoiceId});
      axios.post("/get_invoice_data_print", {id:invoiceId}).then(async(ok)=>{
        if(ok.data.status === "error") {
          window.toastr[ok.data.status](ok.data.msg);
          return;
        }
        const data = ok.data.data;
        const invData = ok.data.invData;
        const itemsData = [];
        this.setState({invoiceDate:this.getMyDate(invData[0].date), customer:invData[0].customer, employee:invData[0].user});
        for(var i = 0; i < data.length; ++i) {
            if(data[i]) {
              itemsData.push([
                <>
                  <div style={{justifyContent: "space-between", display: "flex"}}>
                      <label style={{fontWeight: "100"}}>{data[i].item_qty}x&nbsp;({data[i].final_price}$)&nbsp;{data[i].item_name}</label>
                      <label style={{fontWeight: "100"}}>{data[i].total_price}$</label>
                  </div>
                  <h1 style={{marginTop: "10px", borderTop: "1px solid", marginBottom: "10px"}}></h1>
                </>
              ])
            }
        }
        const finalAmount = invData[0].final_price;
        const vatAmount = String(parseFloat(finalAmount) * parseFloat(String("0."+this.state.user.vat)));
        const lastFinalAmount = String(parseFloat(finalAmount) + parseFloat(vatAmount));
        const totalAmount = invData[0].base_price;
        await this.setStateSynchronous({itemsData:itemsData, totalAmount:totalAmount, discount:invData[0].discount_percent, finalAmount:finalAmount, vatAmount:vatAmount, lastAmount:lastFinalAmount,
                       invoiceType:invData[0].sell_type, invoiceStatus:invData[0].status, branch:invData[0].branch, phones:ok.data.phones, address:ok.data.address, mof:ok.data.mof,
                       rate:ok.data.rate});
        window.print();
      })
    }

    render() {
        return(
            <>
              <div class="book">
                  <div class="page" style={{lineHeight: "1.1"}}>
                    <label style={{fontSize: "40px", justifyContent: "center", display: "grid"}}>EasyTech</label>
                    <label style={{fontSize: "15px", justifyContent: "center", display: "grid"}}>Branch ({this.state.branch})</label>
                    <label style={{fontSize: "15px", justifyContent: "center", display: "grid"}}>Inv.({this.state.invoiceId}) {this.state.invoiceDate}</label>
                    <br/>
                    <table className="table-modal2" style={{width: '100%', textAlignLast: 'start', fontSize: "15px"}}>
                      <tbody>
                        <tr>
                          <th>Phone:</th>
                          <td>{this.state.phones}</td>
                        </tr>
                        <tr>
                          <th>Address:</th>
                          <td>{this.state.address}</td>
                        </tr>
                        <tr>
                          <th>VAT-Number:</th>
                          <td>{this.state.mof}</td>
                        </tr>
                        {/* <tr>
                          <th>Date:</th>
                          <td>{this.state.invoiceDate}</td>
                        </tr> */}
                        <tr>
                          <th>Employee:</th>
                          <td>{this.state.employee}</td>
                        </tr>
                        <tr>
                          <th>Customer:</th>
                          <td>{this.state.customer}</td>
                        </tr>
                      </tbody>
                    </table>

                    <br/>
                    
                    <h1 style={{marginTop: "10px", borderTop: "1px solid", marginBottom: "10px"}}></h1>
                    
                    {this.state.itemsData}

                    {/* <h1 style={{marginTop: "10px", borderTop: "1px solid", marginBottom: "10px"}}></h1> */}
                    <br/>
                    <table className="table-modal2" style={{width: '100%', textAlignLast: 'start'}}>
                      <tbody>
                        <tr>
                          <th>Invoice Type:</th>
                          <td>{this.state.invoiceType}</td>
                        </tr>
                        <tr>
                          <th>Pay Status:</th>
                          <td>{this.state.invoiceStatus}</td>
                        </tr>
                        <tr>
                          <th>Rate:</th>
                          <td>{parseFloat(this.state.rate).toLocaleString("en", {useGrouping: true})}</td>
                        </tr>
                        <tr>
                          <th>Total Amount:</th>
                          <td>{parseFloat(this.state.totalAmount).toLocaleString("en", {useGrouping: true})} USD</td>
                        </tr>
                        <tr>
                          <th>Discount:</th>
                          <td>{this.state.discount}%</td>
                        </tr>
                        <tr>
                          <th>Final Amount:</th>
                          <td>{parseFloat(this.state.finalAmount).toLocaleString("en", {useGrouping: true})} USD</td>
                        </tr>
                        <tr>
                          <th>VAT 11%:</th>
                          <td>{parseFloat(this.state.vatAmount).toFixed(2).toLocaleString("en", {useGrouping: true})} USD</td>
                        </tr>
                        <tr>
                          <th>Amount with VAT:</th>
                          <td>{parseFloat(this.state.lastAmount).toFixed(2).toLocaleString("en", {useGrouping: true})} USD</td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
              </div>
            </>
        )
    }
}

export default login;
