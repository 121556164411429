import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";

class main extends Component {

    state = {
        user: [],
        entries: [],
        perms: [],
        adminToDel: "",
        adminIdToDel: ""
    }

    setupDatatable() {
        const self = this;
        function getMyDate(date) {
            if(!date) {
                return "";
            }
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        window.$('#table1').DataTable({
            'columnDefs': [
            { "width": "100%", "targets": 1 },
            { className: 'dt-left', targets: 1 },
            ],
            // 'columnDefs': [{
            // 'targets': [1],
            // 'render': function(data, type, full, meta) {
            //             if(type === 'display'){ data = strtrunc(data, 10); } 
            //                 return data; 
            //           }
            // }],
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                // const date = $("#selectedDate").val();
                await axios.post('/move_stock_reqs_table', {/*date:date, */offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const item = resData[vCount].item;
                    const itemId = resData[vCount].item_id;
                    const qty = resData[vCount].qty;
                    const fromBranch = resData[vCount].from_branch;
                    const toBranch = resData[vCount].to_branch;
                    const requestBy = resData[vCount].request_by;
                    const answerBy = resData[vCount].answer_by;
                    const requestDate = getMyDate(resData[vCount].request_date);
                    const answerDate = getMyDate(resData[vCount].answer_date);
                    const status = resData[vCount].status;
                    
                    var htmlStatus = "";
                    if(status === "Pending") {
                        htmlStatus = "<p style='color: blue;'>"+status+"</p>";
                    }
                    if(status === "Rejected") {
                        htmlStatus = "<p style='color: red;'>"+status+"</p>";
                    }
                    if(status === "Approved") {
                        htmlStatus = "<p style='color: lightgreen;'>"+status+"</p>";
                    }

                    var buttons = "<div class='dropdown'>" +
                                        "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                        "<div class='dropdown-content'>"

                    if(status === "Pending") {
                        buttons += "<a class='myAStyle' id='reject_move' itemId='"+id+"' style='color: red !important;'>Reject</a>";
                        buttons += "<a class='myAStyle' id='approve_move' itemId='"+id+"'>Approve</a>";
                    }
                    buttons += "</div></div>";
                    
                    if(self.state.user.branch === fromBranch) {
                        buttons = "";
                    }

                    out.push([id, item, itemId, qty, fromBranch, toBranch, requestBy, answerBy, requestDate, answerDate, htmlStatus, buttons]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
        $(document).off("click", "#reject_move");
        $(document).on("click", "#reject_move", function() {
            const itemId = $(this).attr("itemId");
            axios.post("/reject_move_stock_request", {id:itemId}).then((ok)=>{
                window.toastr[ok.data.status](ok.data.msg);
                if(ok.data.status === "success") {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }
            })
        });
        $(document).off("click", "#approve_move");
        $(document).on("click", "#approve_move", function() {
            const itemId = $(this).attr("itemId");
            axios.post("/approve_move_stock_request", {id:itemId}).then((ok)=>{
                window.toastr[ok.data.status](ok.data.msg);
                if(ok.data.status === "success") {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }
            })
        });
    }
    getMonthDate = (monthNum) => {
        const date = new Date();
        date.setDate(1);
        date.setMonth(date.getMonth() + monthNum);
        const fYear = date.getFullYear();
        const mMonth = String(date.getMonth() + 1).length === 1 ? "0"+String(date.getMonth() + 1) : String(date.getMonth() + 1);
        const monthDate = fYear+"-"+mMonth;
        return monthDate;
    }
    componentDidMount() {
        axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            this.setState({user:ok.data});
            if(!ok.data.access.includes("|16|")) {
                this.props.history.push("/panel");
                return;
            }
            const nodeName = "#move-stock-reqs_btn";
            const interv3 = setInterval(function() {
                $(".MuiButtonBase-root").css({backgroundColor: ""});
                $(nodeName).css({backgroundColor: "cadetblue"});
                if($(nodeName).prop("style")) {
                    if($(nodeName).prop("style").backgroundColor === "cadetblue") {
                        clearInterval(interv3);
                    }
                }
            }, 1);
            this.setupDatatable();
            this.setupBranches();

            // if(ok.data.access.includes("|49|")) {
            //     this.setState({addAdminsBtn:[
            //         <>
            //             <button class='myBtnStyle' tabIndex={0} type='button' onClick={() => {
            //                 $("#addAdminBtn").show();
            //                 $("#editAdminBtn").hide();
            //                 this.setupBranches();
            //                 $("#expensDateIn").val(new Date().toISOString());
            //                 window.$("#addExpensItem").modal("show");
            //             }}>+ Add</button>
            //         </>
            //     ]})
            // }
        })
    }

    addExpens = () => {
        const amount = $("#expensAmountIn").val();
        const note = $("#expensNoteIn").val();
        const date = $("#expensDateIn").val();
        const branch = this.state.user.branch ? this.state.user.branch : $("#inBranchesx").val();
        if(!amount || parseFloat(amount) <= 0) {
            window.toastr["error"]("Invalid Amount");
            return;
        }
        if(!note || note.length < 5) {
            window.toastr["error"]("Invalid Note");
            return;
        }
        if(!date) {
            window.toastr["error"]("Invalid Date");
            return;
        }
        axios.post("/admin_add_expens_item", {amount:amount, note:note, branch:branch, date:date}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addExpensItem").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    setupBranches = () => {
        axios.post("/get_branches").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({branchesList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                // data.push(<option value="">{"<Make as Main Category>"}</option>);
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({branchesList:data});
            }
        })
    }

    deleteExpense = () => {
        const amount = this.state.adminToDel;
        const id = this.state.adminIdToDel;
        axios.post("/delete_expense_item", {id:id, amount:amount}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#deleteBranchModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    updateExpens = () => {
        const id = this.state.adminIdToDel;
        const amount = $("#expensAmountIn").val();
        const note = $("#expensNoteIn").val();
        const branch = this.state.user.branch ? this.state.user.branch : $("#inBranchesx").val();
        const date = $("#expensDateIn").val();
        if(!amount || parseFloat(amount) <= 0) {
            window.toastr["error"]("Invalid Amount");
            return;
        }
        if(!note || note.length < 5) {
            window.toastr["error"]("Invalid Note");
            return;
        }
        if(!date) {
            window.toastr["error"]("Invalid Date");
            return;
        }
        axios.post("/update_expens_item", {id:id, amount:amount, note:note, branch:branch, date:date}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addExpensItem").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    render() {
        return(
            <>
            
            <div class="modal fade" id="deleteBranchModal" role="dialog" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            Deleting Expense Item
                        </div>
                        <div class="modal-body">
                            Are you sure you want to remove Expense Item ({this.state.adminIdToDel}) with Amount ({this.state.adminToDel}) from Branch ({this.state.itemBranchToDel}) ?
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.deleteExpense();
                            }}>Yes</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                window.$("#deleteBranchModal").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addExpensItem" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Amount:</th>
                                        <td><input class="myInputStyle" type="number" id="expensAmountIn"/></td>
                                    </tr>
                                    <tr>
                                        <th>Note:</th>
                                        <td><input class="myInputStyle" id="expensNoteIn"/></td>
                                    </tr>
                                    <tr>
                                        <th>Date:</th>
                                        <td><input class="myInputStyle" id="expensDateIn"/></td>
                                    </tr>
                                    {!this.state.user.branch && <tr>
                                        <th>Branch:</th>
                                        <td>
                                            <select id="inBranchesx" className="myInputStyle">
                                                {this.state.branchesList}
                                            </select>
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addAdminBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.addExpens();
                            }}>Add</button>

                            <button class='myBtnStyle' id='editAdminBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.updateExpens();
                            }}>Update</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="FusePageCarded-header container">
                <div style={{flexDirection: "column"}} className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
                    <span className="MuiTypography-root MuiTypography-body1 flex text-24 md:text-32 font-extrabold tracking-tight muiltr-1niqtu4-MuiTypography-root" delay={300} style={{transform: 'none'}}>Move Stock Requests Page</span>
                </div>
            </div>

            <div style={{padding: "0px 10px 0px 10px"}}>

                {/*
                {this.state.addAdminsBtn}
                
                <br/>
                
                <table className="table-modal" style={{width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                    <tbody>
                        <tr>
                            <th>Date:</th>
                            <td>
                                <select id="selectedDate" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    <option value="">All</option>
                                    <option value={this.getMonthDate(0)}>{this.getMonthDate(0)}</option>
                                    <option value={this.getMonthDate(-1)}>{this.getMonthDate(-1)}</option>
                                    <option value={this.getMonthDate(-2)}>{this.getMonthDate(-2)}</option>
                                    <option value={this.getMonthDate(-3)}>{this.getMonthDate(-3)}</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                */}

                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>Refresh</button>
                <br/>
                <br/>
                <table class="table table-report dt-responsive -mt-2" id="table1" style={{width: "100%", fontSize: "smaller"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Item</th>
                            <th>Item ID</th>
                            <th>Qty</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Request By</th>
                            <th>Answer By</th>
                            <th>Request Date</th>
                            <th>Answer Date</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }
}

export default main;
