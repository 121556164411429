import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";

class main extends Component {

    state = {
        user: [],
        entries: [],
        perms: [],
        adminToDel: "",
        adminIdToDel: "",
        allowCost: "",
        itemsCats: [],
        branchesList: [],
        suppliersList: [],
        itemIdLogsHistory: "",
        imagesScanned: []
    }

    async setupDatatable() {
        const self = this;
        function getMyDate(date) {
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        window.$('#table1').DataTable({
            // 'columnDefs': [
            // { "width": "100%", "targets": 1 },
            // { className: 'dt-left', targets: 1 },
            // ],
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount;
                var resData = []
                await axios.post('/money_operations_table', {offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const user = resData[vCount].user;
                    const type = resData[vCount].type;
                    const refId = resData[vCount].ref_id;
                    const totalAmount = resData[vCount].total_amount;
                    const usdAmount = resData[vCount].usd_amount;
                    const lbpAmount = resData[vCount].lbp_amount;
                    const date = getMyDate(resData[vCount].date);
                    const note = resData[vCount].note;
                    const branch = resData[vCount].branch;
                    const currency = resData[vCount].currency;
                    var buttons = "<div class='dropdown'>" +
                                        "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                        "<div class='dropdown-content'>"
                    if(self.state.user.access.includes("|42|")) {
                        buttons += "<a class='myAStyle' id='edit_operation' itemUser='"+user+"' itemId='"+id+"' type='"+type+"' refId='"+refId+"' \
                                    totalAmount='"+totalAmount+"' usdAmount='"+usdAmount+"' lbpAmount='"+lbpAmount+"' note='"+note+"' \
                                    currency='"+currency+"' branch='"+branch+"'>Update</a>";
                    }
                    if(self.state.user.access.includes("|43|")) {
                        buttons += "<a class='myAStyle' id='delete_operation' itemUser='"+user+"' itemId='"+id+"' usdAmount='"+usdAmount+"' lbpAmount='"+lbpAmount+"' style='color: red !important;'>Delete</a>";
                    }
                    buttons += "</div></div>";

                    out.push([id, user, type, refId, totalAmount, usdAmount, lbpAmount, date, note, branch, currency, buttons]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
        $(document).off("click", "#edit_operation");
        $(document).on("click", "#edit_operation", function() {
            const itemId = $(this).attr("itemId");
            const type = $(this).attr("type");
            const refId = $(this).attr("refId");
            const totalAmount = $(this).attr("totalAmount");
            const usdAmount = $(this).attr("usdAmount");
            const lbpAmount = $(this).attr("lbpAmount");
            const note = $(this).attr("note");
            const currency = $(this).attr("currency");
            const branch = $(this).attr("branch");
            const itemUser = $(this).attr("itemUser");

            $("#operationType2").val(type);
            $("#refBoxId2").val(refId);
            $("#totalAmountBoxId2").val(totalAmount);
            $("#usdAmountBoxId2").val(usdAmount);
            $("#lbpAmountBoxId2").val(lbpAmount);
            $("#noteBoxId2").val(note);
            $("#currencyId2").val(currency);
            $("#inBranches2").val(branch);

            self.setState({adminIdToDel:itemId, adminToDel:itemUser});
            window.$("#updateOperationModal").modal("show");
        });
        $(document).off("click", "#delete_operation");
        $(document).on("click", "#delete_operation", function() {
            const itemId = $(this).attr("itemId");
            const usdAmount = $(this).attr("usdAmount");
            const lbpAmount = $(this).attr("lbpAmount");
            const itemUser = $(this).attr("itemUser");
            self.setState({adminIdToDel:itemId, adminToDel:itemUser, usdAmount:usdAmount, lbpAmount:lbpAmount});
            window.$("#deleteOperationModal").modal("show");
        });
    }

    componentDidMount() {
        axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            this.setState({user:ok.data});
            if(!ok.data.access.includes("|40|")) {
                this.props.history.push("/panel");
                return;
            }
            const nodeName = "#moneyOperations_btn";
            const interv3 = setInterval(function() {
                $(".MuiButtonBase-root").css({backgroundColor: ""});
                $(nodeName).css({backgroundColor: "cadetblue"});
                if($(nodeName).prop("style")) {
                    if($(nodeName).prop("style").backgroundColor === "cadetblue") {
                        clearInterval(interv3);
                    }
                }
            }, 1);
            this.setupDatatable();
            this.setupBranches();
            this.setupRate();

            if(ok.data.access.includes("|41|")) {
                $("#addOperationTable").show();
            }
        })
    }
    setupBranches = () => {
        axios.post("/get_branches").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({branchesList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({branchesList:data});
            }
        })
    }

    addMoneyOperation = () => {
        const type = $("#operationType").val();
        const refId = $("#refBoxId").val();
        const totalAmount = $("#totalAmountBoxId").val().replace(/,/g, "") ? $("#totalAmountBoxId").val().replace(/,/g, "") : "0";
        const usdAmount = $("#usdAmountBoxId").val().replace(/,/g, "") ? $("#usdAmountBoxId").val().replace(/,/g, "") : "0";
        const lbpAmount = $("#lbpAmountBoxId").val().replace(/,/g, "") ? $("#lbpAmountBoxId").val().replace(/,/g, "") : "0";
        const note = $("#noteBoxId").val();
        const branch = this.state.user.branch ? this.state.user.branch : $("#inBranches").val();
        const currency = $("#currencyId").val();
        if(!refId) {
            window.toastr["error"]("Please Input Reference ID");
            return;
        }
        if(type === "in" && parseFloat(totalAmount) <= 0) {
            window.toastr["error"]("For Operation Type[in], Total Amount Must be Bigger than Zero");
            return;
        }
        if(type === "out" && parseFloat(totalAmount) >= 0) {
            window.toastr["error"]("For Operation Type[out], Total Amount Must be Less than Zero");
            return;
        }
        // if(currency === "usd") {
        //     const totalUsdAmount = String(parseFloat(usdAmount) + parseFloat(parseFloat(lbpAmount) / parseFloat(this.state.rate)));
        //     if(totalAmount !== totalUsdAmount) {
        //         window.toastr["error"]("Total ["+type+"] Amount ["+totalAmount+"] not Equal Total USD Amount ["+totalUsdAmount+"]");
        //         return;
        //     }
        // }
        // if(currency === "lbp") {
        //     const totalLbpAmount = String(parseFloat(lbpAmount) + parseFloat(parseFloat(usdAmount) * parseFloat(this.state.rate)));
        //     if(totalAmount !== totalLbpAmount) {
        //         window.toastr["error"]("Total ["+type+"] Amount ["+totalAmount+"] not Equal Total LBP Amount ["+totalLbpAmount+"]");
        //         return;
        //     }
        // }
        axios.post("/add_money_operation", {currency:currency, type:type, refId:refId, totalAmount:totalAmount, usdAmount:usdAmount, lbpAmount:lbpAmount, note:note, branch:branch}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                $("#cashBoxUsdVal").html(parseFloat(ok.data.newCashBoxUsd).toLocaleString("en", {useGrouping:true}));
                $("#cashBoxLbpVal").html(parseFloat(ok.data.newCashBoxLbp).toLocaleString("en", {useGrouping:true}));
                window.$('#table1').DataTable().ajax.reload(null, false);
                $("#refBoxId").val("");
                $("#totalAmountBoxId").val("");
                $("#usdAmountBoxId").val("");
                $("#lbpAmountBoxId").val("");
                $("#noteBoxId").val("");
            }
        })
    }
    setupRate = () => {
        axios.post("/get_rate").then((ok)=>{
            if(ok.data) {
                this.setState({rate:ok.data});
            }
        })
    }

    updateOperationData = () => {
        const operationId = this.state.adminIdToDel;
        const type = $("#operationType2").val();
        const refId = $("#refBoxId2").val();
        const totalAmount = $("#totalAmountBoxId2").val().replace(/,/g, "") ? $("#totalAmountBoxId2").val().replace(/,/g, "") : "0";
        const usdAmount = $("#usdAmountBoxId2").val().replace(/,/g, "") ? $("#usdAmountBoxId2").val().replace(/,/g, "") : "0";
        const lbpAmount = $("#lbpAmountBoxId2").val().replace(/,/g, "") ? $("#lbpAmountBoxId2").val().replace(/,/g, "") : "0";
        const note = $("#noteBoxId2").val();
        const branch = this.state.user.branch ? this.state.user.branch : $("#inBranches2").val();
        const currency = $("#currencyId2").val();
        const operationUser = this.state.adminToDel;
        if(!refId) {
            window.toastr["error"]("Please Input Reference ID");
            return;
        }
        if(type === "in" && parseFloat(totalAmount) <= 0) {
            window.toastr["error"]("For Operation Type[in], Total Amount Must be Bigger than Zero");
            return;
        }
        if(type === "out" && parseFloat(totalAmount) >= 0) {
            window.toastr["error"]("For Operation Type[out], Total Amount Must be Less than Zero");
            return;
        }
        if(currency === "usd") {
            const totalUsdAmount = String(parseFloat(usdAmount) + parseFloat(parseFloat(lbpAmount) / parseFloat(this.state.rate)));
            if(totalAmount !== totalUsdAmount) {
                window.toastr["error"]("Total ["+type+"] Amount ["+totalAmount+"] not Equal Total USD Amount ["+totalUsdAmount+"]");
                return;
            }
        }
        if(currency === "lbp") {
            const totalLbpAmount = String(parseFloat(lbpAmount) + parseFloat(parseFloat(usdAmount) * parseFloat(this.state.rate)));
            if(totalAmount !== totalLbpAmount) {
                window.toastr["error"]("Total ["+type+"] Amount ["+totalAmount+"] not Equal Total LBP Amount ["+totalLbpAmount+"]");
                return;
            }
        }
        axios.post("/update_money_operation", {operationUser:operationUser, operationId:operationId, currency:currency, type:type, refId:refId, totalAmount:totalAmount, usdAmount:usdAmount, lbpAmount:lbpAmount, note:note, branch:branch}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                $("#cashBoxUsdVal").html(parseFloat(ok.data.newCashBoxUsd).toLocaleString("en", {useGrouping:true}));
                $("#cashBoxLbpVal").html(parseFloat(ok.data.newCashBoxLbp).toLocaleString("en", {useGrouping:true}));
                window.$('#table1').DataTable().ajax.reload(null, false);
                window.$("#updateOperationModal").modal("hide");
            }
        })
    }

    deleteOperation = () => {
        const id = this.state.adminIdToDel;
        const operationUser = this.state.adminToDel;
        axios.post("/delete_money_operation", {id:id, operationUser:operationUser}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                $("#cashBoxUsdVal").html(parseFloat(ok.data.newCashBoxUsd).toLocaleString("en", {useGrouping:true}));
                $("#cashBoxLbpVal").html(parseFloat(ok.data.newCashBoxLbp).toLocaleString("en", {useGrouping:true}));
                window.$('#table1').DataTable().ajax.reload(null, false);
                window.$("#deleteOperationModal").modal("hide");
            }
        })
    }

    render() {
        return(
            <>

            <div class="modal fade" id="deleteOperationModal" role="dialog" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            Deleting Money Operation
                        </div>
                        <div class="modal-body">
                            Are you sure you want to remove Money Operation ({this.state.adminIdToDel}) completely ?<br/>
                            This will Apply the following changes to your Cashbox:<br/>
                            USD: [{String(this.state.usdAmount).includes("-") ? String(this.state.usdAmount).replace("-", "+") : String("-"+this.state.usdAmount).replace("-0", "0").replace("+0", "0")}]<br/>
                            LBP: [{String(this.state.lbpAmount).includes("-") ? String(this.state.lbpAmount).replace("-", "+") : String("-"+this.state.lbpAmount).replace("-0", "0").replace("+0", "0")}]
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.deleteOperation();
                            }}>Yes</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                window.$("#deleteOperationModal").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="updateOperationModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Update Money Operation ({this.state.adminIdToDel}) Data
                        </div>
                        <div class="modal-body">
                            <table tabIndex="0" className="table-modal" style={{width: "100%", textAlignLast: "start", backgroundColor: "silver"}} onKeyDown={(e) => {
                                if(e.key === "Enter") {
                                    this.updateOperationData();
                                }
                            }}>
                                <tbody>
                                    <tr>
                                        <th>Operation IN/OUT:</th>
                                        <td>
                                            <select id="operationType2" className="myInputStyle" style={{width: "100%"}}>
                                                <option value="in">IN</option>
                                                <option value="out">OUT</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Reference:</th>
                                        <td><input class="myInputStyle" id="refBoxId2" placeholder="Like MTCN Number"/></td>
                                    </tr>
                                    <tr>
                                        <th>Total Amount:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <input class="myInputStyle" id="totalAmountBoxId2" placeholder="Total Cash With Currency" onChange={() => {
                                                const val = $("#totalAmountBoxId2").val().replace(/,/g, "");
                                                if(val && !isNaN(val) && val.slice(-1) !== ".") {
                                                    $("#totalAmountBoxId2").val(parseFloat(val).toLocaleString('en', {useGrouping:true, maximumFractionDigits: 10}));
                                                }
                                            }} />
                                            &nbsp;
                                            <select id="currencyId2" className="myInputStyle">
                                                <option value="usd">USD</option>
                                                <option value="lbp">LBP</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>USD Amount:</th>
                                        <td><input class="myInputStyle" id="usdAmountBoxId2" placeholder="USD Cash" onChange={() => {
                                            const val = $("#usdAmountBoxId2").val().replace(/,/g, "");
                                            if(val && !isNaN(val) && val.slice(-1) !== ".") {
                                                $("#usdAmountBoxId2").val(parseFloat(val).toLocaleString('en', {useGrouping:true, maximumFractionDigits: 10}));
                                                const totalAmount = $("#totalAmountBoxId2").val().replace(/,/g, "");
                                                if(totalAmount && $("#currencyId2").val() === "usd") {
                                                    const leftLbp = String(parseFloat(parseFloat(totalAmount) - parseFloat(val)) * parseFloat(this.state.rate));
                                                    $("#lbpAmountBoxId2").val(parseFloat(leftLbp).toLocaleString("en", {useGrouping: true}));
                                                }
                                            }

                                        }} /></td>
                                    </tr>
                                    <tr>
                                        <th>LBP Amount:</th>
                                        <td><input class="myInputStyle" id="lbpAmountBoxId2" placeholder="LBP Cash" onChange={() => {
                                            const val = $("#lbpAmountBoxId2").val().replace(/,/g, "");
                                            if(val && !isNaN(val) && val.slice(-1) !== ".") {
                                                $("#lbpAmountBoxId2").val(parseFloat(val).toLocaleString('en', {useGrouping:true, maximumFractionDigits: 10}));
                                                const totalAmount = $("#totalAmountBoxId2").val().replace(/,/g, "");
                                                if(totalAmount && $("#currencyId2").val() === "lbp") {
                                                    const leftUsd = String(parseFloat(parseFloat(totalAmount) - parseFloat(val)) / parseFloat(this.state.rate));
                                                    $("#usdAmountBoxId2").val(parseFloat(leftUsd).toLocaleString("en", {useGrouping: true}));
                                                }
                                            }
                                        }} /></td>
                                    </tr>
                                    <tr>
                                        <th>Note:</th>
                                        <td><input class="myInputStyle" id="noteBoxId2" placeholder="Anything..." /></td>
                                    </tr>
                                    {!this.state.user.branch && <tr>
                                        <th>Branch:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <select id="inBranches2" className="myInputStyle">
                                                {this.state.branchesList}
                                            </select>
                                            &nbsp;
                                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", borderRadius: "0px", height: "25px", width: "70%"}} onClick={() => {
                                                this.setupBranches();
                                            }}>Refresh Branches</button>
                                        </td>
                                    </tr>}
                                    <tr>
                                        <th>Rate:</th>
                                        <td style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                            <label id="rateLabelBoxMoney2" style={{marginBottom: "0px", fontWeight: "500"}}>Rate ({parseFloat(this.state.rate).toLocaleString("en", {useGrouping: true})})</label>
                                            &nbsp;
                                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", borderRadius: "0px", height: "25px", width: "70%"}} onClick={() => {
                                                this.setupRate();
                                            }}>Refresh</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.updateOperationData();
                            }}>Update</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="FusePageCarded-header container">
                <div style={{flexDirection: "column"}} className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
                    <span className="MuiTypography-root MuiTypography-body1 flex text-24 md:text-32 font-extrabold tracking-tight muiltr-1niqtu4-MuiTypography-root" delay={300} style={{transform: 'none'}}>Money Operations Page</span>
                </div>
            </div>

            <div style={{padding: "0px 10px 0px 10px"}}>

                <table tabIndex="0" className="table-modal" id="addOperationTable" style={{display: "none", width: "600px", textAlignLast: "start", backgroundColor: "silver"}} onKeyDown={(e) => {
                    if(e.key === "Enter") {
                        this.addMoneyOperation();
                    }
                }}>
                    <tbody>
                        <tr>
                            <th>Operation IN/OUT:</th>
                            <td>
                                <select id="operationType" className="myInputStyle" style={{width: "100%"}} onChange={() => {
                                    // if($("#operationType").val() === "in") {
                                    //     $("#usdAmountBoxId").placeholder("-USD Amount");
                                    // } else {
                                    //     $("#usdAmountBoxId").placeholder("USD Amount");
                                    // }
                                }}>
                                    <option value="in">IN</option>
                                    <option value="out">OUT</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Reference:</th>
                            <td><input class="myInputStyle" id="refBoxId" placeholder="Like MTCN Number"/></td>
                        </tr>
                        <tr>
                            <th>Total Amount:</th>
                            <td style={{display: "flex", alignItems: "center"}}>
                                <input class="myInputStyle" id="totalAmountBoxId" placeholder="Total Cash With Currency" onChange={() => {
                                    const val = $("#totalAmountBoxId").val().replace(/,/g, "");
                                    if(val && !isNaN(val) && val.slice(-1) !== ".") {
                                        $("#totalAmountBoxId").val(parseFloat(val).toLocaleString("en", {useGrouping: true, maximumFractionDigits: 10}));
                                    }
                                }} />
                                &nbsp;
                                <select id="currencyId" className="myInputStyle">
                                    <option value="usd">USD</option>
                                    <option value="lbp">LBP</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>USD Amount:</th>
                            <td><input class="myInputStyle" id="usdAmountBoxId" placeholder="USD Cash" onChange={() => {
                                const val = $("#usdAmountBoxId").val().replace(/,/g, "");
                                if(val && !isNaN(val) && val.slice(-1) !== ".") {
                                    $("#usdAmountBoxId").val(parseFloat(val).toLocaleString('en', {useGrouping:true, maximumFractionDigits: 10}));
                                    const totalAmount = $("#totalAmountBoxId").val().replace(/,/g, "");
                                    if(totalAmount && $("#currencyId").val() === "usd") {
                                        const leftLbp = String(parseFloat(parseFloat(totalAmount) - parseFloat(val)) * parseFloat(this.state.rate));
                                        $("#lbpAmountBoxId").val(parseFloat(leftLbp).toLocaleString("en", {useGrouping: true}));
                                    }
                                }

                            }} /></td>
                        </tr>
                        <tr>
                            <th>LBP Amount:</th>
                            <td><input class="myInputStyle" id="lbpAmountBoxId" placeholder="LBP Cash" onChange={() => {
                                const val = $("#lbpAmountBoxId").val().replace(/,/g, "");
                                if(val && !isNaN(val) && val.slice(-1) !== ".") {
                                    $("#lbpAmountBoxId").val(parseFloat(val).toLocaleString('en', {useGrouping:true, maximumFractionDigits: 10}));
                                    const totalAmount = $("#totalAmountBoxId").val().replace(/,/g, "");
                                    if(totalAmount && $("#currencyId").val() === "lbp") {
                                        const leftUsd = String(parseFloat(parseFloat(totalAmount) - parseFloat(val)) / parseFloat(this.state.rate));
                                        $("#usdAmountBoxId").val(parseFloat(leftUsd).toLocaleString("en", {useGrouping: true}));
                                    }
                                }
                            }} /></td>
                        </tr>
                        <tr>
                            <th>Note:</th>
                            <td><input class="myInputStyle" id="noteBoxId" placeholder="Anything..." /></td>
                        </tr>
                        {!this.state.user.branch && <tr>
                            <th>Branch:</th>
                            <td style={{display: "flex", alignItems: "center"}}>
                                <select id="inBranches" className="myInputStyle">
                                    {this.state.branchesList}
                                </select>
                                &nbsp;
                                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", borderRadius: "0px", height: "25px", width: "70%"}} onClick={() => {
                                    this.setupBranches();
                                }}>Refresh Branches</button>
                            </td>
                        </tr>}
                        <tr>
                            <th>Rate:</th>
                            <td style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                <label id="rateLabelBoxMoney" style={{marginBottom: "0px", fontWeight: "500"}}>Rate ({parseFloat(this.state.rate).toLocaleString("en", {useGrouping: true})})</label>
                                &nbsp;
                                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", borderRadius: "0px", height: "25px", width: "70%"}} onClick={() => {
                                    this.setupRate();
                                }}>Refresh</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {/* <br/> */}

                <button class='myBtnStyle' tabIndex={0} type='button' onClick={() => {
                    this.addMoneyOperation();
                }}>Add Money Operation</button>

                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>Refresh</button>
                <br/>
                <br/>

                {this.state.addAdminsBtn}

                {/* <label>Category: </label>
                &nbsp;
                <select id="selectedCat" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>
                    {this.state.itemsCats}
                </select>
                <br/>

                <label>Supplier: </label>
                &nbsp;
                <select id="selectedSupplier" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>
                    {this.state.suppliersList}
                </select>
                <br/>
                <br/> */}

                <table class="table table-report dt-responsive -mt-2" id="table1" style={{width: "100%"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>User</th>
                            <th>Type</th>
                            <th>Reference ID</th>
                            <th>Total Amount</th>
                            <th>USD Amount</th>
                            <th>LBP Amount</th>
                            <th>Date</th>
                            <th>Note</th>
                            <th>Branch</th>
                            <th>Currency</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }
}

export default main;
