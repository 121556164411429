import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";

class main extends Component {

    state = {
        user: [],
        entries: [],
        perms: [],
        adminToDel: "",
        adminIdToDel: ""
    }

    setupDatatable() {
        const self = this;
        function getMyDate(date) {
            if(!date) {
                return "";
            }
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        window.$('#table1').DataTable({
            'columnDefs': [{
            'targets': [1],
            'render': function(data, type, full, meta) {
                        if(type === 'display'){ data = strtrunc(data, 10); } 
                            return data; 
                      }
            }],
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                const date = $("#selectedDate").val();
                await axios.post('/expenses_table', {date:date, offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const user = resData[vCount].user;
                    const amount = resData[vCount].amount;
                    const note = resData[vCount].note;
                    const date = getMyDate(resData[vCount].date);
                    const branch = resData[vCount].branch;
                    const status = resData[vCount].status;
                    // const file = resData[vCount].file;

                    var buttons = "<div class='dropdown'>" +
                                        "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                        "<div class='dropdown-content'>"
                    buttons += "<a class='myAStyle' id='view_img' itemId='"+id+"' amount='"+amount+"' note='"+note+"' branch='"+branch+"' date='"+resData[vCount].date+"'>View Invoice Photo</a>";
                    if(self.state.user.access.includes("|51|")) {
                        buttons += "<a class='myAStyle' id='delete_branch' itemId='"+id+"' amount='"+amount+"' branch='"+branch+"' style='color: red !important;'>Delete</a>";
                    }
                    if(self.state.user.access.includes("|50|")) {
                        buttons += "<a class='myAStyle' id='edit_branch' itemId='"+id+"' amount='"+amount+"' note='"+note+"' branch='"+branch+"' date='"+resData[vCount].date+"'>Update</a>";
                    }
                    buttons += "</div></div>";

                    out.push([id, user, amount, note, date, branch, status, buttons]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
        $(document).off("click", "#delete_branch");
        $(document).on("click", "#delete_branch", function() {
            const itemId = $(this).attr("itemId");
            const itemAmount = $(this).attr("amount");
            const branch = $(this).attr("branch");
            self.setState({adminToDel:itemAmount, adminIdToDel:itemId, itemBranchToDel:branch});
            window.$("#deleteBranchModal").modal("show");
        });

        $(document).off("click", "#edit_branch");
        $(document).on("click", "#edit_branch", function() {
            const itemId = $(this).attr("itemId");
            const itemAmount = $(this).attr("amount");
            const itemNote = $(this).attr("note");
            const branch = $(this).attr("branch");
            const date = $(this).attr("date");
            self.setState({adminIdToDel:itemId});
            $("#addAdminBtn").hide();
            $("#editAdminBtn").show();
            $("#expensAmountIn").val(itemAmount);
            $("#expensNoteIn").val(itemNote);
            self.setupBranches();
            $("#inBranchesx").val(branch);
            $("#expensDateIn").val(new Date(date).toISOString());
            window.$("#addExpensItem").modal("show");
        });
        
        $(document).off("click", "#view_img");
        $(document).on("click", "#view_img", function() {
            const itemId = $(this).attr("itemId");
            const itemAmount = $(this).attr("amount");
            const itemNote = $(this).attr("note");
            const branch = $(this).attr("branch");
            const date = $(this).attr("date");
            
            window.toastr["info"]("Loading data...");
            axios.post("/get_expenses_img", {id:itemId}).then((ok)=>{
                window.toastr.remove();
                if(ok.data.status === "error") {
                    window.toastr[ok.data.status](ok.data.msg);
                } else {
                    $("#setItemId0").html(itemId);
                    $("#setItemNote0").html(itemNote);
                    $("#setItemAmount0").html(itemAmount);
                    $("#setItemBranch0").html(branch);
                    $("#setItemDate0").html(date);
                    $("#setItemPhoto0").attr("src", ok.data.data);

                    window.$("#viewItemImgModal").modal("show");
                }
            })
        });
    }
    getMonthDate = (monthNum) => {
        const date = new Date();
        date.setDate(1);
        date.setMonth(date.getMonth() + monthNum);
        const fYear = date.getFullYear();
        const mMonth = String(date.getMonth() + 1).length === 1 ? "0"+String(date.getMonth() + 1) : String(date.getMonth() + 1);
        const monthDate = fYear+"-"+mMonth;
        return monthDate;
    }
    componentDidMount() {
        axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            this.setState({user:ok.data});
            if(!ok.data.access.includes("|48|")) {
                this.props.history.push("/panel");
                return;
            }
            const nodeName = "#expenses_btn";
            const interv3 = setInterval(function() {
                $(".MuiButtonBase-root").css({backgroundColor: ""});
                $(nodeName).css({backgroundColor: "cadetblue"});
                if($(nodeName).prop("style")) {
                    if($(nodeName).prop("style").backgroundColor === "cadetblue") {
                        clearInterval(interv3);
                    }
                }
            }, 1);
            this.setupDatatable();
            this.setupBranches();

            if(ok.data.access.includes("|49|")) {
                this.setState({addAdminsBtn:[
                    <>
                        <button class='myBtnStyle' tabIndex={0} type='button' onClick={() => {
                            $("#addAdminBtn").show();
                            $("#editAdminBtn").hide();
                            this.setupBranches();
                            $("#expensDateIn").val(new Date().toISOString());
                            window.$("#addExpensItem").modal("show");
                        }}>+ Add</button>
                    </>
                ]})
            }
        })
    }

    addExpens = () => {
        const amount = $("#expensAmountIn").val();
        const note = $("#expensNoteIn").val();
        const date = $("#expensDateIn").val();
        const branch = this.state.user.branch ? this.state.user.branch : $("#inBranchesx").val();
        if(!amount || parseFloat(amount) <= 0) {
            window.toastr["error"]("Invalid Amount");
            return;
        }
        if(!note || note.length < 5) {
            window.toastr["error"]("Invalid Note");
            return;
        }
        if(!date) {
            window.toastr["error"]("Invalid Date");
            return;
        }
        axios.post("/admin_add_expens_item", {amount:amount, note:note, branch:branch, date:date}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addExpensItem").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    setupBranches = () => {
        axios.post("/get_branches").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({branchesList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                // data.push(<option value="">{"<Make as Main Category>"}</option>);
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({branchesList:data});
            }
        })
    }

    deleteExpense = () => {
        const amount = this.state.adminToDel;
        const id = this.state.adminIdToDel;
        axios.post("/delete_expense_item", {id:id, amount:amount}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#deleteBranchModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    updateExpens = () => {
        const id = this.state.adminIdToDel;
        const amount = $("#expensAmountIn").val();
        const note = $("#expensNoteIn").val();
        const branch = this.state.user.branch ? this.state.user.branch : $("#inBranchesx").val();
        const date = $("#expensDateIn").val();
        if(!amount || parseFloat(amount) <= 0) {
            window.toastr["error"]("Invalid Amount");
            return;
        }
        if(!note || note.length < 5) {
            window.toastr["error"]("Invalid Note");
            return;
        }
        if(!date) {
            window.toastr["error"]("Invalid Date");
            return;
        }
        axios.post("/update_expens_item", {id:id, amount:amount, note:note, branch:branch, date:date}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addExpensItem").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    render() {
        return(
            <>
            
            <div class="modal fade" id="deleteBranchModal" role="dialog" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            Deleting Expense Item
                        </div>
                        <div class="modal-body">
                            Are you sure you want to remove Expense Item ({this.state.adminIdToDel}) with Amount ({this.state.adminToDel}) from Branch ({this.state.itemBranchToDel}) ?
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.deleteExpense();
                            }}>Yes</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                window.$("#deleteBranchModal").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="viewItemImgModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Item ID:</th>
                                        <td id="setItemId0"></td>
                                    </tr>
                                    <tr>
                                        <th>Note:</th>
                                        <td id="setItemNote0"></td>
                                    </tr>
                                    <tr>
                                        <th>Amount:</th>
                                        <td id="setItemAmount0"></td>
                                    </tr>
                                    <tr>
                                        <th>Date:</th>
                                        <td id="setItemDate0"></td>
                                    </tr>
                                    <tr>
                                        <th>Branch:</th>
                                        <td id="setItemBranch0"></td>
                                    </tr>
                                    <tr>
                                        <th>Photo:</th>
                                        <td><img id="setItemPhoto0" src="" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addExpensItem" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Amount:</th>
                                        <td><input class="myInputStyle" type="number" id="expensAmountIn"/></td>
                                    </tr>
                                    <tr>
                                        <th>Note:</th>
                                        <td><input class="myInputStyle" id="expensNoteIn"/></td>
                                    </tr>
                                    <tr>
                                        <th>Date:</th>
                                        <td><input class="myInputStyle" id="expensDateIn"/></td>
                                    </tr>
                                    {!this.state.user.branch && <tr>
                                        <th>Branch:</th>
                                        <td>
                                            <select id="inBranchesx" className="myInputStyle">
                                                {this.state.branchesList}
                                            </select>
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addAdminBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.addExpens();
                            }}>Add</button>

                            <button class='myBtnStyle' id='editAdminBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.updateExpens();
                            }}>Update</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="FusePageCarded-header container">
                <div style={{flexDirection: "column"}} className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
                    <span className="MuiTypography-root MuiTypography-body1 flex text-24 md:text-32 font-extrabold tracking-tight muiltr-1niqtu4-MuiTypography-root" delay={300} style={{transform: 'none'}}>Expenses Page</span>
                </div>
            </div>

            <div style={{padding: "0px 10px 0px 10px"}}>

                {this.state.addAdminsBtn}
                
                <br/>
                
                <table className="table-modal" style={{width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                    <tbody>
                        <tr>
                            <th>Date:</th>
                            <td>
                                <select id="selectedDate" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    <option value="">All</option>
                                    <option value={this.getMonthDate(0)}>{this.getMonthDate(0)}</option>
                                    <option value={this.getMonthDate(-1)}>{this.getMonthDate(-1)}</option>
                                    <option value={this.getMonthDate(-2)}>{this.getMonthDate(-2)}</option>
                                    <option value={this.getMonthDate(-3)}>{this.getMonthDate(-3)}</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>Refresh</button>
                <br/>
                <br/>
                <table class="table table-report dt-responsive -mt-2" id="table1" style={{width: "100%"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>User</th>
                            <th>Amount</th>
                            <th>Note</th>
                            <th>Date</th>
                            <th>Branch</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }
}

export default main;
