import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";

class login extends Component {
    componentWillMount() {
      axios.post("/is_authed").then((ok)=>{
        if(ok.data) {
          this.props.history.push("/panel");
        }
      })
    }

    doLogin = () => {
      const username = $("#inUsername").val();
      const pwd = $("#inPassword").val();

      if(!username) {
        window.toastr["error"]("Invalid Username");
        return;
      }
      if(!pwd) {
        window.toastr["error"]("Invalid Password");
        return;
      }

      window.toastr["info"]("Logging...");
      axios.post("/req_login", {username:username, pwd:pwd}).then((ok)=>{
        window.toastr[ok.data.status](ok.data.msg);
        if(ok.data.status === "success") {
          this.props.history.push(ok.data.target);
        }
      })

    }

    render() {
        return(
            <div id="fuse-layout" className="w-full flex muiltr-b10jnn">
        <div className="flex flex-auto min-w-0">
          <main id="fuse-main" className="flex flex-col flex-auto min-h-full min-w-0 relative z-10">
            <div className="flex flex-col flex-auto min-h-0 relative z-10">
              <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0" style={{placeContent: "center", backgroundColor: "white"}}>
                <div className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1 muiltr-piv2u8-MuiPaper-root" style={{placeContent: "center", borderRightWidth: "0px"}}>
                  <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
                    <p className="MuiTypography-root MuiTypography-body1 mt-32 text-4xl font-extrabold tracking-tight leading-tight muiltr-1niqtu4-MuiTypography-root">Sign in</p>
                    <form name="loginForm" noValidate className="flex flex-col justify-center w-full mt-32" onSubmit={(e) =>{e.preventDefault() } }>
                      <div className="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root mb-24 muiltr-wb57ya-MuiFormControl-root-MuiTextField-root">
                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorSecondary MuiFormLabel-filled Mui-required MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined muiltr-1cg7qce-MuiFormLabel-root-MuiInputLabel-root" data-shrink="true" htmlFor=":r39:" id=":r39:-label">Email<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk muiltr-125iqkp-MuiFormLabel-asterisk"> *</span></label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorSecondary MuiInputBase-fullWidth MuiInputBase-formControl muiltr-zsctt5-MuiInputBase-root-MuiOutlinedInput-root">
                          <input aria-invalid="false" id="inUsername" name="email" required type="email" className="MuiInputBase-input MuiOutlinedInput-input muiltr-1soyadh-MuiInputBase-input-MuiOutlinedInput-input" style={{width: "91%"}} />
                          <fieldset aria-hidden="true" className="MuiOutlinedInput-notchedOutline muiltr-1d3z3hw-MuiOutlinedInput-notchedOutline">
                            <legend className="muiltr-14lo706"><span>Email *</span></legend>
                          </fieldset>
                        </div>
                      </div>
                      <div className="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root mb-24 muiltr-wb57ya-MuiFormControl-root-MuiTextField-root">
                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorSecondary MuiFormLabel-filled Mui-required MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined muiltr-1cg7qce-MuiFormLabel-root-MuiInputLabel-root" data-shrink="true" htmlFor=":r3a:" id=":r3a:-label">Password<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk muiltr-125iqkp-MuiFormLabel-asterisk"> *</span></label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorSecondary MuiInputBase-fullWidth MuiInputBase-formControl muiltr-zsctt5-MuiInputBase-root-MuiOutlinedInput-root">
                          <input aria-invalid="false" id="inPassword" name="password" required type="password" className="MuiInputBase-input MuiOutlinedInput-input muiltr-1soyadh-MuiInputBase-input-MuiOutlinedInput-input" style={{width: "91%"}} />
                          <fieldset aria-hidden="true" className="MuiOutlinedInput-notchedOutline muiltr-1d3z3hw-MuiOutlinedInput-notchedOutline">
                            <legend className="muiltr-14lo706"><span>Password *</span></legend>
                          </fieldset>
                        </div>
                      </div>
                      <button className="myBtnStyle" style={{width: "100%"}} tabIndex={0} aria-label="Sign in" onClick={() => {
                        this.doLogin();
                      } }>Sign in</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
        )
    }
}

export default login;
