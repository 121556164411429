import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import {Buffer} from "buffer";
import QRCode from "react-qr-code";

class login extends Component {
  state = {
    invoiceId: "",
    invoiceDate: "",
    customer: "",
    user: [],
    itemsData: []
  }
    // componentWillMount() {
    //   axios.post("/is_authed").then((ok)=>{
    //     if(!ok.data) {
    //       this.props.history.push("/login");
    //     }
    //     if(!ok.data.access.includes("|72|")) {
    //         window.open("/panel", "_self");
    //         // this.props.history.push("/panel");
    //         return;
    //     }
    //     this.setState({user:ok.data});
    //   })
    // }
    getMyDate = (date) => {
      return new Date(date).toLocaleString("en-GB", {timeZone: this.state.user.tz, hour12: "true"}).toLocaleUpperCase();
    }
    setStateSynchronous = (stateUpdate) => {
      return new Promise(resolve => {
          this.setState(stateUpdate, () => resolve());
      });
    }
    componentDidMount() {
        $("head").append('<link rel="stylesheet" title="myPrintA4Style" href="/assets/style/print_a4.css" />');
        $("#root").removeAttr("class");
        $("#root").removeAttr("id");
        const base64InvoiceId = window.location.href.split("/")[window.location.href.split("/").length - 1];
        const invoiceId = Buffer.from(base64InvoiceId, "base64").toString("utf8");
        this.setState({invoiceId:invoiceId});
        const url = window.location.href;
        axios.post("/get_service_receipt_data", {id:invoiceId}).then(async(ok)=>{
            if(ok.data.status === "error") {
                window.toastr[ok.data.status](ok.data.msg);
                return;
            }
            const invData = ok.data.invData;
            const itemsData = [];
            this.setState({invoiceDate:this.getMyDate(invData[0].add_date), customer:invData[0].customer, employee:invData[0].user});
            
            $("#setDeviceName").html(invData[0].device);
            $("#setDeviceSn").html(invData[0].sn);
            $("#setDeviceStatus").html(invData[0].status);
            if(invData[0].status === "Delivered") {
                $("#setDeviceStatus").html(invData[0].status+" ("+invData[0].result+")");
            }
            $("#setDeviceIssue").html(invData[0].device_issue);
            $("#setDeviceDesc").html(invData[0].device_status_desc);
            $("#setCustomerName").html(invData[0].client);
            $("#setCustomerPhone").html(invData[0].phone);
            $("#setStaff").html(invData[0].received_by);
            $("#setAcces").html(invData[0].acces);
            $("#setWarranty").html(invData[0].warranty);
            $("#setPrice").html(invData[0].price);
            $("#setNote").html(invData[0].note);
            

            await this.setStateSynchronous({itemsData:itemsData, totalAmount:invData[0].base_price, discount:invData[0].discount_percent, finalAmount:invData[0].final_price,
                                            invoiceType:invData[0].sell_type, invoiceStatus:invData[0].status, branch:invData[0].branch, phones:ok.data.phones, address:ok.data.address, mof:ok.data.mof, qrData:url});
            window.print();
        })
    }

    render() {
        return(
            <>
              <div class="book">
                  <div class="page">
                    <label style={{fontSize: "40px", justifyContent: "center", display: "grid"}}>EasyTech Service</label>
                    <label style={{fontSize: "15px", justifyContent: "center", display: "grid"}}>Branch ({this.state.branch}) / Receipt No. ({this.state.invoiceId})</label>
                    {/*<label style={{fontSize: "20px", justifyContent: "center", display: "grid"}}>Receipt Number ({this.state.invoiceId})</label>*/}
                    
                    <h1 style={{marginTop: "10px", borderTop: "1px solid", marginBottom: "10px"}}></h1>
                    
                    <div style={{justifyContent: "space-between", display: "flex"}}>
                        <label style={{fontSize: "15px"}}>Phone: <label style={{fontWeight: "100"}}>{this.state.phones}</label></label>
                        <label style={{fontSize: "15px"}}>Address: <label style={{fontWeight: "100"}}>{this.state.address}</label></label>
                    </div>
                    <div style={{justifyContent: "space-between", display: "flex"}}>
                        <label style={{fontSize: "15px"}}>VAT-Number: <label style={{fontWeight: "100"}}>{this.state.mof}</label></label>
                        <label style={{fontSize: "15px"}}>Receipt Date: <label style={{fontWeight: "100"}}>{this.state.invoiceDate}</label></label>
                    </div>

                    <h1 style={{borderTop: "1px solid", marginBottom: "10px"}}></h1>
                    
                    <table className="table-modal" style={{width: "100%", textAlignLast: "start", fontSize: "13px"}}>
                        <tbody>
                            <tr>
                                <th style={{width: "15%"}}>Device:</th>
                                <td id="setDeviceName"></td>
                            </tr>
                            <tr>
                                <th>SN / IMEI:</th>
                                <td id="setDeviceSn"></td>
                            </tr>
                            <tr>
                                <th>Service Status:</th>
                                <td id="setDeviceStatus"></td>
                            </tr>
                            <tr>
                                <th>Device Desc.:</th>
                                <td id="setDeviceDesc"></td>
                            </tr>
                            <tr>
                                <th>Device Issue:</th>
                                <td id="setDeviceIssue"></td>
                            </tr>
                            <tr>
                                <th>Customer:</th>
                                <td id="setCustomerName"></td>
                            </tr>
                            <tr>
                                <th>Phone:</th>
                                <td id="setCustomerPhone"></td>
                            </tr>
                            <tr>
                                <th>Staff:</th>
                                <td id="setStaff"></td>
                            </tr>
                            <tr>
                                <th>Accessories:</th>
                                <td id="setAcces"></td>
                            </tr>
                            <tr>
                                <th>Warranty:</th>
                                <td id="setWarranty"></td>
                            </tr>
                            <tr>
                                <th>Notes:</th>
                                <td id="setNote"></td>
                            </tr>
                            <tr>
                                <th>Price:</th>
                                <td id="setPrice"></td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <h1 style={{marginTop: "10px", borderTop: "1px solid", marginBottom: "10px"}}></h1>
                    
                    <label style={{fontWeight: "600", fontSize: "small", textDecoration:"underline"}}>Important Notes:</label>
                    <label style={{fontWeight: "500"}}>1. Easy Tech is not responsible for the loss of the device after 60 days have passed since the period of receipt of the device.</label>
                    <label style={{fontWeight: "500"}}>2. Easy Tech is not responsible for the loss of the SIM card or storage card inside the device. Please hand over the device to the employee without the SIM card or storage card.</label>
                    <label style={{fontWeight: "500"}}>3. Easy Tech is not responsible for the loss of any data from the device after receiving it. Therefore, please notify the employee if there is important data on the device and include that in the notes on this receipt. The data includes all personal information, including accounts, passwords, applications, and names.</label>
                    
                    <br/>
                    <br/>

                    <label style={{fontWeight: "600", fontSize: "small"}}>As a customer of Easy Tech, I pledge that the device mentioned above is a legitimate, legal, non-stolen and non-smuggled device, and I bear all responsibility if proven otherwise, and this receipt serves as a contract between me and Easy Tech.</label>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    {this.state.qrData && <QRCode size={256} style={{ height: "auto", maxWidth: "100%", width: "20%", justifySelf: "center", margin: "-50px 0px 0px 125px", display: "inline"}} value={this.state.qrData} viewBox={`0 0 256 256`} />}
                    <label style={{fontWeight:"800", float:"right", marginRight:"5%"}}>Customer Name & Signature</label>

                  </div>
              </div>
            </>
        )
    }
}

export default login;
