import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import {Buffer} from "buffer";
import QRCode from "react-qr-code";

class login extends Component {
  state = {
    invoiceId: "",
    invoiceDate: "",
    customer: "",
    user: [],
    itemsData: []
  }
    // componentWillMount() {
    //   axios.post("/is_authed").then((ok)=>{
    //     if(!ok.data) {
    //       this.props.history.push("/login");
    //     }
    //     if(!ok.data.access.includes("|72|")) {
    //         window.open("/panel", "_self");
    //         // this.props.history.push("/panel");
    //         return;
    //     }
    //     this.setState({user:ok.data});
    //   })
    // }
    getMyDate = (date) => {
      return new Date(date).toLocaleString("en-GB", {timeZone: this.state.user.tz, hour12: "true"}).toLocaleUpperCase();
    }
    setStateSynchronous = (stateUpdate) => {
      return new Promise(resolve => {
          this.setState(stateUpdate, () => resolve());
      });
    }
    componentDidMount() {
        $("head").append('<link rel="stylesheet" title="myPrintA4Style" href="/assets/style/print_a4.css" />');
        $("#root").removeAttr("class");
        $("#root").removeAttr("id");
        const base64InvoiceId = window.location.href.split("/")[window.location.href.split("/").length - 1];
        const invoiceId = Buffer.from(base64InvoiceId, "base64").toString("utf8");
        this.setState({invoiceId:invoiceId});
        const url = window.location.href;
        axios.post("/get_service_receipt_data", {id:invoiceId}).then(async(ok)=>{
            if(ok.data.status === "error") {
                window.toastr[ok.data.status](ok.data.msg);
                return;
            }
            const invData = ok.data.invData;
            const itemsData = [];
            this.setState({invoiceDate:this.getMyDate(invData[0].add_date), customer:invData[0].customer, employee:invData[0].user});
            
            $("#setDeviceName").html(invData[0].device);
            $("#setDeviceSn").html(invData[0].sn);
            if(invData[0].status === "Pending") {
                $("#setDeviceStatus").html("قيد الانتظار");
            }
            if(invData[0].status === "Fixing") {
                $("#setDeviceStatus").html("قيد الإصلاح");
            }
            if(invData[0].status === "Fixed") {
                $("#setDeviceStatus").html("تم الإصلاح");
            }
            if(invData[0].status === "Undamaged") {
                $("#setDeviceStatus").html("الجهاز سليم ولايحتاج صيانة");
            }
            if(invData[0].status === "Failed") {
                $("#setDeviceStatus").html("لا يمكن الصيانة");
            }
            if(invData[0].status === "Rejected") {
                $("#setDeviceStatus").html("تم رفض الصيانة من قبل العميل");
            }
            if(invData[0].status === "Delivered") {
                var result = "";
                if(invData[0].result === "Fixed") {
                    result = "تم الإصلاح";
                }
                if(invData[0].result === "Failed") {
                    result = "لايمكن الإصلاح";
                }
                if(invData[0].result === "Undamaged") {
                    result = "الجهاز سليم ولايحتاج صيانة";
                }
                if(invData[0].result === "Rejected") {
                    result = "تم رفض الصيانة من قبل العميل";
                }
                $("#setDeviceStatus").html("تم التسليم إلى العميل ("+result+")");
            }
            if(invData[0].status === "Delivering") {
                var result = "";
                if(invData[0].result === "Fixed") {
                    result = "تم الإصلاح";
                }
                if(invData[0].result === "Failed") {
                    result = "لايمكن الإصلاح";
                }
                if(invData[0].result === "Undamaged") {
                    result = "الجهاز سليم ولايحتاج صيانة";
                }
                if(invData[0].result === "Rejected") {
                    result = "تم رفض الصيانة من قبل العميل";
                }
                $("#setDeviceStatus").html("في انتظار العميل للتسليم ("+result+")");
            }
            if(invData[0].status === "Sending") {
                var result = "";
                if(invData[0].result === "Fixed") {
                    result = "تم الإصلاح";
                }
                if(invData[0].result === "Failed") {
                    result = "لايمكن الإصلاح";
                }
                if(invData[0].result === "Undamaged") {
                    result = "الجهاز سليم ولايحتاج صيانة";
                }
                if(invData[0].result === "Rejected") {
                    result = "تم رفض الصيانة من قبل العميل";
                }
                $("#setDeviceStatus").html("الجهاز غادر مركز الصيانة ("+result+")");
            }

            $("#setDeviceIssue").html(invData[0].device_issue);
            $("#setDeviceDesc").html(invData[0].device_status_desc);
            $("#setCustomerName").html(invData[0].client);
            $("#setCustomerPhone").html(invData[0].phone);
            $("#setStaff").html(invData[0].received_by);
            $("#setAcces").html(invData[0].acces);
            $("#setWarranty").html(invData[0].warranty);
            $("#setPrice").html(invData[0].price);
            $("#setNote").html(invData[0].note);
            
            
            await this.setStateSynchronous({itemsData:itemsData, totalAmount:invData[0].base_price, discount:invData[0].discount_percent, finalAmount:invData[0].final_price,
                                            invoiceType:invData[0].sell_type, invoiceStatus:invData[0].status, branch:invData[0].branch, phones:ok.data.phones, address:ok.data.address, mof:ok.data.mof, qrData:url});
            window.print();
        })
    }

    render() {
        return(
            <>
              <div class="book">
                  <div class="page" style={{direction:"rtl"}}>
                    <label style={{fontSize: "40px", justifyContent: "center", display: "grid"}}>EasyTech Service</label>
                    <label style={{fontSize: "15px", justifyContent: "center", display: "grid"}}>Branch ({this.state.branch}) / Receipt No. ({this.state.invoiceId})</label>
                    {/*<label style={{fontSize: "20px", justifyContent: "center", display: "grid"}}>Receipt Number ({this.state.invoiceId})</label>*/}
                    
                    <h1 style={{marginTop: "10px", borderTop: "1px solid", marginBottom: "10px"}}></h1>
                    
                    <div style={{justifyContent: "space-between", display: "flex", direction:"ltr"}}>
                        <label style={{fontSize: "15px"}}>Phone: <label style={{fontWeight: "100"}}>{this.state.phones}</label></label>
                        <label style={{fontSize: "15px"}}>Address: <label style={{fontWeight: "100"}}>{this.state.address}</label></label>
                    </div>
                    <div style={{justifyContent: "space-between", display: "flex", direction:"ltr"}}>
                        <label style={{fontSize: "15px"}}>VAT-Number: <label style={{fontWeight: "100"}}>{this.state.mof}</label></label>
                        <label style={{fontSize: "15px"}}>Receipt Date: <label style={{fontWeight: "100"}}>{this.state.invoiceDate}</label></label>
                    </div>

                    <h1 style={{borderTop: "1px solid", marginBottom: "10px"}}></h1>
                    
                    <table className="table-modal" style={{width: "100%", textAlignLast: "start", fontSize: "15px"}}>
                        <tbody>
                            <tr>
                                <th style={{width: "15%"}}>الجهاز:</th>
                                <td id="setDeviceName"></td>
                            </tr>
                            <tr>
                                <th>السيريال \ الأيمي:</th>
                                <td id="setDeviceSn"></td>
                            </tr>
                            <tr>
                                <th>حالة الصيانة:</th>
                                <td id="setDeviceStatus"></td>
                            </tr>
                            <tr>
                                <th>وصف الجهاز:</th>
                                <td id="setDeviceDesc"></td>
                            </tr>
                            <tr>
                                <th>مشكلة الجهاز:</th>
                                <td id="setDeviceIssue"></td>
                            </tr>
                            <tr>
                                <th>الزبون:</th>
                                <td id="setCustomerName"></td>
                            </tr>
                            <tr>
                                <th>رقم التلفون:</th>
                                <td id="setCustomerPhone"></td>
                            </tr>
                            <tr>
                                <th>الموظف:</th>
                                <td id="setStaff"></td>
                            </tr>
                            <tr>
                                <th>الملحقات:</th>
                                <td id="setAcces"></td>
                            </tr>
                            <tr>
                                <th>الضمان:</th>
                                <td id="setWarranty"></td>
                            </tr>
                            <tr>
                                <th>ملاحظات:</th>
                                <td id="setNote"></td>
                            </tr>
                            <tr>
                                <th>السعر:</th>
                                <td id="setPrice"></td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <h1 style={{marginTop: "10px", borderTop: "1px solid", marginBottom: "10px"}}></h1>
                    
                    <label style={{fontWeight: "600", fontSize: "large", textDecoration:"underline"}}>ملاحظات هامة:</label>
                    <br/>
                    <label style={{fontWeight: "500", fontSize: "large"}}>1. شركة إيزي تك غير مسؤولة عن فقدان الجهاز بعد مرور 60 يومًا على فترة استلام الجهاز.</label>
                    <label style={{fontWeight: "500", fontSize: "large"}}>2. شركة ايزي تك ليست مسؤولة عن فقدان بطاقة SIM أو بطاقة التخزين داخل الجهاز. يرجى تسليم الجهاز للموظف بدون بطاقة SIM أو بطاقة التخزين.</label>
                    <label style={{fontWeight: "500", fontSize: "large"}}>3. إيزي تك غير مسؤولة عن فقدان أي بيانات من الجهاز بعد استلامها. لذا يرجى إبلاغ الموظف في حالة وجود بيانات مهمة على الجهاز وإدراج ذلك في الملاحظات الموجودة على هذا الإيصال. وتشمل البيانات جميع المعلومات الشخصية، بما في ذلك الحسابات وكلمات المرور والتطبيقات والأسماء.</label>
                    
                    <br/>
                    <br/>

                    <label style={{fontWeight: "600", fontSize: "large"}}>كعميل لشركة إيزي تك، أتعهد بأن الجهاز المذكور أعلاه جهاز شرعي وقانوني وغير مسروق وغير مهرب، وأتحمل كامل المسؤولية إذا ثبت خلاف ذلك، وهذا الإيصال بمثابة عقد بيني وبين إيزي تك .</label>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    {this.state.qrData && <QRCode size={256} style={{ height: "auto", maxWidth: "100%", width: "20%", justifySelf: "center", margin: "-50px 100px 0px 0px", display: "inline"}} value={this.state.qrData} viewBox={`0 0 256 256`} />}
                    <label style={{fontWeight:"800", float:"left", marginLeft:"70px", fontSize: "large"}}>اسم الزبون والتوقيع</label>

                  </div>
              </div>
            </>
        )
    }
}

export default login;
