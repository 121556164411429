import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";

class main extends Component {

    state = {
        user: [],
        entries: [],
        perms: [],
        adminToDel: "",
        adminIdToDel: ""
    }

    async setupSuppliersHistory() {
        function getMyDate(date) {
            return new Date(date).toLocaleString("en-GB", {timeZone: "Africa/Cairo", hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        const self = this;
        window.$('#table2').DataTable({
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                const targetId = $("#boxForSupplierId").val();
                // console.log(targetId);
                await axios.post('/cashbox_history_table', {itemId:targetId, offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const user = resData[vCount].user;
                    const cashBoxId = resData[vCount].cashbox_id;
                    const operation = resData[vCount].operation;
                    const usd_amount = parseFloat(resData[vCount].usd_amount).toLocaleString("en", {useGrouping:true});
                    const usd_amount_after = parseFloat(resData[vCount].usd_amount_after).toLocaleString("en", {useGrouping:true});
                    const lbp_amount = parseFloat(resData[vCount].lbp_amount).toLocaleString("en", {useGrouping:true});
                    const lbp_amount_after = parseFloat(resData[vCount].lbp_amount_after).toLocaleString("en", {useGrouping:true});
                    const date = getMyDate(resData[vCount].date);
                    const note = resData[vCount].note;

                    out.push([id, user, operation, usd_amount, usd_amount_after, lbp_amount, lbp_amount_after, date, note]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
    }

    setupDatatable() {
        function getMyDate(date) {
            if(!date) {
                return "";
            }
            return new Date(date).toLocaleString("en-GB", {timeZone: "Africa/Cairo", hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        const self = this;
        window.$('#table1').DataTable({
            'columnDefs': [{
            'targets': [1],
            'render': function(data, type, full, meta) {
                        if(type === 'display'){ data = strtrunc(data, 10); } 
                            return data; 
                      }
            }],
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                await axios.post('/cashbox_table', {offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const user = resData[vCount].user;
                    const usdAmount = parseFloat(resData[vCount].usd_amount).toLocaleString('en', {useGrouping:true});
                    const lbpAmount = parseFloat(resData[vCount].lbp_amount).toLocaleString('en', {useGrouping:true});
                    const openDate = getMyDate(resData[vCount].open_date);
                    const closeDate = getMyDate(resData[vCount].close_date);
                    const status = resData[vCount].status;
                    const inUsdAmount = parseFloat(resData[vCount].in_usd_amount).toLocaleString('en', {useGrouping:true});
                    const inLbpAmount = parseFloat(resData[vCount].in_lbp_amount).toLocaleString('en', {useGrouping:true});
                    const note = resData[vCount].note;
                    const branch = resData[vCount].branch;
                    // const balance = resData[vCount].balance;

                    var buttons = "<div class='dropdown'>" +
                                        "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                        "<div class='dropdown-content'>"
                    if(self.state.user.access.includes("|31|")) {
                        buttons += "<a class='myAStyle' id='view_supplier_logs' itemId='"+id+"' itemName='"+user+"'>History</a>";
                    }
                    if(self.state.user.access.includes("|37|") && status === "opened") {
                        buttons += "<a class='myAStyle' id='transfer_amount' itemId='"+id+"' user='"+user+"' usdAmount='"+usdAmount+"' lbpAmount='"+lbpAmount+"'>Transfer Amounts</a>";
                    }
                    if(self.state.user.access.includes("|38|") && status === "opened") {
                        buttons += "<a class='myAStyle' id='in_amount' itemId='"+id+"' user='"+user+"' usdAmount='"+usdAmount+"' lbpAmount='"+lbpAmount+"'>IN Amount</a>";
                    }
                    if(self.state.user.access.includes("|38|") && status === "opened") {
                        buttons += "<a class='myAStyle' id='out_amount' itemId='"+id+"' user='"+user+"' usdAmount='"+usdAmount+"' lbpAmount='"+lbpAmount+"'>OUT Amount</a>";
                    }
                    if(self.state.user.access.includes("|57|") && status === "opened") {
                        buttons += "<a class='myAStyle' id='send_to_wallet' itemId='"+id+"' user='"+user+"' usdAmount='"+usdAmount+"' lbpAmount='"+lbpAmount+"'>Send Amount to Wallet</a>";
                    }
                    if(self.state.user.access.includes("|58|") && status === "opened") {
                        buttons += "<a class='myAStyle' id='get_from_wallet' itemId='"+id+"' user='"+user+"' usdAmount='"+usdAmount+"' lbpAmount='"+lbpAmount+"'>Get Amount from Wallet</a>";
                    }

                    buttons += "</div></div>";
                    out.push([id, user, usdAmount, lbpAmount, openDate, closeDate, status, inUsdAmount, inLbpAmount, note, branch, buttons]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
        $(document).off("click", "#get_from_wallet");
        $(document).on("click", "#get_from_wallet", function() {
            self.setupWallets();
            const itemId = $(this).attr("itemId");
            const user = $(this).attr("user");
            const usdAmount = $(this).attr("usdAmount");
            const lbpAmount = $(this).attr("lbpAmount");
            $("#cashboxSessionId5").html(itemId);
            $("#cashboxTransferUsdAmount5").html(usdAmount);
            $("#cashboxTransferLbpAmount5").html(lbpAmount);
            $("#cashboxUser5").html(user);
            window.$("#getAmountFromWallet").modal("show");
        });
        $(document).off("click", "#send_to_wallet");
        $(document).on("click", "#send_to_wallet", function() {
            self.setupWallets();
            const itemId = $(this).attr("itemId");
            const user = $(this).attr("user");
            const usdAmount = $(this).attr("usdAmount");
            const lbpAmount = $(this).attr("lbpAmount");
            $("#cashboxSessionId4").html(itemId);
            $("#cashboxTransferUsdAmount4").html(usdAmount);
            $("#cashboxTransferLbpAmount4").html(lbpAmount);
            $("#cashboxUser4").html(user);
            window.$("#sendAmountToWallet").modal("show");
        });
        $(document).off("click", "#out_amount");
        $(document).on("click", "#out_amount", async function() {
            self.processReason2();
            await self.setupSuppliers();
            const itemId = $(this).attr("itemId");
            const user = $(this).attr("user");
            const usdAmount = $(this).attr("usdAmount");
            const lbpAmount = $(this).attr("lbpAmount");
            $("#cashboxSessionId3").html(itemId);
            $("#cashboxTransferUsdAmount3").html(usdAmount);
            $("#cashboxTransferLbpAmount3").html(lbpAmount);
            $("#cashboxUser3").html(user);
            // if(!$("#inCashboxReason").val()) {
            //     $("#clientStaffPart").hide();
            // } else {
            //     $("#clientStaffPart").show();
            // }
            self.setState({adminToDel:user, adminIdToDel:itemId});
            window.$("#outCashboxAmountModal").modal("show");
        });
        $(document).off("click", "#in_amount");
        $(document).on("click", "#in_amount", async function() {
            self.processReason();
            await self.setupSuppliers();
            const itemId = $(this).attr("itemId");
            const user = $(this).attr("user");
            const usdAmount = $(this).attr("usdAmount");
            const lbpAmount = $(this).attr("lbpAmount");
            $("#cashboxSessionId2").html(itemId);
            $("#cashboxTransferUsdAmount2").html(usdAmount);
            $("#cashboxTransferLbpAmount2").html(lbpAmount);
            $("#cashboxUser2").html(user);
            // if(!$("#inCashboxReason").val()) {
            //     $("#clientStaffPart").hide();
            // } else {
            //     $("#clientStaffPart").show();
            // }
            self.setState({adminToDel:user, adminIdToDel:itemId});
            window.$("#inCashboxAmountModal").modal("show");
        });
        $(document).off("click", "#transfer_amount");
        $(document).on("click", "#transfer_amount", async function() {
            await self.setupStaffs();
            const itemId = $(this).attr("itemId");
            const user = $(this).attr("user");
            const usdAmount = $(this).attr("usdAmount");
            const lbpAmount = $(this).attr("lbpAmount");
            $("#cashboxSessionId").html(itemId);
            $("#cashboxTransferUsdAmount").html(usdAmount);
            $("#cashboxTransferLbpAmount").html(lbpAmount);
            $("#cashboxUser").html(user);
            self.setState({adminToDel:user, adminIdToDel:itemId});
            window.$("#transferCashboxAmountModal").modal("show");
        });
        $(document).off("click", "#view_supplier_logs");
        $(document).on("click", "#view_supplier_logs", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            self.setState({adminToDel:itemName, adminIdToDel:itemId, itemIdLogsHistory:itemId});
            $("#boxForSupplierId").val(itemId);
            window.$("#suppliersLogsHistory").modal("show");
            self.setupSuppliersHistory();
        });
        $(document).off("click", "#delete_branch");
        $(document).on("click", "#delete_branch", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            window.$("#deleteBranchModal").modal("show");
        });

        $(document).off("click", "#edit_branch");
        $(document).on("click", "#edit_branch", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemPhone = $(this).attr("itemPhone");
            const itemCountry = $(this).attr("itemCountry");
            const itemBalance = $(this).attr("itemBalance");
            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            $("#nameInput").val(itemName);
            $("#phoneInput").val(itemPhone);
            $("#countryInput").val(itemCountry);
            $("#balanceInput").val(itemBalance);
            $("#addAdminBtn").hide();
            $("#editAdminBtn").show();
            window.$("#addBranchModal").modal("show");
        });
        $(document).off("click", "#update_supplier_balance");
        $(document).on("click", "#update_supplier_balance", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemBalance = $(this).attr("itemBalance");
            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            $("#setSupplierName").html(itemName);
            $("#setSupplierBalance").html(itemBalance);
            window.$("#updateBalanceModal").modal("show");
        });
    }
    componentDidMount() {
        axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            this.setState({user:ok.data});
            if(!ok.data.access.includes("|28|")) {
                this.props.history.push("/panel");
                return;
            }
            const nodeName = "#cashbox_btn";
            const interv3 = setInterval(function() {
                $(".MuiButtonBase-root").css({backgroundColor: ""});
                $(nodeName).css({backgroundColor: "cadetblue"});
                if($(nodeName).prop("style")) {
                    if($(nodeName).prop("style").backgroundColor === "cadetblue") {
                        clearInterval(interv3);
                    }
                }
            }, 1);
            this.setupDatatable();
            // this.setupStaffs();

            if(ok.data.access.includes("|23|")) {
                // this.setState({addAdminsBtn:[
                //     <>
                //         <button class='myBtnStyle' tabIndex={0} type='button' onClick={() => {
                //             $("#addAdminBtn").show();
                //             $("#editAdminBtn").hide();
                //             if(!$("#balanceInput").val()) {
                //                 $("#balanceInput").val(0);
                //             }
                //             window.$("#addBranchModal").modal("show");
                //         }}>+ Add</button>
                //     </>
                // ]})
            }
        })
    }

    addBranch = () => {
        const name = $("#nameInput").val();
        const phone = $("#phoneInput").val();
        const country = $("#countryInput").val();
        const balance = $("#balanceInput").val();
        if(!name) {
            window.toastr["error"]("Invalid Supplier Name");
            return;
        }
        axios.post("/add_supplier", {name:name, phone:phone, country:country, balance:balance}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addBranchModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    deleteBranch = () => {
        const name = this.state.adminToDel;
        const id = this.state.adminIdToDel;
        axios.post("/delete_supplier", {name:name, id:id}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#deleteBranchModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    editBranch = () => {
        const prevName = this.state.adminToDel;
        const id = this.state.adminIdToDel;
        const name = $("#nameInput").val();
        const phone = $("#phoneInput").val();
        const country = $("#countryInput").val();
        const balance = $("#balanceInput").val();
        if(!name) {
            window.toastr["error"]("Invalid Branch Name");
            return;
        }
        axios.post("/edit_supplier", {prevName:prevName, name:name, id:id, phone:phone, country:country, balance:balance}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addBranchModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    updateSupplierBalance = (type) => {
        const name = this.state.adminToDel;
        const id = this.state.adminIdToDel;
        const balance = $("#balanceToChangeIn").val();
        const note = $("#balanceChangeNote").val();
        if(!balance || parseFloat(balance) < 0) {
            window.toastr["error"]("Invalid Balance Value");
            return;
        }
        if(!note) {
            window.toastr["error"]("Invalid Note Value");
            return;
        }
        var api = "/add_supplier_balance";
        if(type === "-") {
            api = "/remove_supplier_balance";
        }
        axios.post(api, {name:name, id:id, balance:balance, note:note}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#updateBalanceModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    setupStaffs = async() => {
        await axios.post("/get_staff_branch").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({inUsersList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                this.setState({inUsersList:[]});
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({inUsersList:data});
            }
        })
    }

    setupSuppliers = async() => {
        await axios.post("/get_suppliers").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({inUsersList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                this.setState({inUsersList:[]});
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({inUsersList:data});
            }
        })
    }

    doTransfer = () => {
        const cashboxId = $("#cashboxSessionId").html();
        const cashboxUser = $("#cashboxUser").html();
        const cashboxUsdAmount = $("#cashboxTransferUsdAmount").html().replace(/,/g, "");
        const cashboxLbpAmount = $("#cashboxTransferLbpAmount").html().replace(/,/g, "");
        
        const targetUser = $("#inUsers").val();
        var usdCashboxTransfer = $("#usdCashboxTransfer").val().replace(/,/g, "");
        var lbpCashboxTransfer = $("#lbpCashboxTransfer").val().replace(/,/g, "");
        if(!usdCashboxTransfer) {
            usdCashboxTransfer = "0";
        }
        if(!lbpCashboxTransfer) {
            lbpCashboxTransfer = "0";
        }
        if(usdCashboxTransfer === "0" && lbpCashboxTransfer === "0") {
            window.toastr["error"]("Nothing to Transfer");
            return;
        }
        if(parseFloat(usdCashboxTransfer) < 0) {
            window.toastr["error"]("Invalid USD Value, Must be > 0");
            return;
        }
        if(parseFloat(lbpCashboxTransfer) < 0) {
            window.toastr["error"]("Invalid LBP Value, Must be > 0");
            return;
        }
        if(usdCashboxTransfer !== "0" && parseFloat(usdCashboxTransfer) > parseFloat(cashboxUsdAmount)) {
            window.toastr["error"]("Requested USD Amount is bigger than available amount, ["+usdCashboxTransfer+"] > ["+cashboxUsdAmount+"]");
            return;
        }
        if(lbpCashboxTransfer !== "0" && parseFloat(lbpCashboxTransfer) > parseFloat(cashboxLbpAmount)) {
            window.toastr["error"]("Requested LBP Amount is bigger than available amount, ["+lbpCashboxTransfer+"] > ["+cashboxLbpAmount+"]");
            return;
        }
        if(targetUser === cashboxUser) {
            window.toastr["error"]("Cannot Transfer to Yourself !");
            return;
        }
        axios.post("/transfer_cashbox_amount", {targetUser:targetUser, cashboxId:cashboxId, cashboxUser:cashboxUser, usdCashboxTransfer:usdCashboxTransfer, lbpCashboxTransfer:lbpCashboxTransfer}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$('#table1').DataTable().ajax.reload(null, false);
                window.$("#transferCashboxAmountModal").modal("hide");
                $("#cashBoxUsdVal").html(parseFloat(ok.data.newCashBoxUsd).toLocaleString("en", {useGrouping:true}));
                $("#cashBoxLbpVal").html(parseFloat(ok.data.newCashBoxLbp).toLocaleString("en", {useGrouping:true}));
            }
        });
    }

    processReason2 = () => {
        const reasonIdVal = $("#inCashboxReason2").val();
        $("#clientStaffPart2").hide();
        $("#makeNewClientBtnId").hide();
        $("#expensesPart").hide();
        if(reasonIdVal === "staff") {
            $("#clientStaffPart2").show();
            this.setupStaffs();
        } else if(reasonIdVal === "client") {
            $("#clientStaffPart2").show();
            $("#makeNewClientBtnId").show();
            this.setupSuppliers();
        } else if(reasonIdVal === "expenses") {
            $("#expensesPart").show();
        }
    }
    
    addClient = () => {
        const name = $("#nameInput").val();
        const phone = $("#phoneInput").val();
        const country = $("#countryInput").val();
        const balance = $("#balanceInput").val();
        if(!name) {
            window.toastr["error"]("Invalid Supplier Name");
            return;
        }
        axios.post("/add_supplier", {name:name, phone:phone, country:country, balance:balance}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                this.setupSuppliers();
                window.$("#addClientModal").modal("hide");
                // window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    processReason = () => {
        const reasonIdVal = $("#inCashboxReason").val();
        $("#clientStaffPart").hide();
        $("#makeNewClientBtnId").hide();
        if(reasonIdVal === "staff") {
            $("#clientStaffPart").show();
            this.setupStaffs();
        } else if(reasonIdVal === "client") {
            $("#clientStaffPart").show();
            $("#makeNewClientBtnId").show();
            this.setupSuppliers();
        }
    }

    doAddAmount = () => {
        const id = $("#cashboxSessionId2").html();
        const cashboxUser = $("#cashboxUser2").html();
        const inCashboxReason = $("#inCashboxReason").val();
        const curUsdCashbox = $("#cashboxTransferUsdAmount2").html().replace(/,/g, "");
        const curLbpCashbox = $("#cashboxTransferLbpAmount2").html().replace(/,/g, "");
        var clientStaffReceiveReason = $("#clientStaffReceiveReason").val();
        var usdAmount = $("#usdCashboxAddBox").val();
        var lbpAmount = $("#lbpCashboxAddBox").val().replace(/,/g, "");
        const note = $("#addCashboxAmountNote").val();

        if(inCashboxReason !== "client") {
            clientStaffReceiveReason = "";
        }
        if(!usdAmount) {
            usdAmount = "0";
        }
        if(!lbpAmount) {
            lbpAmount = "0";
        }
        if(usdAmount === "0" && lbpAmount === "0") {
            window.toastr["error"]("Nothing to Transfer");
            return;
        }
        if(parseFloat(usdAmount) < 0) {
            window.toastr["error"]("Invalid USD Value, Must be > 0");
            return;
        }
        if(parseFloat(lbpAmount) < 0) {
            window.toastr["error"]("Invalid LBP Value, Must be > 0");
            return;
        }
        // if(usdAmount !== "0" && parseFloat(usdAmount) > parseFloat(curUsdCashbox)) {
        //     window.toastr["error"]("Requested USD Amount is bigger than available amount, ["+usdAmount+"] > ["+curUsdCashbox+"]");
        //     return;
        // }
        // if(lbpAmount !== "0" && parseFloat(lbpAmount) > parseFloat(curLbpCashbox)) {
        //     window.toastr["error"]("Requested LBP Amount is bigger than available amount, ["+lbpAmount+"] > ["+curLbpCashbox+"]");
        //     return;
        // }
        if(!note || note.length < 5) {
            window.toastr["error"]("Invalid Note");
            return;
        }

        axios.post("/in_cashbox_amount", {note:note, usdAmount:usdAmount, lbpAmount:lbpAmount, id:id, cashboxUser:cashboxUser, inCashboxReason:inCashboxReason, clientStaffReceiveReason:clientStaffReceiveReason}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$('#table1').DataTable().ajax.reload(null, false);
                window.$("#inCashboxAmountModal").modal("hide");
                $("#cashBoxUsdVal").html(parseFloat(ok.data.newCashBoxUsd).toLocaleString("en", {useGrouping:true}));
                $("#cashBoxLbpVal").html(parseFloat(ok.data.newCashBoxLbp).toLocaleString("en", {useGrouping:true}));
            }
        });
    }

    doRemoveAmount = async() => {
        const id = $("#cashboxSessionId3").html();
        const cashboxUser = $("#cashboxUser3").html();
        const inCashboxReason = $("#inCashboxReason2").val();
        const curUsdCashbox = $("#cashboxTransferUsdAmount3").html().replace(/,/g, "");
        const curLbpCashbox = $("#cashboxTransferLbpAmount3").html().replace(/,/g, "");
        var clientStaffReceiveReason = $("#clientStaffReceiveReason2").val();
        var usdAmount = $("#usdCashboxOutBox").val();
        var lbpAmount = $("#lbpCashboxOutBox").val().replace(/,/g, "");
        const note = $("#outCashboxAmountNote").val();
        var file = document.querySelector('#invoiceFileId').files[0];
        var base64File = "";

        if(inCashboxReason !== "client") {
            clientStaffReceiveReason = "";
        }
        if(inCashboxReason === "expenses") {
            if(!file) {
                window.toastr["error"]("Please Upload Invoice Photo");
                return;
            }
            base64File = await this.fileToBase64(file);
        }
        if(!usdAmount) {
            usdAmount = "0";
        }
        if(!lbpAmount) {
            lbpAmount = "0";
        }
        if(usdAmount === "0" && lbpAmount === "0") {
            window.toastr["error"]("Nothing to Transfer");
            return;
        }
        if(parseFloat(usdAmount) < 0) {
            window.toastr["error"]("Invalid USD Value, Must be > 0");
            return;
        }
        if(parseFloat(lbpAmount) < 0) {
            window.toastr["error"]("Invalid LBP Value, Must be > 0");
            return;
        }
        if(usdAmount !== "0" && parseFloat(usdAmount) > parseFloat(curUsdCashbox)) {
            window.toastr["error"]("Requested USD Amount is bigger than available amount, ["+usdAmount+"] > ["+curUsdCashbox+"]");
            return;
        }
        if(lbpAmount !== "0" && parseFloat(lbpAmount) > parseFloat(curLbpCashbox)) {
            window.toastr["error"]("Requested LBP Amount is bigger than available amount, ["+lbpAmount+"] > ["+curLbpCashbox+"]");
            return;
        }
        if(!note || note.length < 5) {
            window.toastr["error"]("Invalid Note");
            return;
        }
        
        if(file) {
            window.toastr["info"]("Loading data...");
        }

        axios.post("/out_cashbox_amount", {file:base64File, note:note, usdAmount:usdAmount, lbpAmount:lbpAmount, id:id, cashboxUser:cashboxUser, inCashboxReason:inCashboxReason, clientStaffReceiveReason:clientStaffReceiveReason}).then((ok)=>{
            if(file) {
                window.toastr.remove();
            }
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$('#table1').DataTable().ajax.reload(null, false);
                window.$("#outCashboxAmountModal").modal("hide");
                $("#cashBoxUsdVal").html(parseFloat(ok.data.newCashBoxUsd).toLocaleString("en", {useGrouping:true}));
                $("#cashBoxLbpVal").html(parseFloat(ok.data.newCashBoxLbp).toLocaleString("en", {useGrouping:true}));
            }
        });
    }
    
    setupWallets = () => {
        axios.post("/get_branches").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({inWalletsList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                // data.push(<option value="">{"<Make as Main Category>"}</option>);
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({inWalletsList:data});
            }
        })
    }
    
    doSendAmount = () => {
        const cashboxId = $("#cashboxSessionId4").html();
        const cashboxUser = $("#cashboxUser4").html();
        const wallet = $("#walletSelectId").val();
        var usdAmount = $("#usdCashboxOutWalletBox").val().replace(/,/g, "");
        var lbpAmount = $("#lbpCashboxOutWalletBox").val().replace(/,/g, "");
        const note = $("#outCashboxAmountWalletNote").val();
        const curUsdCashbox = $("#cashboxTransferUsdAmount4").html().replace(/,/g, "");
        const curLbpCashbox = $("#cashboxTransferLbpAmount4").html().replace(/,/g, "");
        if(parseFloat(usdAmount) < 0) {
            window.toastr["error"]("USD Amount must be bigger than Zero");
            return;
        }
        if(parseFloat(lbpAmount) < 0) {
            window.toastr["error"]("LBP Amount must be bigger than Zero");
            return;
        }
        if(!note || note.length < 5) {
            window.toastr["error"]("Invalid Note");
            return;
        }
        if(!usdAmount) {
            usdAmount = "0";
        }
        if(!lbpAmount) {
            lbpAmount = "0";
        }
        if(usdAmount !== "0" && parseFloat(usdAmount) > parseFloat(curUsdCashbox)) {
            window.toastr["error"]("Requested USD Amount is bigger than available amount, ["+usdAmount+"] > ["+curUsdCashbox+"]");
            return;
        }
        if(lbpAmount !== "0" && parseFloat(lbpAmount) > parseFloat(curLbpCashbox)) {
            window.toastr["error"]("Requested LBP Amount is bigger than available amount, ["+lbpAmount+"] > ["+curLbpCashbox+"]");
            return;
        }
        axios.post("/send_cashbox_to_wallet", {note:note, usdAmount:usdAmount, lbpAmount:lbpAmount, id:cashboxId, cashboxUser:cashboxUser, wallet:wallet}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$('#table1').DataTable().ajax.reload(null, false);
                window.$("#sendAmountToWallet").modal("hide");
                $("#cashBoxUsdVal").html(parseFloat(ok.data.newCashBoxUsd).toLocaleString("en", {useGrouping:true}));
                $("#cashBoxLbpVal").html(parseFloat(ok.data.newCashBoxLbp).toLocaleString("en", {useGrouping:true}));
            }
        });
    }
    doGetAmount = () => {
        const cashboxId = $("#cashboxSessionId5").html();
        const cashboxUser = $("#cashboxUser5").html();
        const wallet = $("#walletSelectId2").val();
        var usdAmount = $("#usdCashboxOutWalletBox2").val().replace(/,/g, "");
        var lbpAmount = $("#lbpCashboxOutWalletBox2").val().replace(/,/g, "");
        const note = $("#outCashboxAmountWalletNote2").val();
        const curUsdCashbox = $("#cashboxTransferUsdAmount5").html().replace(/,/g, "");
        const curLbpCashbox = $("#cashboxTransferLbpAmount5").html().replace(/,/g, "");
        if(parseFloat(usdAmount) < 0) {
            window.toastr["error"]("USD Amount must be bigger than Zero");
            return;
        }
        if(parseFloat(lbpAmount) < 0) {
            window.toastr["error"]("LBP Amount must be bigger than Zero");
            return;
        }
        if(!note || note.length < 5) {
            window.toastr["error"]("Invalid Note");
            return;
        }
        if(!usdAmount) {
            usdAmount = "0";
        }
        if(!lbpAmount) {
            lbpAmount = "0";
        }
        axios.post("/get_cashbox_from_wallet", {note:note, usdAmount:usdAmount, lbpAmount:lbpAmount, id:cashboxId, cashboxUser:cashboxUser, wallet:wallet}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$('#table1').DataTable().ajax.reload(null, false);
                window.$("#getAmountFromWallet").modal("hide");
                $("#cashBoxUsdVal").html(parseFloat(ok.data.newCashBoxUsd).toLocaleString("en", {useGrouping:true}));
                $("#cashBoxLbpVal").html(parseFloat(ok.data.newCashBoxLbp).toLocaleString("en", {useGrouping:true}));
            }
        });
    }
    
    fileToBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    render() {
        return(
            <>
            
            <div class="modal fade" id="getAmountFromWallet" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Get Cash Amount From Branch Wallet
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Cashbox Session ID:</th>
                                        <td id="cashboxSessionId5"></td>
                                    </tr>
                                    <tr>
                                        <th>Cashbox User:</th>
                                        <td id="cashboxUser5"></td>
                                    </tr>
                                    <tr>
                                        <th>Cashbox USD Amount:</th>
                                        <td id="cashboxTransferUsdAmount5"></td>
                                    </tr>
                                    <tr>
                                        <th>Cashbox LBP Amount:</th>
                                        <td id="cashboxTransferLbpAmount5"></td>
                                    </tr>
                                    <tr>
                                        <th>Wallets:</th>
                                        <td>
                                            <select id="walletSelectId2" className="myInputStyle">
                                                {this.state.inWalletsList}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Out Amounts:</th>
                                        <td>
                                            <div>
                                                <input class="myInputStyle" style={{display: "inline", marginBottom: "5px"}} id="usdCashboxOutWalletBox2" placeholder="USD Amount" type="number" />
                                                <input class="myInputStyle" style={{display: "inline"}} id="lbpCashboxOutWalletBox2" placeholder="LBP Amount" onChange={() => {
                                                    const val = $("#lbpCashboxOutWalletBox2").val().replace(/,/g, "");
                                                    if(val && !isNaN(val)) {
                                                        $("#lbpCashboxOutWalletBox2").val(parseFloat(val).toLocaleString('en', {useGrouping:true}));
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Note:</th>
                                        <td><input class="myInputStyle" id="outCashboxAmountWalletNote2"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.doGetAmount();
                            }}>Get</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal fade" id="sendAmountToWallet" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Send Cash Amount to Branch Wallet
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Cashbox Session ID:</th>
                                        <td id="cashboxSessionId4"></td>
                                    </tr>
                                    <tr>
                                        <th>Cashbox User:</th>
                                        <td id="cashboxUser4"></td>
                                    </tr>
                                    <tr>
                                        <th>Cashbox USD Amount:</th>
                                        <td id="cashboxTransferUsdAmount4"></td>
                                    </tr>
                                    <tr>
                                        <th>Cashbox LBP Amount:</th>
                                        <td id="cashboxTransferLbpAmount4"></td>
                                    </tr>
                                    <tr>
                                        <th>Wallets:</th>
                                        <td>
                                            <select id="walletSelectId" className="myInputStyle">
                                                {this.state.inWalletsList}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Out Amounts:</th>
                                        <td>
                                            <div>
                                                <input class="myInputStyle" style={{display: "inline", marginBottom: "5px"}} id="usdCashboxOutWalletBox" placeholder="USD Amount" type="number" />
                                                <input class="myInputStyle" style={{display: "inline"}} id="lbpCashboxOutWalletBox" placeholder="LBP Amount" onChange={() => {
                                                    const val = $("#lbpCashboxOutWalletBox").val().replace(/,/g, "");
                                                    if(val && !isNaN(val)) {
                                                        $("#lbpCashboxOutWalletBox").val(parseFloat(val).toLocaleString('en', {useGrouping:true}));
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Note:</th>
                                        <td><input class="myInputStyle" id="outCashboxAmountWalletNote"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.doSendAmount();
                            }}>Send</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addClientModal" role="dialog" tabindex="-1" style={{zIndex: "1051"}}>
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Name:</th>
                                        <td><input class="myInputStyle" id="nameInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Phone:</th>
                                        <td><input class="myInputStyle" type="number" id="phoneInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Country:</th>
                                        <td><input class="myInputStyle" id="countryInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Balance:</th>
                                        <td><input class="myInputStyle" type="number" id="balanceInput"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addAdminBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.addClient();
                            }}>Add</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="outCashboxAmountModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Out Cash Amount from ({this.state.adminToDel}) Cashbox
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Cashbox Session ID:</th>
                                        <td id="cashboxSessionId3"></td>
                                    </tr>
                                    <tr>
                                        <th>Cashbox User:</th>
                                        <td id="cashboxUser3"></td>
                                    </tr>
                                    <tr>
                                        <th>Cashbox USD Amount:</th>
                                        <td id="cashboxTransferUsdAmount3"></td>
                                    </tr>
                                    <tr>
                                        <th>Cashbox LBP Amount:</th>
                                        <td id="cashboxTransferLbpAmount3"></td>
                                    </tr>
                                    <tr>
                                        <th>Reason:</th>
                                        <td>
                                            <select id="inCashboxReason2" className="myInputStyle" onChange={() => { this.processReason2(); }}>
                                                <option value="">No Reason</option>
                                                <option value="expenses">Expenses</option>
                                                {/*<option value="staff">Add to Staff</option>*/}
                                                <option value="client">Add to Supplier/Client</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr id="expensesPart">
                                        <th>Invoice Photo:</th>
                                        <td style={{textAlign: "center"}}>
                                            <input id="invoiceFileId" type="file" onChange={async(e) => {
                                                // console.log(await this.fileToBase64($("#invoiceFileId").val()));
                                                // console.log();
                                            }} />
                                        </td>
                                    </tr>
                                    <tr id="clientStaffPart2">
                                        <th>Client/Staff:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <select id="clientStaffReceiveReason2" className="myInputStyle">
                                                {this.state.inUsersList}
                                            </select>
                                            &nbsp;
                                            <button class='myBtnStyle' id="makeNewClientBtnId" tabIndex={0} type='button' style={{float: "right", minWidth: "fit-content"}} onClick={() => {
                                                window.$("#addClientModal").off("shown.bs.modal").off("hidden.bs.modal");
                                                window.$("#addClientModal").on("shown.bs.modal", function () {
                                                    $("#outCashboxAmountModal").css({zIndex: "1000"});
                                                }).on("hidden.bs.modal", function () {
                                                    $("#outCashboxAmountModal").css({zIndex: "1050"});
                                                });
                                                window.$("#addClientModal").modal("show");
                                            }}>Add Client</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Out Amounts:</th>
                                        <td>
                                            <div>
                                                <input class="myInputStyle" style={{display: "inline", marginBottom: "5px"}} id="usdCashboxOutBox" placeholder="USD Amount" type="number" />
                                                <input class="myInputStyle" style={{display: "inline"}} id="lbpCashboxOutBox" placeholder="LBP Amount" onChange={() => {
                                                    const val = $("#lbpCashboxOutBox").val().replace(/,/g, "");
                                                    if(val && !isNaN(val)) {
                                                        $("#lbpCashboxOutBox").val(parseFloat(val).toLocaleString('en', {useGrouping:true}));
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Note:</th>
                                        <td><input class="myInputStyle" id="outCashboxAmountNote"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.doRemoveAmount();
                            }}>Remove</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="inCashboxAmountModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Add Cash Amount to ({this.state.adminToDel}) Cashbox
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Cashbox Session ID:</th>
                                        <td id="cashboxSessionId2"></td>
                                    </tr>
                                    <tr>
                                        <th>Cashbox User:</th>
                                        <td id="cashboxUser2"></td>
                                    </tr>
                                    <tr>
                                        <th>Cashbox USD Amount:</th>
                                        <td id="cashboxTransferUsdAmount2"></td>
                                    </tr>
                                    <tr>
                                        <th>Cashbox LBP Amount:</th>
                                        <td id="cashboxTransferLbpAmount2"></td>
                                    </tr>
                                    <tr>
                                        <th>Reason:</th>
                                        <td>
                                            <select id="inCashboxReason" className="myInputStyle" onChange={() => { this.processReason(); }}>
                                                <option value="">No Reason</option>
                                                {/*<option value="staff">Receive From Staff</option>*/}
                                                <option value="client">Receive From Supplier/Client</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr id="clientStaffPart">
                                        <th>Client/Staff:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <select id="clientStaffReceiveReason" className="myInputStyle">
                                                {this.state.inUsersList}
                                            </select>
                                            &nbsp;
                                            <button class='myBtnStyle' id="makeNewClientBtnId2" tabIndex={0} type='button' style={{float: "right", minWidth: "fit-content"}} onClick={() => {
                                                window.$("#addClientModal").off("shown.bs.modal").off("hidden.bs.modal");
                                                window.$("#addClientModal").on("shown.bs.modal", function () {
                                                    $("#inCashboxAmountModal").css({zIndex: "1000"});
                                                }).on("hidden.bs.modal", function () {
                                                    $("#inCashboxAmountModal").css({zIndex: "1050"});
                                                });
                                                window.$("#addClientModal").modal("show");
                                            }}>Add Client</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>In Amounts:</th>
                                        <td>
                                            <div>
                                                <input class="myInputStyle" style={{display: "inline", marginBottom: "5px"}} id="usdCashboxAddBox" placeholder="USD Amount" type="number" />
                                                <input class="myInputStyle" style={{display: "inline"}} id="lbpCashboxAddBox" placeholder="LBP Amount" onChange={() => {
                                                    const val = $("#lbpCashboxAddBox").val().replace(/,/g, "");
                                                    if(val && !isNaN(val)) {
                                                        $("#lbpCashboxAddBox").val(parseFloat(val).toLocaleString('en', {useGrouping:true}));
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Note:</th>
                                        <td><input class="myInputStyle" id="addCashboxAmountNote"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.doAddAmount();
                            }}>Add</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="transferCashboxAmountModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Transfer from ({this.state.adminToDel}) Cashbox to another
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Cashbox Session ID:</th>
                                        <td id="cashboxSessionId"></td>
                                    </tr>
                                    <tr>
                                        <th>Cashbox User:</th>
                                        <td id="cashboxUser"></td>
                                    </tr>
                                    <tr>
                                        <th>Cashbox USD Amount:</th>
                                        <td id="cashboxTransferUsdAmount"></td>
                                    </tr>
                                    <tr>
                                        <th>Cashbox LBP Amount:</th>
                                        <td id="cashboxTransferLbpAmount"></td>
                                    </tr>
                                    <tr>
                                        <th>Transfer to Cashbox of User:</th>
                                        <td>
                                            <select id="inUsers" className="myInputStyle">
                                                {this.state.inUsersList}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Amounts:</th>
                                        <td>
                                            <div>
                                                <input class="myInputStyle" style={{display: "inline", marginBottom: "5px"}} id="usdCashboxTransfer" placeholder="USD Amount" type="number" />
                                                <input class="myInputStyle" style={{display: "inline"}} id="lbpCashboxTransfer" placeholder="LBP Amount" onChange={() => {
                                                    const val = $("#lbpCashboxTransfer").val().replace(/,/g, "");
                                                    if(val && !isNaN(val)) {
                                                        $("#lbpCashboxTransfer").val(parseFloat(val).toLocaleString('en', {useGrouping:true}));
                                                    }
                                                }} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.doTransfer();
                            }}>Transfer</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal fade" id="suppliersLogsHistory" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg" style={{maxWidth: "95%"}}>
                    <div class="modal-content" style={{backgroundColor: "gainsboro"}}>
                        <div class="modal-header">
                            Logs History of CashBox ID ({this.state.adminIdToDel}) of User ({this.state.adminToDel})
                        </div>
                        <div class="modal-body">
                            <input id="boxForSupplierId" style={{display: "none"}} />
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "left"}} onClick={() => {
                                window.$('#table2').DataTable().ajax.reload(null, false);
                            }}>Refresh</button>
                            <br/>
                            <br/>
                            <table class="table table-report dt-responsive -mt-2" id="table2" style={{width: "100%"}}>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>User</th>
                                        <th>Operation</th>
                                        <th>USD Amount</th>
                                        <th>USD Amount After</th>
                                        <th>LBP Amount</th>
                                        <th>LBP Amount After</th>
                                        <th>Date</th>
                                        <th>Note</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="deleteBranchModal" role="dialog" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            Deleting Supplier
                        </div>
                        <div class="modal-body">
                            Are you sure you want to remove Supplier ({this.state.adminToDel}) ?
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.deleteBranch();
                            }}>Yes</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                window.$("#deleteBranchModal").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="updateBalanceModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Name:</th>
                                        <td id="setSupplierName"></td>
                                    </tr>
                                    <tr>
                                        <th>Balance:</th>
                                        <td id="setSupplierBalance"></td>
                                    </tr>
                                    <tr>
                                        <th>Balance to change:</th>
                                        <td><input class="myInputStyle" type="number" id="balanceToChangeIn"/></td>
                                    </tr>
                                    <tr>
                                        <th>Note:</th>
                                        <td><input class="myInputStyle" id="balanceChangeNote"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.updateSupplierBalance("-");
                            }}>Remove Balance</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.updateSupplierBalance("+");
                            }}>Add Balance</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addBranchModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Name:</th>
                                        <td><input class="myInputStyle" id="nameInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Phone:</th>
                                        <td><input class="myInputStyle" type="number" id="phoneInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Country:</th>
                                        <td><input class="myInputStyle" id="countryInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Balance:</th>
                                        <td><input class="myInputStyle" type="number" id="balanceInput"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addAdminBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.addBranch();
                            }}>Add</button>

                            <button class='myBtnStyle' id='editAdminBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.editBranch();
                            }}>Update</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="FusePageCarded-header container">
                <div style={{flexDirection: "column"}} className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
                    <span className="MuiTypography-root MuiTypography-body1 flex text-24 md:text-32 font-extrabold tracking-tight muiltr-1niqtu4-MuiTypography-root" delay={300} style={{transform: 'none'}}>Cashboxes</span>
                </div>
            </div>

            <div style={{padding: "0px 10px 0px 10px"}}>

                {this.state.addAdminsBtn}

                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>Refresh</button>
                <br/>
                <br/>
                <table class="table table-report dt-responsive -mt-2" id="table1" style={{width: "100%"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>User</th>
                            <th>USD Amount</th>
                            <th>LBP Amount</th>
                            <th>Open Date</th>
                            <th>Close Date</th>
                            <th>Status</th>
                            <th>Input USD Amount</th>
                            <th>Input LBP Amount</th>
                            <th>Note</th>
                            <th>Branch</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
{/*
            <table id="table1" className="MuiTable-root MuiTable-stickyHeader min-w-xl muiltr-5g2394-MuiTable-root" aria-labelledby="tableTitle">
                <thead className="MuiTableHead-root muiltr-15wwp11-MuiTableHead-root">
                    <tr className="MuiTableRow-root MuiTableRow-head h-48 sm:h-64 muiltr-1yv0chr-MuiTableRow-root">
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            ID
                        </th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            Username
                        </th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            Access
                        </th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            Status
                        </th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className="MuiTableBody-root muiltr-apqrd9-MuiTableBody-root">
                    <tr className="MuiTableRow-root MuiTableRow-hover h-72 cursor-pointer muiltr-1yv0chr-MuiTableRow-root" role="checkbox" aria-checked="false" tabIndex={-1}>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                    </tr>
                </tbody>
            </table>
*/}
            </>
        )
    }
}

export default main;
