import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import getBrowserFingerprint from './get_browser_fp';
import md5 from 'md5';
import sha1 from 'sha1';


class login extends Component {
    getFp = () => {
        const fingerprint = getBrowserFingerprint(false, false, false, false);
        return md5("FGHfgfh"+sha1("34DFGEger"+fingerprint));
    }
    componentDidMount() {
        // this.setState({brFp:this.getFp()});
        // window.toastr["info"](this.state.brFp);
        // console.log(getMAC())
      // axios.post("/get_browser_auth").then((ok)=>{
      //   if(ok.data.status === "error") {
      //     window.toastr[ok.data.status](ok.data.msg);
      //     $(".hideOnNo").hide();
      //     // window.open("/", "_self");
      //   }
      // });
    }
    getFileAsUrlSync = (file) => {
      const reader = new FileReader();
      return new Promise(resolve => {
        reader.onload = ev => {
          resolve(ev.target.result);
        }
        reader.readAsDataURL(file);
      })
    }
    noFileSelected = () => {
      window.toastr["error"]("Invalid");
    }
    doStaffLogin = async() => {
      const self = this;
      // $("#myFileInput").attr("type", "");
      // $("#myFileInput").val("adrfg");

      // $("#myFileInput").off("cancel");
      // $("#myFileInput").on("cancel", function() {
      //   self.noFileSelected();
      // });

      // $("#myFileInput").off("change");
      // $("#myFileInput").on("change", async function() {
        // var myInput = document.getElementById('myFileInput');
        // if(myInput.files.length < 1) {
          // self.noFileSelected();
          // return;
        // }
        // var file = myInput.files[0];
        // console.log(await self.getFileAsUrlSync(file));
        const staffId = $("#inUsername").val();
        const inNote = $("#inNote").val();
        if(!staffId || isNaN(staffId)) {
          self.pLog("red", "Invalid Staff ID", "#errorLabelLogin");
          return;
        }
        axios.post("/staff_login", {id:staffId, note:inNote, fp:this.getFp()}).then((ok)=>{
          if(ok.data.status == "error") {
            self.pLog("red", ok.data.msg, "#errorLabelLogin");
          } else if(ok.data.status === "success") {
            window.toastr[ok.data.status](ok.data.msg);
            $("#inUsername").val("");
            $("#inNote").val("");
            $("#errorLabelLogin").hide();
          }
        })
      // });
      // $("#myFileInput").attr("type", "file");
      // $("#myFileInput").blur(true);
      // $("#myFileInput").trigger("click");
    }
    doStaffLogout = () => {
      const self = this;
      const staffId = $("#inUsername").val();
      const inNote = $("#inNote").val();
      if(!staffId || isNaN(staffId)) {
        self.pLog("red", "Invalid Staff ID", "#errorLabelLogin");
        return;
      }
      axios.post("/staff_logout", {id:staffId, note:inNote, fp:this.getFp()}).then((ok)=>{
        if(ok.data.status == "error") {
          self.pLog("red", ok.data.msg, "#errorLabelLogin");
        } else if(ok.data.status === "success") {
          window.toastr[ok.data.status](ok.data.msg);
          $("#inUsername").val("");
          $("#inNote").val("");
          $("#errorLabelLogin").hide();
        }
      })
    }
    Sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    pLog = async(color, text, elementId) => {
      $(elementId).css({color: color});
      $(elementId).html(text);
      $(elementId).show();

      const interval = 75;
      for(var i = 0; i < 10; ++i) {
          await this.Sleep(interval);
          $(elementId).css({color: "black"});
          await this.Sleep(interval);
          $(elementId).css({color: "red"});
      }
      $(elementId).css({color: color});
    }

    render() {
        return(
            <div id="fuse-layout" className="w-full flex muiltr-b10jnn">
        <div className="flex flex-auto min-w-0">
          <main id="fuse-main" className="flex flex-col flex-auto min-h-full min-w-0 relative z-10">
            <div className="flex flex-col flex-auto min-h-0 relative z-10">
              <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0" style={{placeContent: "center", backgroundColor: "white"}}>
                <div className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1 muiltr-piv2u8-MuiPaper-root" style={{placeContent: "center", borderRightWidth: "0px", width: "100%", height: "70%"}}>
                  <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0" style={{width: "100%", maxWidth: "50%"}}>
                    <p className="MuiTypography-root MuiTypography-body1 mt-32 text-4xl font-extrabold tracking-tight leading-tight muiltr-1niqtu4-MuiTypography-root">Working Time Login/Logout</p>
                    <form name="loginForm" noValidate className="flex flex-col justify-center w-full mt-32" onSubmit={(e) =>{e.preventDefault() } }>
                      <div className="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root mb-24 muiltr-wb57ya-MuiFormControl-root-MuiTextField-root">
                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorSecondary MuiFormLabel-filled Mui-required MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined muiltr-1cg7qce-MuiFormLabel-root-MuiInputLabel-root" data-shrink="true" htmlFor=":r39:" id=":r39:-label">Staff ID<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk muiltr-125iqkp-MuiFormLabel-asterisk"> *</span></label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorSecondary MuiInputBase-fullWidth MuiInputBase-formControl muiltr-zsctt5-MuiInputBase-root-MuiOutlinedInput-root">
                          <input type="number" aria-invalid="false" id="inUsername" name="staff_id" className="MuiInputBase-input MuiOutlinedInput-input muiltr-1soyadh-MuiInputBase-input-MuiOutlinedInput-input" style={{width: "91%"}} />
                          <fieldset aria-hidden="true" className="MuiOutlinedInput-notchedOutline muiltr-1d3z3hw-MuiOutlinedInput-notchedOutline">
                            <legend className="muiltr-14lo706"><span>Staff ID *</span></legend>
                          </fieldset>
                        </div>
                      </div>
                      
                      <div className="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root mb-24 muiltr-wb57ya-MuiFormControl-root-MuiTextField-root">
                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorSecondary MuiFormLabel-filled Mui-required MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined muiltr-1cg7qce-MuiFormLabel-root-MuiInputLabel-root" data-shrink="true" htmlFor=":r39:" id=":r39:-label">Operation Note<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk muiltr-125iqkp-MuiFormLabel-asterisk"></span></label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorSecondary MuiInputBase-fullWidth MuiInputBase-formControl muiltr-zsctt5-MuiInputBase-root-MuiOutlinedInput-root">
                          <input aria-invalid="false" id="inNote" name="opt_id" className="MuiInputBase-input MuiOutlinedInput-input muiltr-1soyadh-MuiInputBase-input-MuiOutlinedInput-input" style={{width: "91%"}} />
                          <fieldset aria-hidden="true" className="MuiOutlinedInput-notchedOutline muiltr-1d3z3hw-MuiOutlinedInput-notchedOutline">
                            <legend className="muiltr-14lo706"><span>Operation Note</span></legend>
                          </fieldset>
                        </div>
                      </div>
                      <label id="errorLabelLogin"></label>
                      <br/>
                      <div style={{display: "flex"}}>
                        <button className="myBtnStyleRed hideOnNo" style={{width: "100%"}} tabIndex={0} onClick={() => {
                          this.doStaffLogout();
                        }}>Logout</button>
                        &nbsp;
                        &nbsp;
                        <button className="myBtnStyle hideOnNo" style={{width: "100%"}} tabIndex={0} onClick={() => {
                          this.doStaffLogin();
                        }}>Login</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
        )
    }
}

export default login;
