import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";

class main extends Component {

    state = {
        user: [],
        entries: [],
        perms: [],
        adminToDel: "",
        adminIdToDel: ""
    }

    async setupSuppliersHistory() {
        const self = this;
        function getMyDate(date) {
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        window.$('#table2').DataTable({
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                const targetId = $("#boxForSupplierId").val();
                // console.log(targetId);
                await axios.post('/wallet_history_table', {itemId:targetId, offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const user = resData[vCount].user;
                    const cashBoxId = resData[vCount].cashbox_id;
                    const operation = resData[vCount].operation;
                    const usd_amount = parseFloat(resData[vCount].usd_amount).toLocaleString("en", {useGrouping:true});
                    const usd_amount_after = parseFloat(resData[vCount].usd_amount_after).toLocaleString("en", {useGrouping:true});
                    const lbp_amount = parseFloat(resData[vCount].lbp_amount).toLocaleString("en", {useGrouping:true});
                    const lbp_amount_after = parseFloat(resData[vCount].lbp_amount_after).toLocaleString("en", {useGrouping:true});
                    const date = getMyDate(resData[vCount].date);
                    const note = resData[vCount].note;

                    out.push([id, user, operation, usd_amount, usd_amount_after, lbp_amount, lbp_amount_after, date, note]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
    }

    setupDatatable() {
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        const self = this;
        window.$('#table1').DataTable({
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                await axios.post('/branches_table', {offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const name = resData[vCount].name;
                    const phones = resData[vCount].phones;
                    const address = resData[vCount].address;
                    const mofNum = resData[vCount].mof;
                    const botKey = resData[vCount].bot_key;
                    const invoicesChatId = resData[vCount].invoices_chat_id;
                    const outOfStockChatId = resData[vCount].out_of_stock_chat_id;
                    // const generalChatId = resData[vCount].general_chat_id;
                    const wallet = resData[vCount].wallet;
                    const ip = resData[vCount].ip;

                    // console.log(name, self.state.user.access);

                    var buttons = "<div class='dropdown'>" +
                                        "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                        "<div class='dropdown-content'>"

                    if(self.state.user.access.includes("|19|")) {
                        buttons += "<a class='myAStyle' id='delete_branch' itemId='"+id+"' itemName='"+name+"' style='color: red !important;'>Delete</a>";
                    }
                    if(self.state.user.access.includes("|20|")) {
                        buttons += "<a class='myAStyle' id='edit_branch' botKey='"+botKey+"' invoicesChatId='"+invoicesChatId+"' outOfStockChatId='"+outOfStockChatId+"' itemId='"+id+"' itemPhones='"+phones+"' itemAddress='"+address+"' itemName='"+name+"' itemMof='"+mofNum+"' ip='"+ip+"'>Update</a>";
                    }
                    if(self.state.user.access.includes("|56|")) {
                        buttons += "<a class='myAStyle' id='update_branch_wallet' itemId='"+id+"' itemName='"+name+"' itemWallet='"+wallet+"'>Update Wallet Balance</a>";
                    }
                    if(self.state.user.access.includes("|64|")) {
                        buttons += "<a class='myAStyle' id='view_wallet_history' itemId='"+id+"' itemName='"+name+"'>View Wallet History</a>";
                    }
                    buttons += "</div></div>";
                    // console.log(buttons);
                    out.push([id, name, phones, address, mofNum, parseFloat(wallet).toLocaleString(), ip, buttons]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
        $(document).off("click", "#view_wallet_history");
        $(document).on("click", "#view_wallet_history", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            self.setState({adminIdToDel:itemId, adminToDel:itemName, itemIdLogsHistory:itemId});
            $("#boxForSupplierId").val(itemId);
            window.$("#suppliersLogsHistory").modal("show");
            self.setupSuppliersHistory();
        });
        $(document).off("click", "#update_branch_wallet");
        $(document).on("click", "#update_branch_wallet", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemWallet = $(this).attr("itemWallet");
            $("#branchIdIn").html(itemId);
            $("#branchNameIn").html(itemName);
            $("#branchWalletIn").html(itemWallet);
            window.$("#updateWalletModal").modal("show");
        });
        $(document).off("click", "#delete_branch");
        $(document).on("click", "#delete_branch", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            window.$("#deleteBranchModal").modal("show");
        });

        $(document).off("click", "#edit_branch");
        $(document).on("click", "#edit_branch", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemPhones = $(this).attr("itemPhones");
            const itemAddress = $(this).attr("itemAddress");
            const itemMof = $(this).attr("itemMof");
            const botKey = $(this).attr("botKey");
            const invoicesChatId = $(this).attr("invoicesChatId");
            const outOfStockChatId = $(this).attr("outOfStockChatId");
            const ip = $(this).attr("ip");
            // const generalChatId = $(this).attr("generalChatId");
            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            $("#nameInput").val(itemName);
            $("#addAdminBtn").hide();
            $("#editAdminBtn").show();
            $("#phonesInput").val(itemPhones);
            $("#addressInput").val(itemAddress);
            $("#mofInput").val(itemMof);
            $("#botKeyInput").val(botKey);
            $("#ipInput").val(ip);
            $("#invoicesChatIdInput").val(invoicesChatId);
            // $("#generalChatIdInput").val(generalChatId);
            $("#outOfStockChatIdInput").val(outOfStockChatId);
            window.$("#addBranchModal2").modal("show");
        });
    }
    componentDidMount() {
        axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            this.setState({user:ok.data});
            if(!ok.data.access.includes("|17|")) {
                this.props.history.push("/panel");
                return;
            }
            const nodeName = "#branches_btn";
            const interv3 = setInterval(function() {
                $(".MuiButtonBase-root").css({backgroundColor: ""});
                $(nodeName).css({backgroundColor: "cadetblue"});
                if($(nodeName).prop("style")) {
                    if($(nodeName).prop("style").backgroundColor === "cadetblue") {
                        clearInterval(interv3);
                    }
                }
            }, 1);
            this.setupDatatable();

            if(ok.data.access.includes("|18|")) {
                this.setState({addAdminsBtn:[
                    <>
                        <button class='myBtnStyle' tabIndex={0} type='button' onClick={() => {
                            $("#addAdminBtn").show();
                            $("#editAdminBtn").hide();
                            window.$("#addBranchModal2").modal("show");
                        }}>+ Add</button>
                    </>
                ]})
            }
        })
    }

    addBranch = () => {
        const name = $("#nameInput").val();
        const phones = $("#phonesInput").val();
        const address = $("#addressInput").val();
        const mof = $("#mofInput").val();
        const botKey = $("#botKeyInput").val();
        const invoicesChatId = $("#invoicesChatIdInput").val();
        const outOfStockChatId = $("#outOfStockChatIdInput").val();
        const ip = $("#ipInput").val();
        // const generalChatId = $("#generalChatIdInput").val();
        if(!name) {
            window.toastr["error"]("Invalid Branch Name");
            return;
        }
        if(!phones) {
            window.toastr["error"]("Invalid Branch Phones");
            return;
        }
        if(!address) {
            window.toastr["error"]("Invalid Branch Address");
            return;
        }
        if(!mof) {
            window.toastr["error"]("Invalid Branch MOF");
            return;
        }
        axios.post("/add_branch", {ip:ip, botKey:botKey, invoicesChatId:invoicesChatId, outOfStockChatId:outOfStockChatId, /*generalChatId:generalChatId,*/ name:name, phones:phones, address:address, mof:mof}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addBranchModal2").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    deleteBranch = () => {
        const name = this.state.adminToDel;
        axios.post("/delete_branch", {name:name}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#deleteBranchModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    editBranch = () => {
        const prevName = this.state.adminToDel;
        const name = $("#nameInput").val();
        const phones = $("#phonesInput").val();
        const address = $("#addressInput").val();
        const mof = $("#mofInput").val();
        const botKey = $("#botKeyInput").val();
        const invoicesChatId = $("#invoicesChatIdInput").val();
        const outOfStockChatId = $("#outOfStockChatIdInput").val();
        const generalChatId = $("#generalChatIdInput").val();
        const ip = $("#ipInput").val();
        if(!name) {
            window.toastr["error"]("Invalid Branch Name");
            return;
        }
        if(!phones) {
            window.toastr["error"]("Invalid Branch Phones");
            return;
        }
        if(!address) {
            window.toastr["error"]("Invalid Branch Address");
            return;
        }
        if(!mof) {
            window.toastr["error"]("Invalid Branch MOF");
            return;
        }
        axios.post("/edit_branch", {ip:ip, botKey:botKey, invoicesChatId:invoicesChatId, outOfStockChatId:outOfStockChatId, /*generalChatId:generalChatId,*/ prevName:prevName, name:name, phones:phones, address:address, mof:mof}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addBranchModal2").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }
    
    addWalletBalance = () => {
        const branchId = $("#branchIdIn").html();
        const balance = $("#branchWalletIn").val();
        const note = $("#branchWalletInNote").val();
        if(!balance || isNaN(balance) || parseFloat(balance) < 0) {
            window.toastr["error"]("Invalid Balance");
            return;
        }
        if(!note || note.length < 5) {
            window.toastr["error"]("Invalid Note");
            return;
        }
        axios.post("/add_wallet_balance", {id:branchId, balance:balance, note:note}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#updateWalletModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        });
    }
    removeWalletBalance = () => {
        const branchId = $("#branchIdIn").html();
        const balance = $("#branchWalletIn").val();
        const note = $("#branchWalletInNote").val();
        if(!balance || isNaN(balance) || parseFloat(balance) < 0) {
            window.toastr["error"]("Invalid Balance");
            return;
        }
        if(!note || note.length < 5) {
            window.toastr["error"]("Invalid Note");
            return;
        }
        axios.post("/remove_wallet_balance", {id:branchId, balance:balance, note:note}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#updateWalletModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        });
    }

    render() {
        return(
            <>
            
            <div class="modal fade" id="suppliersLogsHistory" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg" style={{maxWidth: "95%"}}>
                    <div class="modal-content" style={{backgroundColor: "gainsboro"}}>
                        <div class="modal-header">
                            Logs History of Wallet ({this.state.adminToDel})
                        </div>
                        <div class="modal-body">
                            <input id="boxForSupplierId" style={{display: "none"}} />
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "left"}} onClick={() => {
                                window.$('#table2').DataTable().ajax.reload(null, false);
                            }}>Refresh</button>
                            <br/>
                            <br/>
                            <table class="table table-report dt-responsive -mt-2" id="table2" style={{width: "100%"}}>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>User</th>
                                        <th>Operation</th>
                                        <th>USD Amount</th>
                                        <th>USD Amount After</th>
                                        <th>LBP Amount</th>
                                        <th>LBP Amount After</th>
                                        <th>Date</th>
                                        <th>Note</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal fade" id="updateWalletModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Branch ID:</th>
                                        <td id="branchIdIn"></td>
                                    </tr>
                                    <tr>
                                        <th>Branch Name:</th>
                                        <td id="branchNameIn"></td>
                                    </tr>
                                    <tr>
                                        <th>Balance:</th>
                                        <td><input class="myInputStyle" id="branchWalletIn" type="number" /></td>
                                    </tr>
                                    <tr>
                                        <th>Note:</th>
                                        <td><input class="myInputStyle" id="branchWalletInNote" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.addWalletBalance();
                            }}>Add Balance</button>

                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.removeWalletBalance();
                            }}>Remove Balance</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal fade" id="deleteBranchModal" role="dialog" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            Deleting Branch
                        </div>
                        <div class="modal-body">
                            Are you sure you want to remove Branch ({this.state.adminToDel}) ?
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.deleteBranch();
                            }}>Yes</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                window.$("#deleteBranchModal").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addBranchModal2" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Name:</th>
                                        <td><input class="myInputStyle" id="nameInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Phones:</th>
                                        <td><input class="myInputStyle" id="phonesInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Address:</th>
                                        <td><input class="myInputStyle" id="addressInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>MOF:</th>
                                        <td><input class="myInputStyle" id="mofInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Telegram Bot Key:</th>
                                        <td><input class="myInputStyle" id="botKeyInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Invoices Chat ID:</th>
                                        <td><input class="myInputStyle" id="invoicesChatIdInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Out of Stock Chat ID:</th>
                                        <td><input class="myInputStyle" id="outOfStockChatIdInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>IP:</th>
                                        <td><input class="myInputStyle" id="ipInput"/></td>
                                    </tr>
                                    {/*
                                    <tr>
                                        <th>General Chat ID:</th>
                                        <td><input class="myInputStyle" id="generalChatIdInput"/></td>
                                    </tr>
                                    */}
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addAdminBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.addBranch();
                            }}>Add</button>

                            <button class='myBtnStyle' id='editAdminBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.editBranch();
                            }}>Update</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="FusePageCarded-header container">
                <div style={{flexDirection: "column"}} className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
                    <span className="MuiTypography-root MuiTypography-body1 flex text-24 md:text-32 font-extrabold tracking-tight muiltr-1niqtu4-MuiTypography-root" delay={300} style={{transform: 'none'}}>Branches Page</span>
                </div>
            </div>

            <div style={{padding: "0px 10px 0px 10px"}}>

                {this.state.addAdminsBtn}

                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>Refresh</button>
                <br/>
                <br/>
                <table class="table table-report dt-responsive -mt-2" id="table1" style={{width: "100%"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Branch Name</th>
                            <th>Phones</th>
                            <th>Address</th>
                            <th>MOF</th>
                            <th>Wallet Balance</th>
                            <th>IP</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
{/*
            <table id="table1" className="MuiTable-root MuiTable-stickyHeader min-w-xl muiltr-5g2394-MuiTable-root" aria-labelledby="tableTitle">
                <thead className="MuiTableHead-root muiltr-15wwp11-MuiTableHead-root">
                    <tr className="MuiTableRow-root MuiTableRow-head h-48 sm:h-64 muiltr-1yv0chr-MuiTableRow-root">
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            ID
                        </th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            Username
                        </th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            Access
                        </th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            Status
                        </th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className="MuiTableBody-root muiltr-apqrd9-MuiTableBody-root">
                    <tr className="MuiTableRow-root MuiTableRow-hover h-72 cursor-pointer muiltr-1yv0chr-MuiTableRow-root" role="checkbox" aria-checked="false" tabIndex={-1}>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                    </tr>
                </tbody>
            </table>
*/}
            </>
        )
    }
}

export default main;
