import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import {Buffer} from "buffer";

class login extends Component {
  state = {
    invoiceId: "",
    invoiceDate: "",
    customer: "",
    user: [],
    itemsData: []
  }
    componentWillMount() {
      
    }
    getMyDate = (date) => {
      return new Date(date).toLocaleString("en-GB", {timeZone: this.state.user.tz, hour12: "true"}).toLocaleUpperCase();
    }
    setStateSynchronous = (stateUpdate) => {
      return new Promise(resolve => {
          this.setState(stateUpdate, () => resolve());
      });
    }
    
    cleanNumberByNPoint = (num, n) => {
        num = String(num);
        if(!num.includes(".")) {
            return num;
        }
        const leftPoint = num.split(".")[0];
        const rightPoint = num.split(".")[1];
        if(rightPoint.length > 1) {
            return leftPoint+"."+rightPoint.substring(0, n);
        } else {
            return leftPoint+"."+rightPoint;
        }
    }
    
    componentDidMount = async() => {
        await axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
              this.props.history.push("/login");
            }
            if(!ok.data.access.includes("|44|")) {
                window.open("/panel", "_self");
                // this.props.history.push("/panel");
                return;
            }
            this.setState({user:ok.data});
        })
      $("head").append('<link rel="stylesheet" title="myPrintA4Style" href="/assets/style/print_a4.css" />');
      $("#root").removeAttr("class");
      $("#root").removeAttr("id");
      const base64InvoiceId = window.location.href.split("/")[window.location.href.split("/").length - 1];
      const invoiceId = Buffer.from(base64InvoiceId, "base64").toString("utf8");
      this.setState({invoiceId:invoiceId});
      axios.post("/get_invoice_data_print", {id:invoiceId}).then(async(ok)=>{
        if(ok.data.status === "error") {
          window.toastr[ok.data.status](ok.data.msg);
          return;
        }
        const data = ok.data.data;
        const invData = ok.data.invData;
        const itemsData = [];
        this.setState({invoiceDate:this.getMyDate(invData[0].date), customer:invData[0].customer, employee:invData[0].user});
        for(var i = 0; i < data.length; ++i) {
            if(data[i]) {
                const itemPriceOrig = data[i].item_price;
              itemsData.push([
                <tr>
                    <td>{data[i].item_name}</td>
                    <td>{data[i].item_price}</td>
                    <td>{data[i].item_discount}%</td>
                    <td>{data[i].final_price}</td>
                    <td>{data[i].item_qty}</td>
                    <td>{data[i].total_price}</td>
                </tr>
              ]);
            }
        }
        const finalAmount = invData[0].final_price;
        const vatAmount = String(parseFloat(finalAmount) * parseFloat(String("0."+this.state.user.vat)));
        const lastFinalAmount = String(parseFloat(finalAmount) + parseFloat(vatAmount));
        const totalAmount = invData[0].base_price;
        await this.setStateSynchronous({itemsData:itemsData, totalAmount:totalAmount, discount:invData[0].discount_percent, finalAmount:finalAmount,
                       invoiceType:invData[0].sell_type, invoiceStatus:invData[0].status, branch:invData[0].branch, phones:ok.data.phones, address:ok.data.address, mof:ok.data.mof, lastAmount:lastFinalAmount, vatAmount:vatAmount});
        window.print();
      })
    }

    render() {
        return(
            <>
              <div class="book">
                  <div class="page">
                    <label style={{fontSize: "40px", justifyContent: "center", display: "grid"}}>EasyTech</label>
                    {/* <br/> */}
                    <label style={{fontSize: "15px", justifyContent: "center", display: "grid"}}>Branch ({this.state.branch})</label>
                    <br/>
                    <label style={{fontSize: "20px", justifyContent: "center", display: "grid"}}>Invoice ({this.state.invoiceId})</label>
                    <br/>
                    <div style={{justifyContent: "space-between", display: "flex"}}>
                        <label style={{fontSize: "15px"}}>Phone: <label style={{fontWeight: "100"}}>{this.state.phones}</label></label>
                        <label style={{fontSize: "15px"}}>Address: <label style={{fontWeight: "100"}}>{this.state.address}</label></label>
                    </div>
                    <div style={{justifyContent: "space-between", display: "flex"}}>
                        <label style={{fontSize: "15px"}}>VAT-Number: <label style={{fontWeight: "100"}}>{this.state.mof}</label></label>
                        <label style={{fontSize: "15px"}}>Invoice Date: <label style={{fontWeight: "100"}}>{this.state.invoiceDate}</label></label>
                    </div>
                    <div style={{justifyContent: "space-between", display: "flex"}}>
                        <label style={{fontSize: "15px"}}>Employee: <label style={{fontWeight: "100"}}>{this.state.employee}</label></label>
                        <label style={{fontSize: "15px"}}>Customer: <label style={{fontWeight: "100"}}>{this.state.customer}</label></label>
                    </div>
                    <h1 style={{marginTop: "10px", borderTop: "1px solid", marginBottom: "10px"}}></h1>
                    <table class="table table-report dt-responsive -mt-2" id="table2" style={{width: "100%", border: "1px solid black", marginBottom: "0px"}}>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Price</th>
                                <th>Discount</th>
                                <th>Final Price</th>
                                <th>Qty</th>
                                <th>Total Amount</th>
                            </tr>
                        </thead>
                        <tbody style={{textAlignLast: "start"}}>
                            {this.state.itemsData}
                            {/* <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr> */}
                        </tbody>
                    </table>
                    <h1 style={{marginTop: "10px", borderTop: "1px solid", marginBottom: "10px"}}></h1>

                    <table className="table-modal" style={{width: 'fit-content', textAlignLast: 'start'}}>
                      <tbody>
                        <tr>
                          <th>Invoice Type:</th>
                          <td>{this.state.invoiceType}</td>
                        </tr>
                        <tr>
                          <th>Pay Status:</th>
                          <td>{this.state.invoiceStatus}</td>
                        </tr>
                        <tr>
                          <th>Total Amount:</th>
                          <td>{parseFloat(this.state.totalAmount).toFixed(2).toLocaleString("en", {useGrouping: true})} USD</td>
                        </tr>
                        <tr>
                          <th>Discount:</th>
                          <td>{this.state.discount}%</td>
                        </tr>
                        <tr>
                          <th>Final Amount:</th>
                          <td>{parseFloat(this.state.finalAmount).toFixed(2).toLocaleString("en", {useGrouping: true})} USD</td>
                        </tr>
                        <tr>
                          <th>VAT 11%:</th>
                          <td>{parseFloat(this.state.vatAmount).toFixed(2).toLocaleString("en", {useGrouping: true})} USD</td>
                        </tr>
                        <tr>
                          <th>Amount with VAT:</th>
                          <td>{parseFloat(this.state.lastAmount).toFixed(2).toLocaleString("en", {useGrouping: true})} USD</td>
                        </tr>
                      </tbody>
                    </table>

                    {/* <label style={{fontSize: "15px"}}>Invoice Type: <label style={{fontWeight: "100"}}>{this.state.invoiceType}</label></label>
                    <br/>
                    <label style={{fontSize: "15px"}}>Pay Status: <label style={{fontWeight: "100"}}>{this.state.invoiceStatus}</label></label>
                    <br/>
                    <label style={{fontSize: "15px"}}>Total Amount: <label style={{fontWeight: "100"}}>{parseFloat(this.state.totalAmount).toLocaleString("en", {useGrouping: true})} USD</label></label>
                    <br/>
                    <label style={{fontSize: "15px"}}>Discount: <label style={{fontWeight: "100"}}>{this.state.discount}%</label></label>
                    <br/>
                    <label style={{fontSize: "15px"}}>Final Amount: <label style={{fontWeight: "100"}}>{parseFloat(this.state.finalAmount).toLocaleString("en", {useGrouping: true})} USD</label></label> */}


                  </div>
              </div>
            </>
        )
    }
}

export default login;
