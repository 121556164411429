import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";

class main extends Component {

    state = {
        user: [],
        entries: [],
        perms: [],
        adminToDel: "",
        adminIdToDel: ""
    }

    setupDatatable() {
        const self = this;
        function getMyDate(date) {
            if(!date) {
                return "";
            }
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        window.$('#table1').DataTable({
            'columnDefs': [
            { "width": "100%", "targets": 1 },
            { className: 'dt-left', targets: 1 },
            ],
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                const selectedStatus = $("#selectedStatus").val();
                const selectedBranch = $("#selectedBranch").val();
                await axios.post('/serials_table', {status:selectedStatus, branch:selectedBranch, offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const codeName = resData[vCount].code_name;
                    const codeId = resData[vCount].code_id;
                    const codeSn = resData[vCount].sn;
                    const codeImei1 = resData[vCount].imei1;
                    const codeImei2 = resData[vCount].imei2;
                    const codeDate = getMyDate(resData[vCount].date);
                    const codeAuthor = resData[vCount].author;
                    const codeStatus = resData[vCount].status;
                    const codeBranch = resData[vCount].branch;
                    const codeItemInvoiceId = resData[vCount].item_invoice_id;
                    const codeSubItemInvoiceId = resData[vCount].sub_item_invoice_id;
                    const codeMoveStockRef = resData[vCount].move_stock_ref;

                    var buttons = "<div class='dropdown'>" +
                                        "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                        "<div class='dropdown-content'>"

                    // buttons += "<a class='myAStyle' id='view_details' itemId='"+id+"' author='"+author+"' operation='"+operation+"' date='"+date+"' details='"+details+"'>View Details</a>";
                    buttons += "</div></div>";

                    out.push([id, codeName, codeId, codeSn, codeImei1, codeImei2, codeDate, codeAuthor, codeStatus, codeBranch, codeItemInvoiceId, codeSubItemInvoiceId, codeMoveStockRef, buttons]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
        $(document).off("click", "#view_details");
        $(document).on("click", "#view_details", function() {
            const itemId = $(this).attr("itemId");
            const author = $(this).attr("author");
            const operation = $(this).attr("operation");
            const date = $(this).attr("date");
            const details = $(this).attr("details");

            $("#setLogIdHistory").html(itemId);
            $("#setAuthorHistory").html(author);
            $("#setOperationHistory").html(operation);
            $("#setDateHistory").html(date);
            $("#setDetailsHistory").val(details);

            window.$("#historyItemModal").modal("show");
        });
    }
    componentDidMount() {
        axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            this.setState({user:ok.data});
            if(!ok.data.access.includes("|68|")) {
                this.props.history.push("/panel");
                return;
            }
            const nodeName = "#serials_btn";
            const interv3 = setInterval(function() {
                $(".MuiButtonBase-root").css({backgroundColor: ""});
                $(nodeName).css({backgroundColor: "cadetblue"});
                if($(nodeName).prop("style")) {
                    if($(nodeName).prop("style").backgroundColor === "cadetblue") {
                        clearInterval(interv3);
                    }
                }
            }, 1);
            this.setupDatatable();
            this.setupBranches();
        })
    }
    
    setStateSynchronous = (stateUpdate) => {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }
    
    setupBranches = async() => {
        window.toastr["info"]("Loading data...");
        await axios.post("/get_branches").then(async(ok)=>{
            if(ok.data.status == "error") {
                this.setState({branchesList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                await this.setStateSynchronous({branchesList:data});
            }
        })
        await axios.post("/get_suppliers").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({clientsList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                // data.push(<option value="">{"<Make as Main Category>"}</option>);
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({clientsList:data});
            }
        })
        window.toastr.remove();
    }
    
    render() {
        return(
            <>
            
            <div class="modal fade" id="historyItemModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Log ID:</th>
                                        <td id="setLogIdHistory"></td>
                                    </tr>
                                    <tr>
                                        <th>Author</th>
                                        <td id="setAuthorHistory"></td>
                                    </tr>
                                    <tr>
                                        <th>Operation</th>
                                        <td id="setOperationHistory"></td>
                                    </tr>
                                    <tr>
                                        <th>Date</th>
                                        <td id="setDateHistory"></td>
                                    </tr>
                                    <tr>
                                        <th>Details</th>
                                        <td><textarea id="setDetailsHistory" style={{width: "100%", height: "250px", backgroundColor: "gainsboro", border: "groove"}}></textarea></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="FusePageCarded-header container">
                <div style={{flexDirection: "column"}} className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
                    <span className="MuiTypography-root MuiTypography-body1 flex text-24 md:text-32 font-extrabold tracking-tight muiltr-1niqtu4-MuiTypography-root" delay={300} style={{transform: 'none'}}>Serials Page</span>
                </div>
            </div>

            <div style={{padding: "0px 10px 0px 10px"}}>

                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>Refresh</button>
                <br/>
                
                <table className="table-modal" style={{width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                    <tbody>
                        <tr>
                            <th>Status:</th>
                            <td>
                                <select id="selectedStatus" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    <option value="">All</option>
                                    <option value="sold">Sold</option>
                                    <option value="ready">Ready</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Branch:</th>
                            <td>
                                <select id="selectedBranch" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    <option value="">All</option>
                                    {this.state.branchesList}
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                
                <table class="table table-report dt-responsive -mt-2" id="table1" style={{width: "100%", fontSize: "smaller"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Related ID</th>
                            <th>SN</th>
                            <th>IMEI 1</th>
                            <th>IMEI 2</th>
                            <th>Date</th>
                            <th>Author</th>
                            <th>Status</th>
                            <th>Branch</th>
                            <th>Invoice ID</th>
                            <th>Sub Invoice ID</th>
                            <th>Move Stock Reference</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }
}

export default main;
